import React from 'react';
import Modal from './Modal';

const PreviewMediaFile = ({
  isOpen = false,
  onCloseHandler = () => {},
  type = 'image',
  fileSrc = '',
}) => {
  const renderMedia = () => {
    if (type === 'image') {
      return (
        <img
          src={fileSrc}
          className="bg-greymedium object-contain w-[100vw] md:!w-[50vw] min-h-[250px] max-h-[80vh]"
        />
      );
    } else if (type === 'video') {
      return (
        <video
          src={fileSrc}
          autoPlay
          controls={true}
          className="bg-greymedium w-[100vw] md:!w-[50vw] min-h-[250px] max-h-[80vh]"
        />
      );
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseHandler}
      isTitle={true}
      title={'Preview'}
      width="w-[100vw] md:!w-[50vw]"
      height="min-h-[250px] max-h-[90vh]"
      childrenClassNames=""
      titleClassNames=""
      padding="!p-0"
      titleParentClassNames="md:m-3 m-0"
    >
      <div className="flex justify-center items-center">{renderMedia()}</div>
    </Modal>
  );
};

export default PreviewMediaFile;
