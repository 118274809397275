import OutlinedButton from '../../components/common/OutlinedButton';
// import { validationSchemaMyself } from '../../validations';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../constants/urlPaths';
import { updateSignup } from '../../redux/slices/authSlice';
import { useDispatch } from 'react-redux';
import AddAboutMe from '../../components/MyselfPage/AddAboutMe';
import MoreAboutYou from '../../components/MyselfPage/MoreAboutYou';
import Assesments from '../../components/MyselfPage/Assesments';
import Location from '../../components/MyselfPage/Location';
import { useEffect, useState } from 'react';
import { getMySelfDetails } from '../../services/myself';
export function MyselfTabContent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const { HOME } = PATHS;

  const onSkipHandler = async () => {
    await dispatch(updateSignup(false));
    navigate(HOME, { replace: true });
    window.location.reload();
  };

  const getUserData = async () => {
    const response = await getMySelfDetails();
    const { data = '', status = '' } = response;
    if (status) {
      const { data: fetchedUserData = {} } = data;
      setUserData(fetchedUserData);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="sm:py-[36px] py-[20px] lg:px-[70px] md:px-[40px] px-[20px] bg-bluebg">
      <AddAboutMe />
      <MoreAboutYou />
      <Location />
      <Assesments
        discAssessment={userData?.discAssessment}
        enneagram={userData?.enneagram}
        hollandCode={userData?.hollandCode}
        myersBriggsAssessment={userData?.myersBriggsAssessment}
        attributeAssessment={userData?.attributeAssessment}
        bigFiveAssessment={userData?.bigFiveAssessment}
      />
      <div>
        <div className="mt-[34px] flex justify-between flex-wrap">
          <div className="flex gap-4 flex-wrap"></div>
          <div className="flex gap-4 flex-wrap items-center">
            <div>
              <OutlinedButton
                textClass="text-[16px] font-medium"
                label="Skip"
                isSkip={true}
                onClick={() => onSkipHandler()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
