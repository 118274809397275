import NETWORK_CONSTANTS from '../constants/networkConstants';
import apiUtility from '../utils/network/apiUtility';

const {
  MYSELF_ADD_ABOUTME,
  SOFTSKILLS_LISTING,
  LANGUAGES_LISTING,
  ADD_MORE_YOU,
  MYSELF_DETAILS,
  ASSESMENT_ATTRIBUTES,
  ASSESMENT_BIG_FIVE,
  ASSESMENT_DISC,
  ASSESMENT_MYERS_BRIGGS,
  SAVE_LOCATION,
  GET_MYSELF_OTHER_USER,
} = NETWORK_CONSTANTS;

const addAboutMe = async ({ aboutMyself }) => {
  try {
    const data = await apiUtility(MYSELF_ADD_ABOUTME, 'POST', { aboutMyself });
    return data;
  } catch (error) {
    return error;
  }
};

const getSoftSkillsListing = async () => {
  try {
    const data = await apiUtility(SOFTSKILLS_LISTING, 'GET');
    return data;
  } catch (error) {
    return error;
  }
};

const getLanguageListing = async () => {
  try {
    const data = await apiUtility(LANGUAGES_LISTING, 'GET');
    return data;
  } catch (error) {
    return error;
  }
};

const addMoreAboutYou = async (moreAboutYouData = {}) => {
  try {
    const data = await apiUtility(ADD_MORE_YOU, 'PATCH', moreAboutYouData);
    return data;
  } catch (error) {
    return error;
  }
};

const getMySelfDetails = async () => {
  try {
    const data = await apiUtility(MYSELF_DETAILS, 'GET');
    return data;
  } catch (error) {
    return error;
  }
};

const addAssesmentAttributes = async (attributesData = {}) => {
  try {
    const data = await apiUtility(ASSESMENT_ATTRIBUTES, 'POST', attributesData);
    return data;
  } catch (error) {
    return error;
  }
};

const getAssesmentAttributes = async () => {
  try {
    const data = await apiUtility(ASSESMENT_ATTRIBUTES, 'GET');
    return data;
  } catch (error) {
    return error;
  }
};

const addAssesmentMyersBriggs = async (requestData = {}) => {
  try {
    const data = await apiUtility(ASSESMENT_MYERS_BRIGGS, 'POST', requestData);
    return data;
  } catch (error) {
    return error;
  }
};

const addAssesmentDisc = async (requestData = {}) => {
  try {
    const data = await apiUtility(ASSESMENT_DISC, 'POST', requestData);
    return data;
  } catch (error) {
    return error;
  }
};

const addAssesmentBigFive = async (requestData = {}) => {
  try {
    const data = await apiUtility(ASSESMENT_BIG_FIVE, 'POST', requestData);
    return data;
  } catch (error) {
    return error;
  }
};

const getAssesmentBigFive = async () => {
  try {
    const data = await apiUtility(ASSESMENT_BIG_FIVE, 'GET');
    return data;
  } catch (error) {
    return error;
  }
};

const addLocation = async (requestData = {}) => {
  try {
    const data = await apiUtility(SAVE_LOCATION, 'PATCH', requestData);
    return data;
  } catch (error) {
    return error;
  }
};

const getOtherUserMyselfDetails = async ({ userId }) => {
  try {
    const data = await apiUtility(GET_MYSELF_OTHER_USER(userId), 'GET');
    return data;
  } catch (error) {
    return error;
  }
};

export {
  addAboutMe,
  getSoftSkillsListing,
  getLanguageListing,
  addMoreAboutYou,
  getMySelfDetails,
  addAssesmentAttributes,
  getAssesmentAttributes,
  addAssesmentBigFive,
  addAssesmentDisc,
  addAssesmentMyersBriggs,
  getAssesmentBigFive,
  addLocation,
  getOtherUserMyselfDetails,
};
