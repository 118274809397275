import React from 'react';

const LeftChevron = ({ fill = 'black', width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="fluent:ios-arrow-24-filled">
        <path
          id="Vector"
          d="M10.6064 3.07206C10.6816 2.9925 10.7404 2.89891 10.7794 2.79664C10.8184 2.69436 10.8369 2.5854 10.8338 2.47599C10.8307 2.36657 10.8061 2.25883 10.7614 2.15893C10.7166 2.05902 10.6527 1.9689 10.5731 1.89372C10.4935 1.81854 10.4 1.75977 10.2977 1.72075C10.1954 1.68174 10.0865 1.66325 9.97703 1.66635C9.86761 1.66944 9.75988 1.69406 9.65997 1.73879C9.56007 1.78353 9.46995 1.8475 9.39477 1.92706L2.31144 9.42706C2.16516 9.58178 2.08365 9.78663 2.08365 9.99956C2.08365 10.2125 2.16516 10.4173 2.31144 10.5721L9.39477 18.0729C9.46946 18.1542 9.55955 18.2198 9.65982 18.266C9.7601 18.3122 9.86855 18.338 9.97887 18.3419C10.0892 18.3459 10.1992 18.3278 10.3025 18.2889C10.4058 18.2499 10.5003 18.1908 10.5806 18.115C10.6609 18.0392 10.7253 17.9482 10.7701 17.8473C10.8149 17.7464 10.8392 17.6376 10.8416 17.5273C10.844 17.4169 10.8245 17.3072 10.7841 17.2044C10.7437 17.1016 10.6833 17.0079 10.6064 16.9287L4.0631 9.99956L10.6064 3.07206Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default LeftChevron;
