export const UserIcon = ({ fill = '#ffffff', width = 41, height = 41 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Combined Shape"
        opacity="0.9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.86541 13.3487C6.86541 5.97642 12.9692 0 20.4986 0C28.0279 0 34.1317 5.97642 34.1317 13.3487C34.1317 16.7676 32.8191 19.8862 30.6603 22.2479H34.1006C35.35 22.2479 36.635 23.2048 36.9735 24.3948L40.8146 37.8995C41.1518 39.0852 40.4102 40.0472 39.1426 40.0483L1.805 40.0784C0.544339 40.0794 -0.207209 39.1215 0.129077 37.9292L3.94544 24.3989C4.28051 23.2109 5.57452 22.2479 6.81439 22.2479H10.3368C8.17807 19.8862 6.86541 16.7676 6.86541 13.3487ZM20.4537 22.5475C25.3989 22.5475 29.4076 18.4942 29.4076 13.4942C29.4076 11.7632 28.9272 10.1457 28.0938 8.77059C25.3656 11.2364 19.2896 11.2364 16.1555 11.2364C13.9523 11.2364 12.4542 12.8364 11.5371 14.3252C11.952 18.9357 15.7857 22.5475 20.4537 22.5475ZM32.5946 26.9333L7.68155 26.9377L5.156 35.6986L35.1531 35.7004L32.5946 26.9333Z"
        fill={fill}
      />
    </svg>
  );
};
