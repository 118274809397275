import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ProfileLayout from '../../components/ProfileLayout';
import FollowerContainer from '../../components/ProfileLayout/FollowerContainer';
import ProfileContainer from '../../components/ProfileLayout/ProfileContainer';
import Card from '../../components/common/Card';
import backIcon from '../../assets/images/backIcon.svg';
import noWork from '../../assets/images/noWork.svg';

import { useNavigate, useParams } from 'react-router-dom';
import OutlinedButton from '../../components/common/OutlinedButton';
import useScrollToTop from '../../hooks/useScrollToTop';
import InterestDetail from '../../components/ProfilePage/InterestDetail';
import Myself from '../../components/ProfilePage/Myself';
import WorkDetail from '../../components/ProfilePage/WorkDetail';
import MyPosts from '../../components/ProfilePage/MyPosts';
import { networkCount } from '../../services/myProfile';
import { getErrorMessage, successStatus } from '../../common';
import { ToastNotifyError } from '../../components/Toast/ToastNotify';
import { PATHS } from '../../constants/urlPaths';
import { LANG, TABS_NAME } from '../../constants/lang';
import Tabs from '../../components/ProfilePage/Tabs';
import Modal from '../../components/Modal';
import InterestForm from '../Interests/InterestForm';
import { capitalizeFirstLetter } from '../../utils/helper';
import CreatePostLayout from '../../components/CreatePost/CreatePostLayout';

const { MYNETWORK } = PATHS;
const { FOLLOWERS, FOLLOWING, CONNECTIONS } = TABS_NAME;
const { LANG_CREATE_POST } = LANG.PAGES.FEED;

const ProfilePage = () => {
  const userData = useSelector((state) => state?.auth?.user) || {};
  const [tab, setTab] = useState('Posts');
  const [networkCounter, setNetworkCounter] = useState({});
  const navigate = useNavigate();
  const { type } = useParams();
  const [isInterestModalOpen, setIsInterestModalOpen] = useState(false);
  const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
  const [typeOfPost, setTypeOfPost] = useState(null);

  // Scrolling to top whenever user comes on this page for the first time
  useScrollToTop();

  useEffect(() => {
    const typeToMatch = type?.toLowerCase();
    setTab(capitalizeFirstLetter(typeToMatch) || 'Posts');
  }, [type]);

  const fetchNetworkCount = async () => {
    const { status, data } = await networkCount();
    if (!successStatus(status)) {
      const errormsg = getErrorMessage(data);
      if (errormsg) {
        ToastNotifyError(errormsg);
      }
    } else {
      setNetworkCounter(data?.data);
    }
  };

  useEffect(() => {
    fetchNetworkCount();
  }, []);

  const handleOpenPopup = (type) => {
    setTypeOfPost(type);
    setIsCreatePostModalOpen(true);
  };

  const handleUpdateTab = (value) => {
    value = value?.toLowerCase();
    setTab(capitalizeFirstLetter(value)); // Captializing the first letter because the tab has such values
    navigate(`${PATHS.PROFILE}/${value}`, { replace: true });
  };

  return (
    <ProfileLayout>
      <div className="col-span-10 md:col-span-12 xs:col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-3 lg:sticky top-[61px] z-40 lg:h-fit	">
        {/* The profile info - image, name etc. */}
        <div
          className="flex text-[15px] md:text-[18px] lg:text-[24px] py-4 sticky h-fit cursor-pointer font-medium"
          onClick={() => navigate(PATHS.HOME)}
        >
          <img src={backIcon} alt="backIcon" className="w-[20px] lg:w-[30px]" />
          My Profile
        </div>
        <ProfileContainer userData={userData} />
        <FollowerContainer
          {...networkCounter}
          onFollowersClick={() => navigate(`${MYNETWORK}?type=${FOLLOWERS}`)}
          onFollowingClick={() => navigate(`${MYNETWORK}?type=${FOLLOWING}`)}
          onConnectionsClick={() => navigate(`${MYNETWORK}?type=${CONNECTIONS}`)}
        />

        {tab === 'Work' ? (
          <div className="text-right ml-auto flex hover:opacity-70 justify-end lg:justify-center mb-[20px]">
            <OutlinedButton
              label={'Add Career'}
              showArrowIcon={false}
              add
              additionalClassNames="w-auto lg:w-full justify-center"
              onClick={() => navigate(PATHS.PROFILE_ADD_EDIT_CAREER)}
            />
          </div>
        ) : tab === 'Interests' ? (
          <>
            <div className="text-right ml-auto flex hover:opacity-70 justify-end lg:justify-center mb-[20px]">
              <OutlinedButton
                label={'Add Interests'}
                showArrowIcon={false}
                add
                additionalClassNames="w-auto lg:w-full justify-center"
                onClick={() => setIsInterestModalOpen(true)}
              />
            </div>
            <Modal
              isTitle={true}
              title={'Add Interest'}
              isOpen={isInterestModalOpen}
              onClose={() => setIsInterestModalOpen(false)}
              width="max-w-[472px]"
              padding={0}
              titleClassNames="pl-0"
            >
              <div className="px-[18px]">
                <InterestForm
                  reloadData={() => {}}
                  type="small"
                  closePopupHandler={() => setIsInterestModalOpen(false)}
                  data={{}}
                />
              </div>
            </Modal>
          </>
        ) : tab === 'Posts' ? (
          <div className="text-right ml-auto flex hover:opacity-70 justify-end lg:justify-center mb-[20px]">
            <OutlinedButton
              label={'Add Posts'}
              showArrowIcon={false}
              add
              additionalClassNames="w-auto lg:w-full justify-center"
              onClick={() => handleOpenPopup('caption')}
            />
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="col-span-10 xs:col-span-12 sm:col-span-12 lg:col-span-8 md:col-span-12 xl:col-span-9 overflow-y-auto py-[12px] lg:my-14">
        <div className="grid grid-cols-12 gap-3 feed-page">
          <div className="col-span-12">
            <div>
              <Tabs tab={tab} updateTab={handleUpdateTab} />

              <div className="show-no-scrollbar overflow-y-auto h-[calc(100vh-220px)] max-h-[calc(100vh-220px)] rounded-lg mt-4">
                {tab === 'Posts' ? (
                  <>
                    <MyPosts other={false} />
                  </>
                ) : tab === 'Work' ? (
                  <>
                    <WorkDetail />
                  </>
                ) : tab === 'Interests' ? (
                  <>
                    <InterestDetail />
                  </>
                ) : tab === 'Myself' ? (
                  <>
                    <Myself />
                  </>
                ) : (
                  <>
                    <Card classNames="p-4 mt-4 h-[calc(100vh-275px)] flex flex-col justify-center item-center m-auto text-center">
                      <img src={noWork} alt="noWork" className="w-[20%] md:w-[10%] mx-auto " />
                      <h4 className="font-semibold text-greydark text-[12px] md:text-[14px] my-2">
                        No work added yet.
                      </h4>
                      <h5 className="font-medium text-greydark text-[10px] md:text-[14px] mb-2">
                        It helps people quickly identify your many talents.
                      </h5>
                      <div className="text-center mx-auto flex mt-2">
                        <OutlinedButton label={'Add'} showArrowIcon={false} add />
                      </div>
                    </Card>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isCreatePostModalOpen}
        onClose={() => setIsCreatePostModalOpen(false)}
        isTitle={true}
        title={LANG_CREATE_POST}
        childrenClassNames="overflow-y-auto"
        padding="p-0"
        titleClassNames=""
        titleParentClassNames="md:m-3 m-0"
        height="h-[100dvh] max-h-[100dvh] md:h-auto"
      >
        <CreatePostLayout
          closePopupHandler={() => {
            setIsCreatePostModalOpen(false);
            setTypeOfPost(null);
          }}
          openTypeOfPost={typeOfPost}
          // reloadData={reloadPosts}
        />
      </Modal>
    </ProfileLayout>
  );
};

export default ProfilePage;
