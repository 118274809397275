import React, { useState } from 'react';
import SearchIcon from '../Icons/SearchIcon';
import CrossIcon from '../Icons/Cross';

const SeeAllPopup = ({
  onClose,
  all = [],
  placeholder = 'Search',
  onItemChecked,
  checkedItems,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const searchInputChangeHandler = (e) => {
    let value = e.target.value;
    setSearchValue(value);
  };
  const handleCheckboxChange = (subtitle, value) => {
    onItemChecked(subtitle, value);
  };
  const convertToLowerCaseWithExceptions = (name) => {
    const lowerCaseSubtitleName = name.trim().toLowerCase();
    const withoutHyphens = lowerCaseSubtitleName.replace(/-/g, '');
    const withoutThe = withoutHyphens.replace(/^the/, '');
    const withoutSpaces = withoutThe.replace(/\s+/g, '');
    if (withoutSpaces === 'attributes') {
      return 'attribute';
    }
    return withoutSpaces;
  };

  const itemsToDisplay = searchValue
    ? all.allItems.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
    : all.allItems;

  return (
    <div className="md:w-[490px] bg-white shadow-md p-[20px]">
      <div className="flex justify-between items-center">
        <div className="flex border-b border-[#A1A0A0]">
          <div className="flex items-center px-[2px]">
            <SearchIcon color="#A1A0A0" />
          </div>
          <input
            onChange={(e) => searchInputChangeHandler(e)}
            value={searchValue}
            placeholder={placeholder}
            className="pb-[2px] pt-[2px]"
          />
        </div>
        <div className="cursor-pointer" onClick={onClose}>
          <CrossIcon />
        </div>
      </div>
      <ul className="flex flex-wrap mt-[20px]">
        {itemsToDisplay.map((item, itemIndex) => (
          <li key={itemIndex} className="mb-2 mr-6 content-style flex justify-between w-[190px]">
            {item.name}
            <input
              type="checkbox"
              className="mr-2 cursor-pointer"
              checked={checkedItems[convertToLowerCaseWithExceptions(all.subtitle)]?.includes(
                item.value,
              )}
              onChange={() => handleCheckboxChange(all.subtitle, item.value)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SeeAllPopup;
