import React, { useEffect, useState } from 'react';
import Card from '../common/Card';
import OutlinedButton from '../common/OutlinedButton';
import edit from '../../assets/images/editIcon.svg';
import { successStatus } from '../../common';
import SpinningLoader from '../common/SpinningLoader';
import { fetchOtherUserWorkInterestList, fetchWorkInterestList } from '../../services/signup';
import InterestView from './InterestView';
import noWork from '../../assets/images/noWork.svg';
import Modal from '../Modal';
import AddAboutInterest from './AddAboutInterest';
import InterestForm from '../../pages/Interests/InterestForm';
import { useDispatch, useSelector } from 'react-redux';
import { enforceAPICalling } from '../../redux/slices/authSlice';

function InterestDetail({ otherUserId = null }) {
  const [isLoading, setIsLoading] = useState({ global: false, edit: false });
  const [interests, setInterests] = useState([]);
  const [aboutInterest, setAboutInterest] = useState('');
  const [isOpenAboutInterestModal, setIsOpenInterestWorkModal] = useState(false);
  const [isInterestModalOpen, setIsInterestModalOpen] = useState(false);
  const { forcefullyCallAPI = false } = useSelector((state) => state.auth || {});
  const dispatch = useDispatch();

  const getInterestsList = async () => {
    if (!isLoading?.global) {
      setIsLoading({ ...isLoading, global: true });
      let response = {};
      if (otherUserId) {
        response = await fetchOtherUserWorkInterestList({ id: otherUserId });
      } else {
        response = await fetchWorkInterestList();
      }
      const { status, data } = response;

      if (successStatus(status)) {
        setInterests(data?.data?.UserInterests);
        setAboutInterest(data?.data?.interest || '');
      }

      setIsLoading({ ...isLoading, global: false });
      dispatch(enforceAPICalling(false));
    }
  };

  useEffect(() => {
    getInterestsList();
  }, [otherUserId]);

  useEffect(() => {
    // If we are enforcing API call, we need to reload the data
    // We are using this in case when we add an interest from <ProfilePage... /> component
    if (forcefullyCallAPI) {
      getInterestsList();
    }
  }, [forcefullyCallAPI]);

  const renderAboutInterest = () => {
    if (aboutInterest) {
      return (
        <div className="p-4 flex flex-col w-full justify-between">
          <div className="flex gap-2 justify-between items-center">
            <div className="text-blueprimary text-[16px] font-semibold">About Interest</div>

            {otherUserId ? (
              ''
            ) : (
              <div className="flex items-center justify-between">
                <div
                  className="bg-iconBackground p-1 rounded cursor-pointer"
                  onClick={() => setIsOpenInterestWorkModal(true)}
                >
                  <img src={edit} alt="edit" />
                </div>
              </div>
            )}
          </div>

          <div className="font-normal text-greydark text-[14px] my-3 text-justify">
            {aboutInterest}
          </div>
        </div>
      );
    } else if (!aboutInterest && !otherUserId) {
      return (
        <div className="p-4 flex flex-col">
          <p className="text-[16px] font-semibold text-greydark w-full md:w-[80%]">
            {`Add some description about your interests. Feel free to provide details or specific information you'd
              like to include.`}
          </p>

          <div className="text-center hover:opacity-70 mt-3 pt-4">
            <OutlinedButton
              label={'Add About Interest'}
              showArrowIcon={false}
              add
              onClick={() => setIsOpenInterestWorkModal(true)}
            />
          </div>
        </div>
      );
    }
  };

  const renderInterests = () => {
    if (interests?.length) {
      return (
        <div className="flex flex-col gap-6 mt-6">
          {interests?.map((interest) => (
            <InterestView
              key={interest?.id}
              interest={interest}
              isEditable={!otherUserId}
              reloadData={getInterestsList}
            />
          ))}
        </div>
      );
    } else if (!otherUserId) {
      return (
        <p className="px-4 pt-4 w-full md:w-[80%] text-[16px] font-semibold mt-8 text-greydark">
          Your information will be grouped and displayed by interest.
        </p>
      );
    }
  };

  return isLoading?.global ? (
    <Card classNames="p-4 h-[calc(100vh-275px)]">
      <div className="h-full w-full flex justify-center items-center">
        <SpinningLoader width="w-8" height="h-8" />
      </div>
    </Card>
  ) : !aboutInterest && !interests?.length && otherUserId ? (
    <Card classNames="p-4 h-[calc(100vh-275px)] flex flex-col justify-center item-center m-auto text-center">
      <img src={noWork} alt="noWork" className="w-[20%] md:w-[10%] mx-auto " />
      <h4 className="font-semibold text-greydark text-[12px] md:text-[14px] my-2">
        No Interests added yet.
      </h4>
    </Card>
  ) : (
    <Card classNames="mt-4 min-h-[calc(100vh-275px)]">
      {renderAboutInterest()}
      {renderInterests()}

      {!otherUserId ? (
        <>
          <div className="text-center flex mb-4 px-4 pb-12 pt-4 hover:opacity-70">
            <OutlinedButton
              label={'Add Interests'}
              showArrowIcon={false}
              add
              onClick={() => setIsInterestModalOpen(true)}
            />
          </div>
          <Modal
            isOpen={isOpenAboutInterestModal}
            onClose={() => setIsOpenInterestWorkModal(false)}
            isTitle={true}
            title={`${aboutInterest ? 'Update' : 'Add'} About Interest`}
            childrenClassNames="overflow-y-auto"
            padding="p-0"
            titleClassNames=""
            titleParentClassNames="md:m-3 m-0"
            height=" max-h-[100dvh] md:h-auto"
          >
            <AddAboutInterest
              interest={aboutInterest}
              onCloseHandler={() => {
                setIsOpenInterestWorkModal(false);
                getInterestsList();
              }}
            />
          </Modal>

          <Modal
            isTitle={true}
            title={'Add Interest'}
            isOpen={isInterestModalOpen}
            onClose={() => setIsInterestModalOpen(false)}
            width="max-w-[472px]"
            padding={0}
            titleClassNames="pl-0"
          >
            <div className="px-[18px]">
              <InterestForm
                reloadData={getInterestsList}
                type="small"
                closePopupHandler={() => setIsInterestModalOpen(false)}
                data={{}}
              />
            </div>
          </Modal>
        </>
      ) : (
        ''
      )}
    </Card>
  );
}

export default InterestDetail;
