import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import BlueDivider from '../../components/common/BlueDivider';
import { Button } from '../../components/common/Button';
import OutlinedButton from '../../components/common/OutlinedButton';
import { AddBlueIcon } from '../../components/Icons/AddBlueIcon';
import Modal from '../../components/Modal';
import TextArea from '../../components/TextArea';
import {
  fetchProfileEdit,
  fetchUserInterestById,
  fetchWorkInterestList,
} from '../../services/signup';
import { validationSchemaInterest } from '../../validations';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../constants/urlPaths';
import { useDispatch } from 'react-redux';
import { updateSignup } from '../../redux/slices/authSlice';
import { LIMITS } from '../../constants/constants';
import { getErrorMessage, successStatus } from '../../common';
import { ToastNotifyError, ToastNotifySuccess } from '../../components/Toast/ToastNotify';
import InterestForm from './InterestForm';
import { SimpleClickableChips } from '../../components/Chips';
import InterestVideoIcon from '../../components/Icons/InterestVideoIcon';
import EditBlueIcon from '../../components/Icons/EditBlueIcon';
import SpinningLoader from '../../components/common/SpinningLoader';
import PreviewMediaFile from '../../components/PreviewMediaFile';

const { HOME } = PATHS;
const initialWork = {
  interest: '',
};

export function InterestsTabContent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isInterestModalOpen, setIsInterestModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({ about: false, global: false, edit: false });
  const [interestsData, setInterestsData] = useState([]);
  const [selectedInterest, setSelectedInterest] = useState(0);
  const [selectedInterestData, setSelectedInterestData] = useState({});
  const [previewMedia, setPreviewMedia] = useState({ type: '', isOpen: false, fileSrc: '' });

  const fetchUserInterests = async () => {
    setIsLoading({ ...isLoading, global: true });
    const response = await fetchWorkInterestList();
    const {
      status,
      data: { data },
    } = response;

    if (successStatus(status)) {
      setInterestsData(data);
      setSelectedInterest(0);
      setFieldValue('interest', data?.interest);
    }

    setIsLoading({ ...isLoading, global: false });
  };

  useEffect(() => {
    setSelectedInterestData({});
  }, [selectedInterest]);

  const aboutInterestSubmitHandler = async () => {
    if (!isLoading?.about) {
      setIsLoading({ ...isLoading, about: true });
      const response = await fetchProfileEdit({
        interest,
      });
      const { status, data } = response;
      const errormsg = getErrorMessage(data);
      if (successStatus(status)) {
        ToastNotifySuccess('Description saved successfully', 'location-success');
        fetchUserInterests();
      } else {
        if (errormsg) {
          ToastNotifyError(errormsg, '');
        }
      }
      setIsLoading({ ...isLoading, about: false });
    }
  };

  useEffect(() => {
    fetchUserInterests();
  }, []);

  // This function creates image URLs from file objects
  const renderImages = () => {
    const images =
      interestsData?.UserInterests?.[selectedInterest]?.UserInterestMedia?.filter(
        (item) => item?.type !== 'video',
      ) || [];

    if (images?.length) {
      return (
        <div className="flex flex-col gap-3 pt-2 flex-wrap">
          <div className="font-medium text-greydark text-[10px] md:text-[14px] ">
            Photos ({images?.length}/{LIMITS.INTEREST_MAX_NUMBER_OF_IMAGES})
          </div>
          <div className="flex gap-3 pt-2 flex-wrap">
            {images?.map((file, index) => {
              return (
                <div
                  key={index}
                  className="relative max-h-[55px] h-[55px] w-[55px] max-w-[55px] cursor-pointer"
                  onClick={() =>
                    setPreviewMedia({ isOpen: true, fileSrc: file?.media, type: 'image' })
                  }
                >
                  <img
                    src={file?.media}
                    alt={file.media}
                    className="mb-3 max-h-[55px] h-[55px] w-[55px] max-w-[55px] rounded-md object-cover bg-[#b6ddff]"
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return '';
  };

  const renderVideos = () => {
    const videos =
      interestsData?.UserInterests?.[selectedInterest]?.UserInterestMedia?.filter(
        (item) => item?.type === 'video',
      ) || [];

    if (videos?.length) {
      return (
        <div className="flex flex-col gap-3 pt-2 flex-wrap">
          <div className="font-medium text-greydark text-[10px] md:text-[14px]">
            Videos ({videos?.length}/{LIMITS.INTEREST_MAX_NUMBER_OF_VIDEOS})
          </div>
          <div className="flex gap-3 pt-2 flex-wrap">
            {videos?.map((file, index) => {
              return (
                <div
                  key={index}
                  className="relative max-h-[55px] h-[55px] w-[55px] max-w-[55px] cursor-pointer"
                  onClick={() =>
                    setPreviewMedia({ isOpen: true, fileSrc: file?.media, type: 'video' })
                  }
                >
                  <video
                    src={file?.media}
                    className="mb-3 max-h-[55px] h-[55px] w-[55px] max-w-[55px] rounded-md object-cover"
                    controls={false}
                  />
                  <div className="rounded-md w-full h-full absolute top-0 left-0 bg-[#00000085] flex items-center justify-center">
                    <InterestVideoIcon />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return '';
  };

  const onSkipHandler = async () => {
    await dispatch(updateSignup(false));
    navigate(HOME, { replace: true });
    window.location.reload();
  };

  const editModalOpenHandler = async () => {
    if (isLoading?.edit) {
      return;
    }

    setIsLoading({ ...isLoading, edit: true });
    const { status, data } = await fetchUserInterestById({
      id: interestsData?.UserInterests?.[selectedInterest]?.id,
    });

    if (successStatus(status)) {
      setIsInterestModalOpen(true);
      setSelectedInterestData(data?.data);
    } else {
      const errormsg = getErrorMessage(data);
      if (errormsg) {
        ToastNotifyError(errormsg);
      }
    }
    setIsLoading({ ...isLoading, edit: false });
  };

  const formikInterest = useFormik({
    initialValues: initialWork,
    validationSchema: validationSchemaInterest,
    onSubmit: aboutInterestSubmitHandler,
  });

  const {
    values: { interest = '' },
    touched: { title: tuc_interest },
    errors: { title: err_interest },
    setFieldValue,
  } = formikInterest;

  return (
    <div className="py-[36px] lg:px-[70px] md:px-[40px] px-[20px] bg-bluebg min-h-[92vh]">
      <div className="tab-content-title">So far so good. Let’s talk about your Interests</div>
      <div className="tab-content-subtitle">We use this info for better reach.</div>

      <div className="md:flex block items-center mt-8 mb-5">
        <div className="w-[170px] form-title md:pb-0 pb-2">About Interests</div>
        <div className="grow">
          <TextArea
            height="h-[160px]"
            placeholder="Enter Description"
            value={interest}
            onChange={(e) => formikInterest.setFieldValue('interest', e.target.value)}
            error={tuc_interest && err_interest}
            helperText={tuc_interest && err_interest}
            customMaxLength={LIMITS.MAX_ABOUT_INTEREST_LENGTH}
          />
          <div className="w-full text-right text-xs text-greylight">
            {interest?.trim().length}/{LIMITS.MAX_ABOUT_INTEREST_LENGTH}
          </div>
        </div>
      </div>

      <div className="grid justify-items-end pb-8">
        <Button
          isDisabled={interestsData?.interest === interest}
          label="Save"
          type="submit"
          showArrowIcon={false}
          onlyShowLoaderWhenLoading={true}
          onClick={aboutInterestSubmitHandler}
          isLoading={isLoading?.about}
        />
      </div>

      <div className="mb-8 flex justify-between">
        <div className="step-title">
          Interests
          <BlueDivider width={'50%'} />
        </div>
      </div>

      {interestsData?.UserInterests?.length === 0 ? (
        <InterestForm reloadData={fetchUserInterests} />
      ) : (
        <div className="flex flex-col gap-3">
          <div className="text-[14px] font-medium text-greydark flex gap-4 items-center justify-between">
            <div className="flex justify-center items-center gap-3">
              <p>Title</p>
              <div className="flex gap-3">
                {interestsData?.UserInterests?.map((interest, _i) => {
                  return (
                    <SimpleClickableChips
                      key={interest?.id}
                      label={interest?.title}
                      className={
                        selectedInterest === _i ? '!bg-blueprimary text-white' : ' text-greydark'
                      }
                      onClick={() => setSelectedInterest(_i)}
                    />
                  );
                })}
              </div>
            </div>

            <div>
              <div
                className="cursor-pointer bg-[#0081CC0D] rounded w-9 h-9 flex items-center justify-center"
                onClick={editModalOpenHandler}
              >
                {isLoading?.edit ? (
                  <SpinningLoader marginLeft="" marginRight="" />
                ) : (
                  <EditBlueIcon />
                )}
              </div>
            </div>
          </div>
          <div className="pt-6 pb-10">
            {[null, undefined].includes(selectedInterest) ? (
              <p className="text-[14px] font-medium">No media to show</p>
            ) : interestsData?.UserInterests?.[selectedInterest]?.UserInterestMedia?.length ? (
              <div className="flex flex-col gap-3">
                {renderImages()}
                {renderVideos()}
              </div>
            ) : (
              <p className="text-[14px] font-medium">No media Uploaded</p>
            )}
          </div>
        </div>
      )}

      <div>
        <div className="mt-9 flex md:flex-row flex-col md:justify-between justify-normal md:items-center items-stretch gap-7 md:gap-4 flex-wrap">
          <div className="flex gap-4 flex-wrap">
            <div>
              <OutlinedButton
                label="Add Interests"
                Icon={<AddBlueIcon />}
                onClick={() => {
                  setSelectedInterestData({});
                  setIsInterestModalOpen(true);
                }}
                disabled={
                  interestsData?.UserInterests?.length === 0 ||
                  interestsData?.UserInterests?.length === 10
                }
              />
            </div>
          </div>

          <div className="flex gap-4 flex-wrap items-center ml-auto md:ml-0">
            <div>
              <OutlinedButton label="Skip" isSkip={true} onClick={() => onSkipHandler()} />
            </div>
            <div>
              <div>
                <Button
                  label="Next"
                  showArrowIcon={false}
                  onClick={() => {
                    navigate(PATHS.PATH_MYSELF);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isTitle={true}
        title={`${selectedInterestData?.id ? 'Edit' : 'Add'}  Interest`}
        isOpen={isInterestModalOpen}
        onClose={() => setIsInterestModalOpen(false)}
        width="max-w-[472px]"
        padding={0}
        titleClassNames="pl-0"
      >
        <div className="px-[18px]">
          <InterestForm
            reloadData={fetchUserInterests}
            type="small"
            closePopupHandler={() => setIsInterestModalOpen(false)}
            data={selectedInterestData}
            origin="SIGNUP"
          />
        </div>
      </Modal>

      <PreviewMediaFile {...previewMedia} onCloseHandler={() => setPreviewMedia({})} />
    </div>
  );
}
