import React from 'react';

const VideoIcon = ({ fill = '#DE0B0B' }) => {
  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Video">
        <g id="Iconly/Light outline/Video">
          <g id="Video_2">
            <path
              id="Vector"
              d="M12.6284 4.5C15.0364 4.5 16.7129 6.03397 16.8914 8.28433L19.5232 7.20209C20.6619 6.73473 21.9043 7.52881 21.9947 8.73549L22 8.8765V15.5155C22 16.7527 20.7997 17.6089 19.6546 17.2371L19.5228 17.1888L16.8914 16.1064C16.7125 18.3553 15.0326 19.891 12.6284 19.891H6.39603C3.84592 19.891 2.11719 18.1719 2.11719 15.703V8.688C2.11719 6.21909 3.84592 4.5 6.39603 4.5H12.6284ZM12.6284 6H6.39603C4.6657 6 3.60842 7.05139 3.60842 8.688V15.703C3.60842 17.3396 4.6657 18.391 6.39603 18.391H12.6284C14.3545 18.391 15.416 17.3369 15.416 15.703L15.4158 15.0174C15.4155 15.0068 15.4154 14.9961 15.4156 14.9854L15.416 8.688C15.416 7.05178 14.3581 6 12.6284 6ZM20.1544 8.57159L20.087 8.59075L16.9072 9.897V14.493L20.0866 15.8001C20.2661 15.8737 20.46 15.7644 20.5009 15.5856L20.5088 15.5155V8.8765C20.5088 8.68171 20.3344 8.54221 20.1544 8.57159Z"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default VideoIcon;
