import { formatDistanceToNow } from 'date-fns';
import Avatar from '../../components/common/Avatar';
import { NOTIFICATION_TYPES } from '../../constants/constants';

const { LIKE, COMMENT, FOLLOW, POST_WARNING, USER_WARNING, COMMENT_WARNING, CUSTOM } =
  NOTIFICATION_TYPES;

const formatTimeDifference = (timestamp) => {
  if (timestamp) {
    const notificationDate = new Date(timestamp);
    const formattedDistance = formatDistanceToNow(notificationDate, {
      addSuffix: true,
    });
    return formattedDistance;
  }
  return '';
};

const NotificationItem = ({
  count = 0,
  item,
  i,
  handleClick = () => {},
  totalLength = 0,
  origin,
}) => {
  let userData = item?.FromUser;
  if (!userData) {
    userData = item?.data?.[0]?.FromUser;
    item = item?.data?.[0];
  }

  return (
    <div
      key={item?.id}
      className={`${origin === 'HOME' ? 'px-1' : 'px-4 md:pl-10'}  ${
        !item?.markAsRead ? 'bg-[#F5FBFF]' : ''
      }  relative cursor-pointer`}
      onClick={() =>
        handleClick(item?.notificationType, item?.PostId, item?.id, item?.markAsRead, userData?.id)
      }
    >
      {!item?.markAsRead && origin !== 'HOME' ? <div className="dot-icon" /> : ''}

      <div className="flex pt-4 pb-4">
        <div className="mr-2.5">
          <Avatar
            classNames="w-[45px] h-[45px] object-cover"
            image={userData?.profilePictureUrl}
            name={`${userData?.firstName} ${userData?.lastName}`}
          />
        </div>
        <div className="block w-full">
          <div className="text-[14px] font-normal text-[#333333]">
            <span className="font-medium capitalize">
              {userData?.firstName} {userData?.lastName}
            </span>
            {count > 1 && (item?.notificationType || item?.[0]?.notificationType) === LIKE
              ? ` and ${count - 1} others liked your post`
              : count > 1 && (item?.notificationType || item?.[0]?.notificationType) === COMMENT
                ? ` and ${count - 1} others commented on your post`
                : (item?.notificationType || item?.[0]?.notificationType) === LIKE
                  ? `${' '}liked your post`
                  : (item?.notificationType || item?.[0]?.notificationType) === COMMENT
                    ? `${' '}commented on your post`
                    : (item?.notificationType || item?.[0]?.notificationType) === FOLLOW
                      ? `${' '}followed you`
                      : [CUSTOM, COMMENT_WARNING, POST_WARNING, USER_WARNING]?.includes(
                            item?.notificationType || item?.[0]?.notificationType,
                          )
                        ? `: ${item?.notificationBody}`
                        : ''}
          </div>
          <div className="text-[12px] font-normal text-[#A1A0A0]">
            {formatTimeDifference(item?.createdAt)}
          </div>
        </div>
      </div>
      {i !== totalLength - 1 && <hr style={{ color: '#E8E8E8' }} />}
    </div>
  );
};

export default NotificationItem;
