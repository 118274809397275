export const PurdrivenText = () => {
  return (
    <svg width="93" height="16" viewBox="0 0 93 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.8978 0.145752C8.33511 0.145752 10.5631 1.33016 10.5631 4.40505C10.5631 7.6485 7.91184 8.76912 4.73036 8.76912H2.66984V13.5705C2.66984 14.8187 1.7768 15.0465 1.33492 15.0465C0.888401 15.0465 0 14.8187 0 13.5705V1.70825C0 0.646846 0.511641 0.150308 1.59074 0.150308H4.8978V0.145752ZM2.66984 6.56431H4.53501C6.27459 6.56431 7.86533 6.14977 7.86533 4.40505C7.86533 2.66033 6.27459 2.34601 4.61873 2.34601H2.66519V6.56431H2.66984Z"
        fill="white"
      />
      <path
        d="M20.9629 13.7345C20.9629 14.8551 20.135 15.0419 19.7536 15.0419C19.3722 15.0419 18.5443 14.8551 18.5443 13.7345V13.2562H18.5024C18.0559 14.2538 16.8465 15.1467 15.3395 15.1467C12.6883 15.1467 11.5859 13.1514 11.5859 11.1151V6.08597C11.5859 4.90157 12.4371 4.69202 12.8604 4.69202C13.2837 4.69202 14.1348 4.90157 14.1348 6.08597V10.409C14.1348 11.5934 14.4093 13.0694 16.0465 13.0694C17.6605 13.0694 18.4233 11.6982 18.4233 10.368V6.08597C18.4233 4.90157 19.2699 4.69202 19.6978 4.69202C20.1211 4.69202 20.9722 4.90157 20.9722 6.08597V13.7345H20.9629Z"
        fill="white"
      />
      <path
        d="M25.1651 15.0419C24.7418 15.0419 23.8906 14.8324 23.8906 13.648V6.02223C23.8906 4.9016 24.7186 4.69205 25.1 4.69205C25.5046 4.69205 26.3326 4.9016 26.3326 6.02223V6.54155H26.3744C26.7791 5.46192 27.7977 4.58728 29.0256 4.58728C29.7885 4.58728 30.342 4.96082 30.342 5.68969C30.342 6.332 29.9373 6.89231 28.9605 6.89231C27.3465 6.89231 26.4349 8.07672 26.4349 9.73944V13.648C26.4395 14.8369 25.593 15.0419 25.1651 15.0419Z"
        fill="white"
      />
      <path
        d="M33.206 14.8597C32.1222 14.8597 31.6152 14.3586 31.6152 13.3018V1.70828C31.6152 0.646867 32.1269 0.15033 33.206 0.15033H36.9409C40.8666 0.15033 45.2388 1.97704 45.2388 7.5073C45.2388 12.6595 40.8666 14.8643 36.9409 14.8643H33.206V14.8597ZM34.2897 12.5729H36.5177C39.2759 12.5729 42.3736 11.4705 42.3736 7.50275C42.3736 3.28444 39.2759 2.39159 36.5177 2.39159H34.2897V12.5729Z"
        fill="white"
      />
      <path
        d="M48.8487 15.0419C48.4254 15.0419 47.5742 14.8324 47.5742 13.648V6.02223C47.5742 4.9016 48.4021 4.69205 48.7835 4.69205C49.1882 4.69205 50.0161 4.9016 50.0161 6.02223V6.54155H50.058C50.4627 5.46192 51.4813 4.58728 52.7092 4.58728C53.472 4.58728 54.0256 4.96082 54.0256 5.68969C54.0256 6.332 53.6209 6.89231 52.6441 6.89231C51.0301 6.89231 50.1185 8.07672 50.1185 9.73944V13.648C50.1185 14.8369 49.2719 15.0419 48.8487 15.0419Z"
        fill="white"
      />
      <path
        d="M56.4617 2.9929C55.5267 2.9929 54.8477 2.28681 54.8477 1.49873C54.8477 0.710643 55.5267 0 56.4617 0C57.3966 0 58.1175 0.646867 58.1175 1.49873C58.1175 2.34603 57.3966 2.9929 56.4617 2.9929ZM56.4849 15.0419C56.0617 15.0419 55.2105 14.8324 55.2105 13.648V6.08146C55.2105 4.89705 56.0617 4.68751 56.4849 4.68751C56.9082 4.68751 57.7594 4.89705 57.7594 6.08146V13.648C57.7594 14.8369 56.9082 15.0419 56.4849 15.0419Z"
        fill="white"
      />
      <path
        d="M64.6762 15.0419C64.0622 15.0419 63.318 14.7913 62.8297 13.6707L59.7319 6.58251C59.6063 6.29096 59.5645 6.06319 59.5645 5.87642C59.5645 5.19311 60.1133 4.69202 60.8575 4.69202C61.2575 4.69202 61.7692 4.83779 62.1087 5.71243L64.653 12.1538H64.6948L67.3042 5.69421C67.6437 4.84235 68.1507 4.69657 68.5368 4.69657C69.281 4.69657 69.788 5.19767 69.788 5.83998C69.788 5.96753 69.7647 6.21352 69.6159 6.54606L66.5181 13.6753C66.0344 14.7959 65.2716 15.0419 64.6762 15.0419Z"
        fill="white"
      />
      <path
        d="M73.4388 10.6596C73.5458 12.0718 74.8621 13.1104 76.3458 13.1104C77.4063 13.1104 78.0436 12.7779 78.6808 12.2586C79.0436 11.967 79.2762 11.9033 79.5506 11.9033C80.1227 11.9033 80.5692 12.295 80.5692 12.9009C80.5692 13.1104 80.4855 13.4794 80.1459 13.7937C79.1506 14.7276 78.0017 15.1421 76.3691 15.1421C73.2295 15.1421 70.8945 13.1697 70.8945 9.92621C70.8945 6.70554 73.2062 4.58728 76.2854 4.58728C78.7459 4.58728 80.7413 5.95846 81.146 8.88758C81.1646 9.09713 81.1878 9.2429 81.1878 9.40689C81.1878 10.2815 80.6994 10.6551 79.8529 10.6551H73.4388V10.6596ZM78.6622 8.86936C78.6622 7.58018 77.918 6.45955 76.2621 6.45955C74.7551 6.45955 73.5458 7.4754 73.4388 8.86936H78.6622Z"
        fill="white"
      />
      <path
        d="M83.5405 6.00397C83.5405 4.88334 84.3685 4.69657 84.7499 4.69657C85.1313 4.69657 85.9592 4.88334 85.9592 6.00397V6.48229H86.0011C86.4476 5.48465 87.6569 4.5918 89.164 4.5918C91.8152 4.5918 92.9175 6.58706 92.9175 8.62333V13.6525C92.9175 14.8369 92.071 15.0464 91.6431 15.0464C91.2198 15.0464 90.3686 14.8369 90.3686 13.6525V9.32941C90.3686 8.14501 90.0942 6.66906 88.457 6.66906C86.8476 6.66906 86.0801 8.04023 86.0801 9.37041V13.6525C86.0801 14.8369 85.2336 15.0464 84.8057 15.0464C84.3824 15.0464 83.5312 14.8369 83.5312 13.6525V6.00397H83.5405Z"
        fill="white"
      />
    </svg>
  );
};
