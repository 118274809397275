import { getFileName } from './helper';

const handleDownload = async (path) => {
  if (!path) return;

  try {
    const response = await fetch(path);
    if (!response.ok) throw new Error('Network response was not ok');

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = getFileName(path) || 'File'; // Use a function to extract filename or a default name
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Failed to download file:', error);
  }
};

export default handleDownload;
