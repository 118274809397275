import React, { useEffect, useState } from 'react';
import SectionLayout from '../../components/PrivateLayout/SectionLayout';
import Pagination from '../../components/Pagination';
import noWork from '../../assets/images/noWork.svg';
import './style.scss';
import InnerSectionLayout from '../../components/PrivateLayout/InnerSectionLayout';
import { ToastNotifyError } from '../../components/Toast/ToastNotify';
import { getErrorMessage, successStatus } from '../../common';
import {
  markReadDispatcher,
  notificationListDispatcher,
} from '../../redux/dispatchers/notificationDispatcher';
import { useDispatch } from 'react-redux';
import { fetchPostDetails } from '../../services/feed';
import Modal from '../../components/Modal';
import PostDetails from '../../components/Post/PostDetails';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../constants/urlPaths';
import useScrollToTop from '../../hooks/useScrollToTop';
import NotificationItem from './NotificationItem';
import { NOTIFICATION_TYPES } from '../../constants/constants';
let PageSize = 10;

const NotificationPage = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const [isPreviewDetailsPostOpen, setIsPreviewDetailsPostOpen] = useState(false);
  const [activePost, setActivePost] = useState({});
  const [activeMediaIndex, setActiveMediaIndex] = useState(0);

  useScrollToTop();

  const fetchnotificationList = async () => {
    const { status, data } = await dispatch(
      notificationListDispatcher({
        page: currentPage,
      }),
    );
    if (!successStatus(status)) {
      const errormsg = getErrorMessage(data);
      if (errormsg) {
        ToastNotifyError(errormsg);
      }
    } else {
      setCurrentPage(data?.data?.page);
      setDataList(data?.data?.notifications);
      setTotalCount(data?.data?.count);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    fetchnotificationList();
  }, [currentPage]);

  const fetchSinglePostDetails = async (postId) => {
    const response = await fetchPostDetails({ postId });
    const { status, data } = response;
    const errormsg = getErrorMessage(data);
    if (!successStatus(status)) {
      ToastNotifyError(errormsg, '');
      setIsPreviewDetailsPostOpen(false);
    } else {
      setActivePost(data?.data);
    }
  };

  const handleClick = async (type, postId, notificationId, markAsRead, userId) => {
    if (postId) {
      if (!markAsRead) {
        const { status, data } = await dispatch(
          markReadDispatcher({ NotificationId: Number(notificationId) }),
        );
        if (!successStatus(status)) {
          const errormsg = getErrorMessage(data);
          if (errormsg) {
            ToastNotifyError(errormsg);
          }
        } else {
          fetchnotificationList();
          if (type !== NOTIFICATION_TYPES.CUSTOM) {
            fetchSinglePostDetails(postId);
            setActiveMediaIndex(postId);
            setIsPreviewDetailsPostOpen(true);
          }
        }
      } else {
        if (type !== NOTIFICATION_TYPES.CUSTOM) {
          fetchSinglePostDetails(postId);
          setActiveMediaIndex(postId);
          setIsPreviewDetailsPostOpen(true);
        }
      }
    } else {
      if (userId) {
        const { status, data } = await dispatch(
          markReadDispatcher({ NotificationId: Number(notificationId) }),
        );
        if (!successStatus(status)) {
          const errormsg = getErrorMessage(data);
          if (errormsg) {
            ToastNotifyError(errormsg);
          }
        } else {
          if (
            [
              NOTIFICATION_TYPES.CUSTOM,
              NOTIFICATION_TYPES.USER_WARNING,
              NOTIFICATION_TYPES.POST_WARNING,
              NOTIFICATION_TYPES.COMMENT_WARNING,
            ]?.includes(type)
          ) {
            await fetchnotificationList();
          } else {
            navigate(`${PATHS.OTHER_USER_PROFILE}${userId}`);
          }
        }
      }
    }
  };

  return (
    <SectionLayout activeTab={3}>
      <InnerSectionLayout heading={'Notifications'}>
        <div className="h-auto">
          {dataList?.length > 0 ? (
            dataList.map((item, i) => {
              return (
                <NotificationItem
                  key={item?.id}
                  count={item?.count}
                  item={item}
                  i={i}
                  handleClick={handleClick}
                  totalLength={dataList?.length}
                  reloadNotificationList={fetchnotificationList}
                />
              );
            })
          ) : (
            <div className="p-4 mt-4 h-[calc(100vh-275px)] flex flex-col justify-center item-center m-auto text-center">
              <img src={noWork} alt="noWork" className="w-[20%] md:w-[10%] mx-auto " />
              <h4 className="font-semibold text-greydark text-[12px] md:text-[14px] my-2">
                No notification yet.
              </h4>
              <h5 className="font-medium text-greydark text-[10px] md:text-[14px] mb-2">
                It helps people quickly identify your many talents.
              </h5>
            </div>
          )}
        </div>
        <div className="py-4 flex items-center justify-end mt-auto">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </InnerSectionLayout>
      <Modal
        isOpen={isPreviewDetailsPostOpen}
        onClose={() => {
          setActivePost({});
          setActiveMediaIndex(0);
          fetchnotificationList();
          setIsPreviewDetailsPostOpen(false);
        }}
        isTitle={false}
        width={` ${
          !activePost?.postMedia?.length ? '!w-[100vw] md:!w-[45vw]' : '!w-[100vw] md:!w-[75vw]'
        } `}
        childrenClassNames=""
        padding="!p-0"
        titleClassNames=""
        titleParentClassNames="md:m-3 m-0"
        height={` ${
          !activePost?.postMedia?.length
            ? 'max-h-[100dvh] md:h-auto'
            : 'h-[100dvh] max-h-[100dvh] md:h-auto'
        } `}
      >
        <PostDetails
          post={activePost}
          reloadPostDetails={fetchSinglePostDetails}
          customActiveIndex={activeMediaIndex}
          onCloseHandler={() => {
            setActivePost({});
            setActiveMediaIndex(0);
            fetchnotificationList();
            setIsPreviewDetailsPostOpen(false);
          }}
          reloadPosts={() => {
            // Not required here as we dont have to reload the posts being on Notif page
          }}
        />
      </Modal>
    </SectionLayout>
  );
};

export default NotificationPage;
