import React from 'react';

const LikeFilledIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon/Filled/heart">
        <path
          id="Vector"
          d="M12.9996 21.6673C12.7984 21.6673 12.6282 21.5996 12.4889 21.4642L5.24604 14.6708C5.16866 14.6106 5.06226 14.5128 4.92684 14.3774C4.79142 14.242 4.57669 13.9956 4.28264 13.6382C3.9886 13.2809 3.7255 12.9141 3.49336 12.538C3.26122 12.1618 3.05422 11.7067 2.87238 11.1725C2.69053 10.6384 2.59961 10.1193 2.59961 9.61523C2.59961 7.96014 3.09098 6.66616 4.07372 5.73329C5.05645 4.80042 6.41449 4.33398 8.14782 4.33398C8.62759 4.33398 9.11702 4.41486 9.61613 4.57661C10.1152 4.73835 10.5795 4.95652 11.009 5.23112C11.4384 5.50571 11.8079 5.76338 12.1175 6.00412C12.6316 6.40399 13.3676 6.40399 13.8818 6.00412C14.1913 5.76338 14.5608 5.50571 14.9902 5.23112C15.4197 4.95652 15.884 4.73835 16.3831 4.57661C16.8822 4.41486 17.3716 4.33398 17.8514 4.33398C19.5847 4.33398 20.9428 4.80042 21.9255 5.73329C22.9082 6.66616 23.3996 7.96014 23.3996 9.61523C23.3996 11.2778 22.5136 12.9706 20.7416 14.6934L13.5103 21.4642C13.371 21.5996 13.2008 21.6673 12.9996 21.6673Z"
          fill="url(#paint0_linear_1778_5561)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1778_5561"
          x1="2.59961"
          y1="13.0007"
          x2="16.9927"
          y2="3.39779"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E1F63" />
          <stop offset="0.9892" stopColor="#ED4663" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LikeFilledIcon;
