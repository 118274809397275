import React, { useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

const ContactField = (props) => {
  const [value, setValue] = useState(props.initialValue);
  const [countryCode, setCountryCode] = useState(props.countryCode);
  const handleChange = (e, countryData) => {
    setValue(e);
    setCountryCode(countryData?.countryCode);
    props?.customChange(e, countryData?.countryCode);
  };
  return (
    <div className={props?.parentClassName}>
      <div className="flex gap-[2px]">
        <label style={{ color: props?.labelFontColor }}>{props?.label}</label>
        {props?.isRequired ? <span className="text-red relative top-[-4px]">*</span> : ''}
      </div>
      <PhoneInput
        {...props}
        country={countryCode}
        placeholder="Enter phone number"
        value={value}
        enableSearch
        onChange={(e, countryData) => handleChange(e, countryData)}
        inputStyle={{ width: '100%', borderRadius: '8px', fontSize: '16px', height: 'auto' }}
      />
    </div>
  );
};

export default ContactField;
