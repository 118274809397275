import React from 'react';

const LikeEmptyIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon/Filled/heart">
        <path
          id="Vector"
          d="M20.2238 14.1507L12.9996 20.9149L5.75912 14.1238L5.73385 14.1001L5.70649 14.0788C5.66699 14.048 5.58713 13.977 5.45717 13.8471L4.94805 14.3562L5.45717 13.8471C5.35016 13.74 5.15587 13.5191 4.86178 13.1617C4.58828 12.8293 4.34512 12.4901 4.1316 12.1441C3.93581 11.8268 3.75079 11.4256 3.58236 10.9308C3.42388 10.4653 3.34961 10.0279 3.34961 9.61523C3.34961 8.11694 3.78859 7.03804 4.59007 6.27724C5.39764 5.51064 6.55086 5.08398 8.14782 5.08398C8.54209 5.08398 8.95353 5.15028 9.38491 5.29008C9.82496 5.43269 10.231 5.62386 10.605 5.863C11.0204 6.12863 11.3701 6.373 11.657 6.59614C12.442 7.20666 13.5573 7.20666 14.3422 6.59614L13.8818 6.00412L14.3422 6.59614C14.6291 6.373 14.9788 6.12863 15.3942 5.863C15.7683 5.62386 16.1743 5.43269 16.6143 5.29008L16.3842 4.58006L16.6143 5.29008C17.0457 5.15028 17.4571 5.08398 17.8514 5.08398C19.4484 5.08398 20.6016 5.51064 21.4092 6.27724C22.2106 7.03804 22.6496 8.11694 22.6496 9.61523C22.6496 10.9932 21.9174 12.5026 20.2238 14.1507Z"
          stroke="#A1A0A0"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default LikeEmptyIcon;
