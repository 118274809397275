import { useFormik } from 'formik';
import { Button } from '../../components/common/Button';
import TextArea from '../../components/TextArea';
import { getErrorMessage, successStatus } from '../../common';
import { addAboutMe } from '../../services/myself';
import { useEffect, useState } from 'react';
import { ToastNotifyError, ToastNotifySuccess } from '../Toast/ToastNotify';
import { userProfile } from '../../services/auth';
import { LIMITS } from '../../constants/constants';
import { validationSchemaAboutMe } from '../../validations';

export default function AddAboutMe({
  hideHeadingAndSubHeading = false,
  parentWrapClass = '',
  buttonClass = '',
  headingClass = '',
  setIsSaveClicked = () => {},
  getMyselfData = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [aboutMeData, setAboutMeData] = useState('');

  const getUserData = async () => {
    const response = await userProfile();
    const { data = '', status = '' } = response;
    if (status) {
      const { data: { aboutMyself = '' } = {} } = data;
      setAboutMeData(aboutMyself);
      formikmyself.setFieldValue('aboutMe', aboutMyself);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const initialData = {
    aboutMe: aboutMeData,
  };

  const myselfSubmit = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const response = await addAboutMe({
        aboutMyself: aboutMe,
      });
      const { status = '', data = '' } = response;
      const errormsg = getErrorMessage(data);
      if (successStatus(status)) {
        getUserData();
        getMyselfData();
        ToastNotifySuccess('About me saved successfully', 'location-success');
      } else {
        if (errormsg) {
          ToastNotifyError(errormsg, 'location-failed');
        }
      }
      setIsSaveClicked(true);
      setIsLoading(false);
    }
  };

  const formikmyself = useFormik({
    initialValues: initialData,
    validationSchema: validationSchemaAboutMe,
    onSubmit: myselfSubmit,
  });

  const {
    values: { aboutMe = '' },
    touched: { aboutMe: tuc_aboutMe = false },
    errors: { aboutMe: err_aboutMe },
  } = formikmyself;

  return (
    <>
      {!hideHeadingAndSubHeading ? (
        <>
          <div className="tab-content-title">Share more about who you are.</div>
          <div className="tab-content-subtitle">
            It helps us match you with the right opportunities.
          </div>
        </>
      ) : (
        ''
      )}

      <div className={`md:flex block items-center mt-8 mb-5 ${parentWrapClass}`}>
        <div
          className={`w-[170px] font-medium md:pb-0 pb-2 ${
            headingClass ? 'text-[12px]' : 'text-[20px]'
          }`}
        >
          About me
        </div>
        <div className="grow">
          <TextArea
            height="h-[160px]"
            placeholder="Enter Description"
            value={aboutMe}
            onChange={(e) => formikmyself.setFieldValue('aboutMe', e.target.value)}
            error={tuc_aboutMe && err_aboutMe}
            helperText={!tuc_aboutMe && err_aboutMe}
            customMaxLength={LIMITS.MAX_ABOUT_MYSELF_LENGTH}
          />

          <div className="w-full text-right text-xs text-greylight">
            {aboutMe?.trim().length}/{LIMITS.MAX_ABOUT_WORK_LENGTH}
          </div>
        </div>
      </div>
      <div className={`grid justify-items-end pb-8 ${buttonClass}`}>
        <Button
          label="Save"
          isDisabled={aboutMeData === aboutMe}
          onClick={myselfSubmit}
          showArrowIcon={false}
          onlyShowLoaderWhenLoading={true}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}
