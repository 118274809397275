import NETWORK_CONSTANTS from '../constants/networkConstants';
import apiUtility from '../utils/network/apiUtility';

const {
  FETCH_APPLIED_FILTERS,
  SEARCH_CAREERS,
  SEARCH_INTEREST,
  SEARCH_EXPERIENCE,
  SEARCH_EDUCATION,
  SEARCH_SKILLS,
} = NETWORK_CONSTANTS;

const AppliedFiltersUsers = async ({ queryParams }) => {
  try {
    const url = FETCH_APPLIED_FILTERS(queryParams);
    const data = await apiUtility(url, 'GET');

    return data;
  } catch (error) {
    return error;
  }
};
const SearchCarrers = async ({ search }) => {
  try {
    const url = SEARCH_CAREERS(search);
    const data = await apiUtility(url, 'GET');

    return data;
  } catch (error) {
    return error;
  }
};
const SearchInterest = async ({ search }) => {
  try {
    const url = SEARCH_INTEREST(search);
    const data = await apiUtility(url, 'GET');

    return data;
  } catch (error) {
    return error;
  }
};
const searchEducation = async ({ search }) => {
  try {
    const url = SEARCH_EDUCATION(search);
    const data = await apiUtility(url, 'GET');

    return data;
  } catch (error) {
    return error;
  }
};
const SearchExperience = async ({ search }) => {
  try {
    const url = SEARCH_EXPERIENCE(search);
    const data = await apiUtility(url, 'GET');

    return data;
  } catch (error) {
    return error;
  }
};
const SearchSkills = async ({ search }) => {
  try {
    const url = SEARCH_SKILLS(search);
    const data = await apiUtility(url, 'GET');

    return data;
  } catch (error) {
    return error;
  }
};

export {
  AppliedFiltersUsers,
  SearchCarrers,
  SearchExperience,
  SearchInterest,
  SearchSkills,
  searchEducation,
};
