import React, { useEffect, useState } from 'react';
import InputProfilePicture from '../InputProfilePicture';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { MESSAGES } from '../../constants/messages';
import { REGEX } from '../../constants/constants';
import { Button } from '../common/Button';
import InputBox from '../InputBox';
import {
  fetchFileUPloadAWS,
  fetchGenratePreSignedUrl,
  fetchProfileEdit,
} from '../../services/signup';
import { getErrorMessage, successStatus } from '../../common';
import { ToastNotifyError, ToastNotifySuccess } from '../Toast/ToastNotify';
import { getFileExtension } from '../../utils/helper';
import { useDispatch } from 'react-redux';
import { profileDispatcher } from '../../redux/dispatchers/authDispatcher';
import Modal from '../Modal';
import UpdateEmail from './UpdateEmail';
import { sendOtpToUpdateEmailDispatcher } from '../../redux/dispatchers/myProfileDispatcher';
import TextArea from '../../components/TextArea';
import RadioButtonsGroup from '../../components/common/RadioButtonsGroup';
import ContactField from '../common/ContactField';
import { userProfile } from '../../services/auth';
import { profile } from '../../redux/slices/authSlice';

const { IS_REQUIRED, EMAIL_INVALID, MSG_FIELD_LENGTH } = MESSAGES;
const { EMAIL_PATTERN } = REGEX;

const radioButtonOptions = [
  {
    label: 'Same as About Work',
    value: 'work',
  },
  {
    label: 'Same as About Interests',
    value: 'interest',
  },
  {
    label: 'Same as About Myself',
    value: 'aboutMyself',
  },
  {
    label: 'Want to write on my own',
    value: 'custom',
  },
];

const EditProfile = ({
  firstName = '',
  lastName = '',
  email = '',
  contactEmails = [],
  contactPhoneNumbers = [],
  tagline = '',
  websiteLink = '',
  socialMediaLink = '',
  bio = {},
  profilePictureUrl = '',
  profilePicture = '',
  work = '',
  interest = '',
  aboutMyself = '',
  onClose = () => {},
}) => {
  const [cropImageFile, setCropImageFile] = useState(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyEmailPopupOpen, setIsVerifyEmailPopupOpen] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(0);
  const [selectedRadioValue, setSelectedRadioValue] = useState(bio?.type || 'custom');
  const [countryCode, setCountryCode] = useState(contactPhoneNumbers[0]?.countryCode || 'us');
  const [isImageRemoved, setIsImageRemoved] = useState(false);

  const fetchUserData = async () => {
    const { status, data } = await userProfile(dispatch);
    if (status === 200) {
      await dispatch(profile(data?.data));
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const initialValues = {
    firstName,
    lastName,
    email,
    contactEmails: contactEmails[0] || '',
    phoneNumber: contactPhoneNumbers[0]?.phoneNumber || '',
    taglineDescription: tagline.description,
    websiteLink: websiteLink || '',
    socialMediaLink: socialMediaLink || '',
    bioDescription: bio?.description || '',
    profilePicture,
  };

  const validationSchema = yup.object({
    email: yup.string().matches(EMAIL_PATTERN, EMAIL_INVALID).required(IS_REQUIRED('Email')),
    contactEmails: yup.string().matches(EMAIL_PATTERN, EMAIL_INVALID),
    firstName: yup
      .string()
      .required(IS_REQUIRED('First Name'))
      .max(50, MSG_FIELD_LENGTH('First Name')),
    lastName: yup
      .string()
      .required(IS_REQUIRED('Last Name'))
      .max(50, MSG_FIELD_LENGTH('First Name')),
    websiteLink: yup.string().matches(/^(https:\/\/)/, 'Website link must be a proper link'),
    socialMediaLink: yup
      .string()
      .matches(/^(https:\/\/)/, 'Social Media link must be a proper link'),
    profilePicture: yup.string().nullable(true).optional(),
  });

  const onSubmit = async (values) => {
    if (!isLoading) {
      setIsLoading(true);
      const {
        firstName,
        lastName,
        phoneNumber,
        email,
        contactEmails,
        bioDescription,
        taglineDescription,
        ...rest
      } = values;
      const response = await fetchProfileEdit({
        ...rest,
        firstName: firstName?.trim(),
        lastName: lastName?.trim(),
        // profilePicture: profilePicture || '',
        email: isEmailVerified === 2 ? values?.email?.trim() : email,
        bio: {
          type: selectedRadioValue,
          description: values?.bioDescription || '',
        },
        tagline: {
          type: 'latest',
          description: taglineDescription,
        },
        contactPhoneNumbers: [
          {
            countryCode: countryCode,
            phoneNumber: phoneNumber,
          },
        ],
        contactEmails: contactEmails ? [contactEmails] : [],
      });
      const { status, data } = response;
      const errormsg = getErrorMessage(data);
      if (successStatus(status)) {
        ToastNotifySuccess('Profile updated successfully', 'location-success');
        dispatch(profileDispatcher());
        onClose();
      } else {
        if (errormsg) {
          ToastNotifyError(errormsg, 'location-failed');
        }
      }
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const getPreSignedUrl = async () => {
    setIsLoading(true);
    if (cropImageFile) {
      const response = await fetchGenratePreSignedUrl(
        getFileExtension(cropImageFile?.name),
        'profilePicture',
      );
      const { status = 0, data = {} } = response;
      if (successStatus(status)) {
        const {
          data: { url, key },
        } = data;
        formik.setFieldValue('profilePicture', key);
        await fetchFileUPloadAWS({ url: url, selectedFile: cropImageFile });
      }
    } else if (isImageRemoved) {
      formik.setFieldValue('profilePicture', null);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPreSignedUrl();
  }, [cropImageFile, isImageRemoved]);

  const onVerifyClickHandler = async () => {
    if (![0, 1].includes(isEmailVerified)) {
      return;
    }

    const response = await dispatch(
      sendOtpToUpdateEmailDispatcher({
        email: formik.values.email?.trim(),
      }),
    );
    const { status, data } = response;
    if (!successStatus(status)) {
      const errormsg = getErrorMessage(data);
      if (errormsg) {
        ToastNotifyError(errormsg);
        setIsLoading(false);
      }
    } else {
      setIsVerifyEmailPopupOpen(true);
    }
    setIsEmailVerified(0);
  };

  const handleRadioChange = (value) => {
    setSelectedRadioValue(value);
    switch (value) {
      case 'work':
        formik.setFieldValue('bioDescription', work);
        break;
      case 'interest':
        formik.setFieldValue('bioDescription', interest);
        break;
      case 'aboutMyself':
        formik.setFieldValue('bioDescription', aboutMyself);
        break;
      default:
        if (bio?.type === 'custom') {
          formik.setFieldValue('bioDescription', bio?.description || '');
        } else {
          formik.setFieldValue('bioDescription', '');
        }
        break;
    }
  };

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        className="flex md:flex-row flex-col md:items-start items-center justify-between gap-x-[1.5vw] px-[24px] py-[24px]"
      >
        <div>
          <div className="border border-greymedium rounded-full">
            <InputProfilePicture
              setCropImageFile={setCropImageFile}
              cropImageFile={cropImageFile}
              profilePic={profilePictureUrl}
              width="!w-[100px]"
              height="!h-[100px]"
              iconWidth="40"
              iconHeight="40"
              iconFillColor="#0171bd"
              isFromEditProfile={true}
              dropDownCss="right-[-80px]"
              setIsImageRemoved={setIsImageRemoved}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="w-full flex sm:flex-row flex-col items-center justify-center gap-x-[1vw]">
            <InputBox
              name="firstName"
              type="text"
              label={'First Name'}
              placeholder={'Enter First Name'}
              initialValue={formik?.values?.firstName}
              onChange={formik.handleChange}
              labelFontColor={'#333333'}
              helperText={formik.touched.firstName && formik.errors.firstName}
              isRequired
              className="w-full"
              parentClassName="w-full lg:w-[50%]"
            />
            <InputBox
              name="lastName"
              type="text"
              label={'Last Name'}
              placeholder={'Enter Last Name'}
              initialValue={formik?.values?.lastName}
              onChange={formik.handleChange}
              labelFontColor={'#333333'}
              helperText={formik.touched.lastName && formik.errors.lastName}
              isRequired
              className="w-full"
              parentClassName="w-full lg:w-[50%]"
            />
          </div>
          <div className="flex sm:flex-row flex-col items-center justify-between w-full gap-x-[1vw]">
            <div className="w-full relative">
              <InputBox
                name="email"
                type="email"
                label={'Email address'}
                placeholder={'Enter Email adress'}
                initialValue={formik?.values?.email}
                onChange={formik.handleChange}
                labelFontColor={'#333333'}
                helperText={formik.touched.email && formik.errors.email}
                isRequired
                disabled={isEmailVerified === 2}
                className="pr-13"
                parentClassName="w-full"
              />
              <div className="absolute right-[30px] top-[40px] text-[12px]">
                <span
                  className={`text-center ${
                    formik?.values?.email?.trim() !== email?.trim()
                      ? 'text-blueprimary cursor-pointer font-semibold'
                      : 'text-greydark opacity-40 cursor-not-allowed'
                  } ${isEmailVerified === 2 ? '!cursor-default !text-[#0FBC00]' : ''} `}
                  onClick={() => {
                    if (isEmailVerified !== 2 && formik?.values?.email?.trim() !== email?.trim()) {
                      onVerifyClickHandler();
                    }
                  }}
                >
                  {isEmailVerified === 2 ? 'Verified' : 'Verify'}
                </span>
              </div>
            </div>
            <div className="w-full">
              <InputBox
                name="contactEmails"
                type="contactEmails"
                label={'Contact email'}
                placeholder={'Contact email'}
                initialValue={formik?.values?.contactEmails}
                onChange={formik.handleChange}
                labelFontColor={'#333333'}
                helperText={formik.touched.contactEmails && formik.errors.contactEmails}
                className="pr-13"
                parentClassName="w-full"
              />
            </div>
          </div>
          <div className="flex sm:flex-row flex-col justify-between w-full gap-x-[1vw]">
            <div className="w-full">
              <ContactField
                name="phoneNumber"
                placeholder={'Contact No.'}
                label={'Contact No.'}
                initialValue={formik?.values?.phoneNumber}
                customChange={(e, country) => {
                  setCountryCode(country);
                  formik.setFieldValue('phoneNumber', e);
                }}
                parentClassName="w-full sm:mb-[0px] mb-[20px]"
                labelFontColor={'#333333'}
                countryCode={countryCode}
              />
            </div>
            <div className="w-full">
              <InputBox
                name="taglineDescription"
                type="text"
                label={'Career title/ Tagline'}
                placeholder={'Career title'}
                initialValue={formik?.values?.taglineDescription}
                onChange={formik.handleChange}
                labelFontColor={'#333333'}
                helperText={formik.touched.taglineDescription && formik.errors.taglineDescription}
                className="w-full"
              />
            </div>
          </div>
          <div className="flex sm:flex-row flex-col justify-between w-full gap-x-[1vw]">
            <div className="w-full">
              <InputBox
                name="websiteLink"
                type="text"
                label={'Website Link'}
                placeholder={'Website Link'}
                initialValue={formik?.values?.websiteLink}
                onChange={formik.handleChange}
                labelFontColor={'#333333'}
                helperText={formik.touched.websiteLink && formik.errors.websiteLink}
                className="w-full"
              />
            </div>
            <div className="w-full">
              <InputBox
                name="socialMediaLink"
                type="text"
                label={'Social Media Link'}
                placeholder={'Social Media Link'}
                initialValue={formik?.values?.socialMediaLink}
                onChange={formik.handleChange}
                labelFontColor={'#333333'}
                helperText={formik.touched.socialMediaLink && formik.errors.socialMediaLink}
                className="w-full"
              />
            </div>
          </div>
          <div className="w-full">
            <div className="mb-[18px]">
              <div className="text-[#333] text-[12px] font-semibold mb-[5px]">Bio</div>
              <RadioButtonsGroup
                options={radioButtonOptions}
                defaultValue={selectedRadioValue}
                onSelect={(fieldName, value) => {
                  handleRadioChange(value);
                }}
                className="sm:flex-row flex-col"
              />
            </div>
            <div>
              <TextArea
                height="h-[110px]"
                placeholder="Enter Bio"
                value={formik?.values?.bioDescription}
                onChange={(e) => {
                  handleRadioChange('custom');
                  formik.setFieldValue('bioDescription', e.target.value);
                }}
                name="bioDescription"
              />
            </div>
          </div>
          {/* <hr className="pb-8" style={{ color: 'rgba(161, 160, 160, 0.50)' }} /> */}
          <div className="w-full flex justify-end ">
            <Button
              type="submit"
              label="Save"
              additionalClassNames="capitalize"
              isLoading={isLoading}
              showArrowIcon={false}
              onlyShowLoaderWhenLoading
              isDisabled={
                isLoading ||
                JSON.stringify(formik?.values) ===
                  JSON.stringify({ firstName, lastName, email, location, profilePicture })
              }
            />
          </div>
        </div>
      </form>
      <Modal
        isOpen={isVerifyEmailPopupOpen}
        onClose={() => {
          setIsEmailVerified(0);
          setIsVerifyEmailPopupOpen(false);
        }}
        isTitle={true}
        title={'Verify Email'}
        titleClassNames=""
        padding="p-0"
      >
        <UpdateEmail
          newEmail={formik?.values?.email}
          verificationStep={isEmailVerified}
          updateVerificationStep={setIsEmailVerified}
          currentEmail={email}
          closeHandler={() => {
            setIsVerifyEmailPopupOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

export default EditProfile;
