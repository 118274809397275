import React, { useState, useEffect } from 'react';

function ProgressBar({ className = '' }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1; // Increment progress
        if (newProgress > 100) {
          return 0; // Reset to 0 after reaching 100%
        }
        return newProgress;
      });
    }, 10); // Update progress every 10 milliseconds

    // Clear interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div className={`w-full bg-gray rounded-full h-2 ${className}`}>
      <div
        className="bg-blueprimary h-2 rounded-full"
        style={{ width: `${progress}%`, transition: 'width 0.5s ease-in-out' }}
      />
    </div>
  );
}

export default ProgressBar;
