import { PATHS } from './urlPaths';

export const REGEX = {
  EMAIL_PATTERN: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w{2,4}([-.]\w+)*$/,
  PASSWORD_PATTERN: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{4,16}$/,
  INTEGER_PATTERN: /^-?\d+(\.\d+)?$/,
  STRING_PATTERN: /^(\S+$)/g,
  POST_PATTERN: /^(?!\s*$).+/,
  LINK_PATTERN: /^https:\/\/[a-zA-Z0-9\-\\.]+\.[a-zA-Z]{2,}(\/\S*)?$/,
  SKILL_PATTERN: /^(?!\s*$).{1,100}$/,
};

export const VERIFY_EMAIL_ORIGIN = {
  FORGOT_PWD: '1fp',
};

export const LIMITS = {
  POST_CAPTION_MAX_LIMIT: 500,
  POST_MAX_IMAGE_SIZE_IN_BYTES: 10485760,
  POST_MAX_VIDEO_SIZE_IN_BYTES: 52428800,
  POST_MAX_ALLOWED_MEDIA: 10,
  POST_READ_MORE_LIMIT: 200,
  COMMENT_MAX_LIMIT: 500,
  MAX_ABOUT_WORK_LENGTH: 6000,
  MAX_CAREER_TITLE_LENGTH: 100,
  MAX_EXPERIENCE_DESCRIPTION_LENGTH: 500,
  MAX_EDUCATION_STRINGS_LENGTH: 100,
  MAX_CAREER_STRINGS_LENGTH: 100,
  POST_MAX_CERTIFICATION_SIZE_IN_BYTES: 10485760,
  MAX_CAREER_SKILL_LENGTH: 100,
  MAX_ABOUT_INTEREST_LENGTH: 6000,
  MAX_TITLE_LENGTH: 100,
  INTEREST_MAX_NUMBER_OF_IMAGES: 15,
  INTEREST_MAX_NUMBER_OF_VIDEOS: 15,
  INTEREST_MAX_IMAGE_SIZE_BYTES: 10485760,
  INTEREST_MAX_VIDEO_SIZE_IN_BYTES: 52428800,
  CAREER_MAX_NUMBER_OF_MEDIA: 15,
  CAREER_MAX_IMAGE_SIZE_BYTES: 10485760,
  CAREER_MAX_VIDEO_SIZE_IN_BYTES: 52428800,
  LOCATION_STRING: 50,
  MAX_ABOUT_MYSELF_LENGTH: 6000,
  MAX_LENGTH_SOFTSKILLS: 50,
  FETCH_UNREAD_NOTIFS_LIMIT: 1,
};

export const POST_IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/png', 'image/jpg'];

export const POST_VIDEO_TYPES = [
  'video/mp4',
  'video/mpeg',
  'video/webm',
  'video/x-matroska',
  'video/quicktime',
];

export const POST_DOCUMENT_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain',
];

export const POST_IMAGE_EXTENSIONS = ['png', 'jpeg', 'png', 'jpg'];
export const POST_VIDEO_EXTENSIONS = ['mp4', 'mpeg', 'webm', 'x-matroska', 'quicktime'];

export const INTEREST_PHOTO_TYPES = [
  'image/png',
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/heic',
  'image/heif',
];
export const INTEREST_PHOTO_EXTENSIONS = ['jpg', 'jpeg', 'png', 'heic', 'heif'];
export const INTEREST_VIDEO_TYPES = ['video/mp4', 'video/mov', 'video/hvec', 'video/flv'];

export const CAREER_MEDIA_TYPES = [
  'image/png',
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/heic',
  'image/heif',
  'video/mp4',
  'video/mov',
  'video/hvec',
  'video/flv',
];

export const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'heic', 'heif'];

export const PAGE_SIZE = {
  FEED: 20,
  COMMENT: 5,
  FOLLOW_REQUESTS: 10,
  PRIVACY_SETTING_SELECT_USERS: 20,
  BLOCKED_USERS_LIST: 10,
};

export const DATE_FORMAT = {
  POST: 'DD MMMM YYYY',
  CAPTION: 'DD MMM YY',
};

export const FIREBASE_CONSTANT = {
  KEY: 'BNEr8fsavW_uQMUS_NnllNCekCYO1_MybA1Cizb5noGiko09Rj96yrbVayebnZ2EEEf3FkF8rIU7p9fug9XSJ-0',
};

export const RESPONSE_FOR_NETWORK = {
  Following: {
    type: 'Followings',
    innerType: 'FollowingUser',
  },
  Followers: {
    type: 'Followers',
    innerType: 'User',
  },
  Connections: {
    type: 'Connections',
    innerType: 'User',
  },
};

export const NOTIFICATION_TYPES = {
  LIKE: 'like',
  COMMENT: 'comment',
  FOLLOW: 'follow',
  CUSTOM: 'custom',
  USER_WARNING: 'userWarning',
  POST_WARNING: 'postWarning',
  COMMENT_WARNING: 'commentWarning',
};

export const MYERS_BRIGGS = [
  {
    title: 'ISTJ: The Inspector',
    description:
      'Quiet, serious, earn success by being thorough and dependable. Practical, matter-of-fact, realistic, and responsible. Decide logically what should be done and work toward it steadily, regardless of distractions. Take pleasure in making everything orderly and organized—their work, their home, their life. Value traditions and loyalty.',
    value: 'inspector',
    name: 'inspector',
  },
  {
    title: 'SFJ: The Protector',
    description:
      'Quiet, friendly, responsible, and conscientious. Committed and steady in meeting their obligations. Thorough, painstaking, and accurate. Loyal, considerate, notice and remember specifics about people who are important to them, concerned with how others feel. Strive to create an orderly and harmonious environment at work and at home.',
    value: 'protector',
    name: 'protector',
  },
  {
    title: 'INFJ: The Counsellor',
    description:
      'Seek meaning and connection in ideas, relationships, and material possessions. Want to understand what motivates people and are insightful about others. Conscientious and committed to their firm values. Develop a clear vision about how best to serve the common good. Organized and decisive in implementing their vision.',
    value: 'counsellor',
    name: 'counsellor',
  },
  {
    title: 'INTJ: The Mastermind',
    description:
      'Have original minds and great drive for implementing their ideas and achieving their goals. Quickly see patterns in external events and develop long-range explanatory perspectives. When committed, organize a job and carry it through. Skeptical and independent, have high standards of competence and performance—for themselves and others.',
    value: 'mastermind',
    name: 'mastermind',
  },
  {
    title: 'ISTP: The Craftsman',
    description:
      'Tolerant and flexible, quiet observers until a problem appears, then act quickly to find workable solutions. Analyze what makes things work and readily get through large amounts of data to isolate the core of practical problems. Interested in cause and effect, organize facts using logical principles, value efficiency.',
    value: 'craftsman',
    name: 'craftsman',
  },
  {
    title: 'ISFP: The Composer',
    description:
      // eslint-disable-next-line quotes
      "Quiet, friendly, sensitive, and kind. Enjoy the present moment, what's going on around them. Like to have their own space and to work within their own time frame. Loyal and committed to their values and to people who are important to them. Dislike disagreements and conflicts; don't force their opinions or values on others.",
    value: 'composer',
    name: 'composer',
  },
  {
    title: 'INFP: The Healer',
    description:
      'Idealistic, loyal to their values and to people who are important to them. Want to live a life that is congruent with their values. Curious, quick to see possibilities, can be catalysts for implementing ideas. Seek to understand people and to help them fulfill their potential. Adaptable, flexible, and accepting unless a value is threatened.',
    value: 'healer',
    name: 'healer',
  },
  {
    title: 'INTP: The Architect',
    description:
      'Seek to develop logical explanations for everything that interests them. Theoretical and abstract, interested more in ideas than in social interaction. Quiet, contained, flexible, and adaptable. Have unusual ability to focus in depth to solve problems in their area of interest. Skeptical, sometimes critical, always analytical.',
    value: 'architect',
    name: 'architect',
  },
  {
    title: 'ESTP: The Dynamo',
    description:
      'Flexible and tolerant, take a pragmatic approach focused on immediate results. Bored by theories and conceptual explanations; want to act energetically to solve the problem. Focus on the here and now, spontaneous, enjoy each moment they can be active with others. Enjoy material comforts and style. Learn best through doing.',
    value: 'dynamo',
    name: 'dynamo',
  },
  {
    title: 'ESFP: The Performer',
    description:
      'Outgoing, friendly, and accepting. Exuberant lovers of life, people, and material comforts. Enjoy working with others to make things happen. Bring common sense and a realistic approach to their work and make work fun. Flexible and spontaneous, adapt readily to new people and environments. Learn best by trying a new skill with other people.',
    value: 'performer',
    name: 'performer',
  },
  {
    title: 'ENFP: The Champion',
    description:
      'Warmly enthusiastic and imaginative. See life as full of possibilities. Make connections between events and information very quickly, and confidently proceed based on the patterns they see. Want a lot of affirmation from others, and readily give appreciation and support. Spontaneous and flexible, often rely on their ability to improvise and their verbal fluency.',
    value: 'champion',
    name: 'champion',
  },
  {
    title: 'ENTP: The Visionary',
    description:
      'Quick, ingenious, stimulating, alert, and outspoken. Resourceful in solving new and challenging problems. Adept at generating conceptual possibilities and then analyzing them strategically. Good at reading other people. Bored by routine, will seldom do the same thing the same way, apt to turn to one new interest after another.',
    value: 'visionary',
    name: 'visionary',
  },
  {
    title: 'ESTJ: The Supervisor',
    description:
      'Practical, realistic, matter-of-fact. Decisive, quickly move to implement decisions. Organize projects and people to get things done, focus on getting results in the most efficient way possible. Take care of routine details. Have a clear set of logical standards, systematically follow them and want others to also. Forceful in implementing their plans.',
    value: 'supervisor',
    name: 'supervisor',
  },
  {
    title: 'ESFJ: The Provider',
    description:
      'Warmhearted, conscientious, and cooperative. Want harmony in their environment, work with determination to establish it. Like to work with others to complete tasks accurately and on time. Loyal, follow through even in small matters. Notice what others need in their day-to-day lives and try to provide it. Want to be appreciated for who they are and for what they contribute.',
    value: 'provider',
    name: 'provider',
  },
  {
    title: 'ENFJ: The Teacher',
    description:
      'Warm, empathetic, responsive, and responsible. Highly attuned to the emotions, needs, and motivations of others. Find potential in everyone, want to help others fulfill their potential. May act as catalysts for individual and group growth. Loyal, responsive to praise and criticism. Sociable, facilitate others in a group, and provide inspiring leadership.',
    value: 'teacher',
    name: 'teacher',
  },
  {
    title: 'NTJ: The Commander',
    description:
      'Frank, decisive, assume leadership readily. Quickly see illogical and inefficient procedures and policies, develop and implement comprehensive systems to solve organizational problems. Enjoy long-term planning and goal setting. Usually well informed, well read, enjoy expanding their knowledge and passing it on to others. Forceful in presenting their ideas.',
    value: 'commander',
    name: 'commander',
  },
];

export const HOLLAND_CODE = [
  {
    title: 'Realistic (R)',
    description:
      'These are people who like well-ordered activities, or enjoy working with objects, tools, and machines',
    heading1: 'Realistic people:',
    listArray: [
      '- See themselves as mechanically or athletically talented, but may not be good with people.',
      '- Value concrete and tangible things like money, power and status.',
      '- Avoid "social" activities, those that need interaction with other people',
    ],
    heading2: 'Common traits:',
    subHeading2: '- Hard-headed, inflexible, persistent, materialistic, practical, and genuine',
    value: 'realistic',
    name: 'realistic',
  },
  {
    title: 'Investigative (I)',
    description:
      'Investigative people like activities that involve creative investigation of the world or nature.',
    heading1: 'Investigative people:',
    listArray: [
      '- See themselves as highly intelligent, but often lack leadership skills.',
      // eslint-disable-next-line quotes
      "- Value scientific endeavour's.",
      '- Avoid activities that seem mundane, commercial or "enterprising"',
    ],
    heading2: 'Common traits:',
    subHeading2: '- Analytical, curious, pessimistic, intellectual, precise, and reserved',

    value: 'investigative',
    name: 'investigative',
  },
  {
    title: 'Artistic (A)',
    description:
      'Artistic people like unstructured activities, and enjoy using materials to create art.',
    heading1: 'Artistic people:',
    listArray: [
      '- See themselves as talented artists.',
      '- Value aesthetic’s.',
      '- Avoid "conventional" occupations, or situations',
    ],
    heading2: 'Common traits:',
    subHeading2:
      '- Idealistic, complicated , introspective, sensitive, impractical and nonconformist',
    value: 'artistic',
    name: 'artistic',
  },
  {
    title: 'Social (S)',
    description:
      'Social people enjoy informing, training, developing, curing and enlightening others.',
    heading1: 'Social people:',
    listArray: [
      '- Perceive themselves as helpful, understanding and able to teach others.',
      '- Value social activities.',
      '- Avoid activities demanded by "realistic" occupations and situations',
    ],
    heading2: 'Common traits:',
    subHeading2: '- Generous, patient, empathic, tactful, persuasive, and cooperative',
    value: 'social',
    name: 'social',
  },
  {
    title: 'Enterprising (E)',
    description: 'These people enjoy reaching organizational goals or achieving economic gain.',
    heading1: 'Enterprising people:',
    listArray: [
      '- See themselves as aggressive, popular, great leaders and speakers, but may lack scientific ability.',
      '- Value political and economic achievement.',
      '- Avoid activities demanded by "investigative" occupations and situations',
    ],
    heading2: 'Common traits:',
    subHeading2: '- Extroverted, adventurous, optimistic, ambitious, sociable, and exhibitionistic',
    value: 'enterprising',
    name: 'enterprising',
  },
  {
    title: 'Conventional (C)',
    description:
      'Conventional people enjoy manipulating data, record keeping, filing, reproducing materials, and organizing written or numerical data.',
    heading1: 'Conventional people:',
    listArray: [
      '- See themselves as having a clerical and numerical ability.',
      '- Value business and economic achievement.',
      '- Avoid unstructured or "artistic" activities',
    ],
    heading2: 'Common traits:',
    subHeading2: '- Efficient, practical, conscientious, inflexible, defensive, and methodical',
    value: 'conventional',
    name: 'conventional',
  },
];

export const DISC = [
  {
    title: '“D”',
    description:
      'Measures a direct, dominant disposition using adjectives such as driven, strong-willed and forceful.',
    value: 'D',
    name: 'D',
  },
  {
    title: 'Di or iD',
    description:
      'Measures an active, fast-paced disposition using adjectives such as dynamic, adventurous and bold.',
    value: 'Di or iD',
    name: 'Di or iD',
  },
  {
    title: '“i”',
    description:
      'Measures an interactive, influencing disposition using adjectives such as sociable, lively and talkative.',
    value: 'i',
    name: 'i',
  },
  {
    title: 'iS or Si',
    description:
      'Measures an agreeable, warm disposition using adjectives such as trusting, cheerful and caring.',
    value: 'iS or Si',
    name: 'iS or Si',
  },
  {
    title: '“S”',
    description:
      'Measures an accommodating, steady disposition using adjectives such as considerate, patient and soft-hearted.',
    value: 'S',
    name: 'S',
  },
  {
    title: 'SC or CS',
    description:
      'Measures a moderate-paced, cautious disposition using adjectives such as careful, soft-spoken and self-controlled.',
    value: 'SC or CS',
    name: 'SC or CS',
  },
  {
    title: '“C”',
    description:
      'Measures a private, conscientious disposition using adjectives such as analytical, reserved and unemotional.',
    value: 'C',
    name: 'C',
  },
  {
    title: 'CD or DC',
    description:
      'Measures a questioning, skeptical disposition using adjectives such as cautious, disciplined and critical.',
    value: 'CD or DC',
    name: 'CD or DC',
  },
];

export const ENNEAGRAM = [
  {
    title: 'Type “One”',
    description: 'Principled, purposeful, self-controlled, and perfectionistic.',
    value: 'typeOne',
    name: 'typeOne',
  },
  {
    title: 'Type “Two”',
    description: 'Generous, demonstrative, people-pleasing, and possessive.',
    value: 'typeTwo',
    name: 'typeTwo',
  },
  {
    title: 'Type “Three”',
    description: 'Adaptable, excelling, driven, and image-conscious.',
    value: 'typeThree',
    name: 'typeThree',
  },
  {
    title: 'Type “Four”',
    description: 'Expressive, dramatic, self-absorbed, and temperamental.',
    value: 'typeFour',
    name: 'typeFour',
  },
  {
    title: 'Type “Five”',
    description: 'Perceptive, innovative, secretive, and isolated.',
    value: 'typeFive',
    name: 'typeFive',
  },
  {
    title: 'Type “Six”',
    description: 'Engaging, responsible, anxious, and suspicious.',
    value: 'typeSix',
    name: 'typeSix',
  },
  {
    title: 'Type “Seven”',
    description: 'Spontaneous, versatile, acquisitive, and scattered.',
    value: 'typeSeven',
    name: 'typeSeven',
  },
  {
    title: 'Type “Eight”',
    description: 'Self-confident, decisive, willful, and confrontational.',
    value: 'typeEight',
    name: 'typeEight',
  },
  {
    title: 'Type “Nine”',
    description: 'Receptive, reassuring, complacent, and resigned.',
    value: 'typeNine',
    name: 'typeNine',
  },
];

export const ASSESMENTS_LIST = [
  {
    title: 'The Big Five',
    navigate: PATHS.ASSESSMENTS_BIG_FIVE,
  },
  {
    title: 'The Attributes',
    navigate: PATHS.ASSESSMENTS_ATTRIBUTES,
  },
  {
    title: 'Myers-Briggs',
    navigate: PATHS.ASSESSMENTS_MYERS_BRIGGS,
  },
  {
    title: 'DISC',
    navigate: PATHS.ASSESSMENTS_DISC,
  },
  {
    title: 'Enneagram',
    navigate: PATHS.ASSESSMENTS_ENNEAGRAM,
  },
  {
    title: 'Holland Code',
    navigate: PATHS.ASSESSMENTS_HOLLAND_CODE,
  },
];
