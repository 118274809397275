import MessagesInactiveImage from '../../assets/images/message-inactive-navbar.svg';
import MessagesInactiveMobileImage from '../../assets/images/message-inactive-mobile-navbar.svg';
import MessagesActiveImage from '../../assets/images/message-active-navbar.svg';
import MessageNew from '../../assets/images/messageNew.svg';
import MessageMobileNew from '../../assets/images/messageMobileNew.svg';

const MessagesIcon = ({ isActive = false, isMobile = false, pending }) => {
  if (isActive) {
    return <img src={MessagesActiveImage} />;
  } else if (isMobile) {
    return <img src={pending ? MessageMobileNew : MessagesInactiveMobileImage} />;
  }
  return <img src={pending ? MessageNew : MessagesInactiveImage} />;
};
export default MessagesIcon;
