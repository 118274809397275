import AssesmentsLayout from '../MyselfPage/AssesmentLayout';
import Assesments from '../MyselfPage/Assesments';
import { getMySelfDetails } from '../../services/myself';
import { useEffect, useState } from 'react';

const ProfileAssesments = () => {
  const [userData, setUserData] = useState({});

  const getUserData = async () => {
    const response = await getMySelfDetails();
    const { data = '', status = '' } = response;
    if (status) {
      const { data: fetchedUserData = {} } = data;
      setUserData(fetchedUserData);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <AssesmentsLayout title="Assessments" showBackgroundImage={true}>
      <Assesments
        discAssessment={userData?.discAssessment}
        enneagram={userData?.enneagram}
        hollandCode={userData?.hollandCode}
        myersBriggsAssessment={userData?.myersBriggsAssessment}
        attributeAssessment={userData?.attributeAssessment}
        bigFiveAssessment={userData?.bigFiveAssessment}
        hideHeading={true}
      />
    </AssesmentsLayout>
  );
};

export default ProfileAssesments;
