import React from 'react';
import { PRIVATE_NAVIGATION_LABELS } from '../../constants/lang';
import navigationItems from '../../constants/navigationItems';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const { NAVLBL_NOTIFICATIONS, NAVLBL_MESSAGES, NAVLBL_SETTINGS } = PRIVATE_NAVIGATION_LABELS;

const navbarItems = navigationItems;

const MobileBottomNavigation = ({ activeTab = 0 }) => {
  const { hasNotifications = false, unreadCount = 0 } = useSelector((state) => state.auth) || {};

  return (
    <div className="fixed bottom-0 left-0 w-full z-49  md:hidden h-[60px] bg-darkblue flex py-4 gap-4 lg:gap-0 md:items-center">
      {navbarItems.map(({ label, action, icon, navPath }, _i) => {
        return (
          <NavBarItem
            label={label}
            onClickHandler={action}
            key={label}
            isActive={_i === activeTab}
            Icon={icon}
            navPath={navPath}
            hasNotifications={hasNotifications}
            unreadCount={unreadCount}
          />
        );
      })}
    </div>
  );
};

export default MobileBottomNavigation;

const NavBarItem = ({
  onClickHandler = () => {},
  label = '',
  isActive = false,
  navPath,
  Icon = () => <></>,
  hasNotifications = false,
  unreadCount = 0,
}) => (
  <NavLink
    reloadDocument={[NAVLBL_SETTINGS, NAVLBL_MESSAGES]?.includes(label)}
    replace={true}
    to={navPath}
    className="w-[-webkit-fill-available] w-full"
  >
    <div
      className={`
      w-full p-1 lg:p-4 pr-[5px] md:pr-[8px] box-border cursor-pointer flex items-center justify-center lg:justify-normal gap-4`}
      onClick={onClickHandler}
    >
      <div className="relative">
        <Icon isActive={isActive} isMobile={true} />
        {label === NAVLBL_NOTIFICATIONS && hasNotifications ? (
          <div className=" !top-[-18px] !right-[-25px] dot-icon !block" />
        ) : (
          ''
        )}
        {label === NAVLBL_MESSAGES && unreadCount > 0 ? (
          <div className=" !top-[-30px] !right-[-35px] dot-icon" />
        ) : (
          ''
        )}
      </div>

      <p className="text-sm font-semibold hidden lg:block">{label}</p>
    </div>
  </NavLink>
);
