export const CameraIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1437254088">
        <circle id="Oval" cx="11.5" cy="11.5" r="11" fill="white" stroke="white" />
        <g id="001-photo-camera-interface-symbol-for-button">
          <path
            id="Shape"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6573 9.83668V14.9408C17.6573 15.6213 17.0951 16.173 16.4016 16.173H7.55655C6.86305 16.173 6.30078 15.6213 6.30078 14.9408V9.83668C6.30078 9.15623 6.86305 8.60454 7.55655 8.60454H9.10115V8.17822C9.10115 7.58279 9.59278 7.1001 10.1999 7.1001H13.7582C14.3653 7.1001 14.857 7.58279 14.857 8.17822V8.60423H16.4016C17.0951 8.60454 17.6573 9.15623 17.6573 9.83668ZM13.8123 12.3927C13.8123 13.3836 12.9907 14.1897 11.9807 14.1897C10.9708 14.1897 10.1495 13.3836 10.1495 12.3927C10.1495 11.4017 10.9708 10.5956 11.9807 10.5956C12.9907 10.5956 13.8123 11.402 13.8123 12.3927ZM11.9768 9.66912C13.506 9.66912 14.7501 10.8899 14.7501 12.3903C14.7501 13.8907 13.506 15.1115 11.9768 15.1115C10.4479 15.1115 9.20372 13.8907 9.20372 12.3903C9.20372 10.8899 10.4479 9.66912 11.9768 9.66912Z"
            fill="#0071BC"
          />
        </g>
      </g>
    </svg>
  );
};
