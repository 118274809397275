import React from 'react';
import backIcon from '../../assets/images/backIcon.svg';

const InnerSectionLayout = ({
  heading,
  isSubSection = false,
  children,
  onClickSubSectionHandler = () => {},
  filters,
  setFilter,
}) => {
  return (
    <div className={`grid grid-cols-12 gap-5 feed-page ${filters ? 'h-full' : ''} `}>
      <div className="col-span-12">
        <div
          className={`bg-white md:rounded-lg shadow-card lg:mt-4  h-auto min-h-[calc(100vh-120px)] md:min-h-[calc(100vh-120px)] flex flex-col p-4 ${
            filters ? 'h-full md:mt-4 md:h-[200px]' : ''
          }`}
        >
          <div
            className={`flex items-center gap-2 mb-4 ${isSubSection ? 'cursor-pointer' : ''} ${
              filters ? 'flex justify-between' : ''
            }`}
          >
            <div className="flex" onClick={onClickSubSectionHandler}>
              {isSubSection && <img src={backIcon} alt="backIcon" className="w-[24px]" />}
              <div
                className={`md:text-[20px] text-[#333333] font-medium pb-0 ${
                  isSubSection ? 'text-[18px]' : 'font-semibold'
                }`}
              >
                {heading}
              </div>
            </div>
            {filters ? (
              <div
                className=" text-blueprimary text-[16px] font-semibold md:hidden"
                onClick={() => setFilter(true)}
              >
                Apply Filters
              </div>
            ) : (
              ''
            )}
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

export default InnerSectionLayout;
