import React, { useEffect, useState } from 'react';
import Avatar from '../../components/common/Avatar';
import ludgi from '../../assets/images/ludgi.svg';
import TestSeen from '../../components/Icons/TestSeen';
import DocumentIcon from '../../components/Icons/DocumentIcon';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PreviewMediaFile from '../../components/PreviewMediaFile';

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const hours = date.getHours() % 12 || 12;
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  return `${hours}:${minutes} ${ampm}`;
};
const formatDate = (date) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  date = new Date(date);

  if (date.toDateString() === today.toDateString()) {
    return 'Today';
  } else if (date.toDateString() === yesterday.toDateString()) {
    return 'Yesterday';
  } else {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const weekdayIndex = date.getDay();
    if (date.getTime() === today.getTime() + 7 * 24 * 60 * 60 * 1000) {
      return `${weekdays[weekdayIndex]}`;
    } else {
      return `${month} ${day}, ${year}`;
    }
  }
};
const MediaMsg = ({ element, contacts, isActive }) => {
  const myProfile = useSelector((state) => state.auth.user);
  const formattedTimestamp = formatTimestamp(element?.timestamp);
  const sender = myProfile?.id === element?.idFrom;
  const otherUser = (users) => {
    return users.find((element) => element?.id !== myProfile?.id);
  };
  const user = (users) => {
    return users.find((element) => element?.id === myProfile?.id);
  };
  const [openSelected, setOpenSelected] = useState(false);
  const [showFullMessage, setShowFullMessage] = useState();
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const messageContent = element?.message || '';
  const truncatedMessage = showFullMessage
    ? messageContent
    : messageContent.length > 300
      ? messageContent.slice(0, 300) + '...'
      : messageContent;

  const toggleShowFullMessage = () => {
    setShowFullMessage(!showFullMessage);
  };

  useEffect(() => {
    const img = new Image();
    img.src = element?.url;

    img.onload = () => {
      const maxHeight = 210;
      const aspectRatio = img.naturalWidth / img.naturalHeight;

      const newWidth = Math.round(maxHeight * aspectRatio);

      setImageDimensions({
        width: newWidth,
        height: maxHeight,
      });
    };
  }, [element?.url]);
  return (
    <div
      className={`flex items-center flex-row ml-2 mt-4 m-6 ${
        sender ? 'flex-row-reverse' : 'justify-start'
      }`}
    >
      <div>
        <div className={`flex items-end ${sender ? 'flex-row-reverse' : 'justify-start'}`}>
          <Avatar
            name={
              contacts.find((element) => {
                return (
                  element?.username === isActive ||
                  otherUser(element?.userDetails).username === isActive
                );
              })?.lastMessage
                ? sender
                  ? user(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    ).firstName
                  : otherUser(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    )?.firstName
                : sender
                  ? myProfile?.firstName
                  : contacts.find((element) => {
                      return (
                        element?.username === isActive ||
                        otherUser(element?.userDetails).username === isActive
                      );
                    })?.firstName
            }
            image={
              contacts.find((element) => {
                return (
                  element?.username === isActive ||
                  otherUser(element?.userDetails).username === isActive
                );
              })?.lastMessage
                ? sender
                  ? myProfile?.profilePictureUrl || myProfile?.profilePicture
                  : otherUser(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    )?.profilePictureUrl ||
                    otherUser(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    )?.profilePicture
                : sender
                  ? myProfile?.profilePictureUrl || myProfile?.profilePicture
                  : contacts.find((element) => {
                      return (
                        element?.username === isActive ||
                        otherUser(element?.userDetails).username === isActive
                      );
                    })?.profilePictureUrl ||
                    contacts.find((element) => {
                      return (
                        element?.username === isActive ||
                        otherUser(element?.userDetails).username === isActive
                      );
                    })?.profilePicture
            }
          />
          <div className="ml-2 mr-2">
            <div
              className={`max-w-[340px] p-[5px] break-all ${
                !sender
                  ? 'bg-whitemedium rounded-tl-2xl rounded-tr-2xl rounded-br-2xl'
                  : 'bg-blueprimary rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl text-white'
              }`}
            >
              <img
                src={element?.url}
                alt=""
                className={`max-h-[210px] max-w-[340px] cursor-pointer ${
                  !sender
                    ? 'bg-whitemedium rounded-tl-2xl rounded-tr-2xl rounded-br-2xl'
                    : 'bg-blueprimary rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl'
                }`}
                onClick={() => setOpenSelected(true)}
              />
              <div
                className="mt-[2px] ml-[10px] mr-[10px]"
                style={{ maxWidth: `${imageDimensions?.width - 20}px` }}
              >
                {truncatedMessage}
                {messageContent.length > 300 && (
                  <span
                    className={`${
                      !sender ? 'text-greydark' : 'text-white'
                    } cursor-pointer font-bold block`}
                    onClick={toggleShowFullMessage}
                  >
                    {showFullMessage ? 'View less' : 'View more'}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex items-center ${
            !sender ? 'justify-start ml-[40px] mt-1' : 'justify-end mr-[40px]'
          }`}
        >
          <div>{sender ? <TestSeen seen={element?.read} /> : ''}</div>
          <div className="text-[12px] text-greydark ml-1">{formattedTimestamp}</div>
        </div>
      </div>
      {openSelected && (
        <PreviewMediaFile
          type="image"
          isOpen={openSelected}
          fileSrc={element?.url}
          onCloseHandler={() => setOpenSelected(false)}
        />
      )}
    </div>
  );
};
const VideoMsg = ({ element, contacts, isActive }) => {
  const myProfile = useSelector((state) => state.auth.user);
  const formattedTimestamp = formatTimestamp(element?.timestamp);
  const sender = myProfile?.id === element?.idFrom;
  const otherUser = (users) => {
    return users.find((element) => element?.id !== myProfile?.id);
  };
  const user = (users) => {
    return users.find((element) => element?.id === myProfile?.id);
  };
  const [openSelected, setOpenSelected] = useState(false);
  const [showFullMessage, setShowFullMessage] = useState();
  const messageContent = element?.message || '';
  const truncatedMessage = showFullMessage
    ? messageContent
    : messageContent.length > 300
      ? messageContent.slice(0, 300) + '...'
      : messageContent;

  const toggleShowFullMessage = () => {
    setShowFullMessage(!showFullMessage);
  };
  return (
    <div
      className={`flex items-center flex-row ml-2 mt-4 m-6 ${
        sender ? 'flex-row-reverse' : 'justify-start'
      }`}
    >
      <div>
        <div className={`flex items-end ${sender ? 'flex-row-reverse' : 'justify-start'}`}>
          <Avatar
            name={
              contacts.find((element) => {
                return (
                  element?.username === isActive ||
                  otherUser(element?.userDetails).username === isActive
                );
              })?.lastMessage
                ? sender
                  ? user(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    ).firstName
                  : otherUser(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    )?.firstName
                : sender
                  ? myProfile?.firstName
                  : contacts.find((element) => {
                      return (
                        element?.username === isActive ||
                        otherUser(element?.userDetails).username === isActive
                      );
                    })?.firstName
            }
            image={
              contacts.find((element) => {
                return (
                  element?.username === isActive ||
                  otherUser(element?.userDetails).username === isActive
                );
              })?.lastMessage
                ? sender
                  ? myProfile?.profilePictureUrl || myProfile?.profilePicture
                  : otherUser(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    )?.profilePictureUrl ||
                    otherUser(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    )?.profilePicture
                : sender
                  ? myProfile?.profilePictureUrl || myProfile?.profilePicture
                  : contacts.find((element) => {
                      return (
                        element?.username === isActive ||
                        otherUser(element?.userDetails).username === isActive
                      );
                    })?.profilePictureUrl ||
                    contacts.find((element) => {
                      return (
                        element?.username === isActive ||
                        otherUser(element?.userDetails).username === isActive
                      );
                    })?.profilePicture
            }
          />
          <div className="ml-2 mr-2">
            <div
              className={`max-w-[340px] p-[5px] ${
                !sender
                  ? 'bg-whitemedium rounded-tl-2xl rounded-tr-2xl rounded-br-2xl'
                  : 'bg-blueprimary rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl text-white'
              }`}
            >
              <video
                src={element?.url}
                alt=""
                className={`max-h-[210px] cursor-pointer ${
                  sender
                    ? 'rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl'
                    : 'rounded-tl-2xl rounded-tr-2xl rounded-br-2xl'
                } `}
                controls
                onClick={(e) => {
                  e.preventDefault();
                  setOpenSelected(true);
                }}
              />
              <div className="mt-[2px] ml-[10px] mr-[10px] break-all">
                {truncatedMessage}
                {messageContent.length > 300 && (
                  <span
                    className={`${
                      !sender ? 'text-greydark' : 'text-white'
                    } cursor-pointer font-bold block`}
                    onClick={toggleShowFullMessage}
                  >
                    {showFullMessage ? 'View less' : 'View more'}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex items-center ${
            !sender ? 'justify-start ml-[40px] mt-1' : 'justify-end mr-[40px]'
          }`}
        >
          <div>{sender ? <TestSeen seen={element?.read} /> : ''}</div>
          <div className="text-[12px] text-greydark ml-1">{formattedTimestamp}</div>
        </div>
      </div>
      {openSelected && (
        <PreviewMediaFile
          type="video"
          isOpen={openSelected}
          fileSrc={element?.url}
          onCloseHandler={() => setOpenSelected(false)}
        />
      )}
    </div>
  );
};

const TextMsg = ({ element, contacts, isActive }) => {
  const myProfile = useSelector((state) => state.auth.user);
  const formattedTimestamp = formatTimestamp(element?.timestamp);
  const sender = myProfile?.id === element?.idFrom;
  const [showFullMessage, setShowFullMessage] = useState();
  const messageContent = element?.message || '';
  const truncatedMessage = showFullMessage
    ? messageContent
    : messageContent.length > 300
      ? messageContent.slice(0, 300) + '...'
      : messageContent;
  const toggleShowFullMessage = () => {
    setShowFullMessage(!showFullMessage);
  };
  const otherUser = (users) => {
    return users.find((element) => element?.id !== myProfile?.id);
  };
  const user = (users) => {
    return users.find((element) => element?.id === myProfile?.id);
  };
  return (
    <div
      className={`flex items-center flex-row ml-2 mt-4 m-6 ${
        sender ? 'flex-row-reverse' : 'justify-start'
      }`}
    >
      <div>
        <div className={`flex items-end ${sender ? 'flex-row-reverse' : 'justify-start'}`}>
          <Avatar
            name={
              contacts.find((element) => {
                return (
                  element?.username === isActive ||
                  otherUser(element?.userDetails).username === isActive
                );
              })?.lastMessage
                ? sender
                  ? user(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    ).firstName
                  : otherUser(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    )?.firstName
                : sender
                  ? myProfile?.firstName
                  : contacts.find((element) => {
                      return (
                        element?.username === isActive ||
                        otherUser(element?.userDetails).username === isActive
                      );
                    })?.firstName
            }
            image={
              contacts.find((element) => {
                return (
                  element?.username === isActive ||
                  otherUser(element?.userDetails).username === isActive
                );
              })?.lastMessage
                ? sender
                  ? myProfile?.profilePictureUrl || myProfile?.profilePicture
                  : otherUser(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    )?.profilePictureUrl ||
                    otherUser(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    )?.profilePicture
                : sender
                  ? myProfile?.profilePictureUrl || myProfile?.profilePicture
                  : contacts.find((element) => {
                      return (
                        element?.username === isActive ||
                        otherUser(element?.userDetails).username === isActive
                      );
                    })?.profilePictureUrl ||
                    contacts.find((element) => {
                      return (
                        element?.username === isActive ||
                        otherUser(element?.userDetails).username === isActive
                      );
                    })?.profilePicture
            }
          />
          <div className="ml-2 mr-2">
            <div
              className={`max-w-[340px] p-4 ${
                !sender
                  ? 'bg-whitemedium rounded-tl-2xl rounded-tr-2xl rounded-br-2xl'
                  : 'bg-blueprimary rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl'
              }`}
              style={{ whiteSpace: 'pre-line' }}
            >
              <div className={`text-[14px] ${!sender ? 'text-greydark' : 'text-white'} break-all`}>
                {truncatedMessage}
                {messageContent.length > 300 && (
                  <span
                    className={`${
                      !sender ? 'text-greydark' : 'text-white'
                    } cursor-pointer font-bold block`}
                    onClick={toggleShowFullMessage}
                  >
                    {showFullMessage ? 'View less' : 'View more'}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex items-center ${
            !sender ? 'justify-start ml-[40px] mt-1' : 'justify-end mr-[40px]'
          }`}
        >
          <div>{sender ? <TestSeen seen={element?.read} /> : ''}</div>
          <div className="text-[12px] text-greydark ml-1">{formattedTimestamp}</div>
        </div>
      </div>
    </div>
  );
};

const Timestamp = ({ element }) => {
  const day = formatDate(element);
  return (
    <div className="flex justify-center">
      <div className=" bg-greymedium h-[24px] rounded-[20px] flex items-center justify-center">
        <div className="text-greydark text-[12px] font-normal p-[20px]">{day}</div>
      </div>
    </div>
  );
};
const Divider = () => {
  return (
    <div className="flex justify-center">
      <div className=" bg-whitelight w-full h-[34px] flex items-center justify-center">
        <div className="text-greydark text-[12px] font-medium">Unread Messages</div>
      </div>
    </div>
  );
};
const Document = ({ element, contacts, isActive }) => {
  const myProfile = useSelector((state) => state.auth.user);
  const formattedTimestamp = formatTimestamp(element?.timestamp);
  const sender = myProfile?.id === element?.idFrom;
  const otherUser = (users) => {
    return users.find((element) => element?.id !== myProfile?.id);
  };
  const user = (users) => {
    return users.find((element) => element?.id === myProfile?.id);
  };
  const handleDownload = (element) => {
    const link = document.createElement('a');
    link.href = element?.url;
    link.target = '_blank';
    link.download = 'Document_File';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div
      className={`flex items-center flex-row ml-2 mt-4 m-6 ${
        sender ? 'flex-row-reverse' : 'justify-start'
      }`}
    >
      <div>
        <div className={`flex items-end ${sender ? 'flex-row-reverse' : 'justify-start'}`}>
          <Avatar
            name={
              contacts.find((element) => {
                return (
                  element?.username === isActive ||
                  otherUser(element?.userDetails).username === isActive
                );
              })?.lastMessage
                ? sender
                  ? user(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    ).firstName
                  : otherUser(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    )?.firstName
                : sender
                  ? myProfile?.firstName
                  : contacts.find((element) => {
                      return (
                        element?.username === isActive ||
                        otherUser(element?.userDetails).username === isActive
                      );
                    })?.firstName
            }
            image={
              contacts.find((element) => {
                return (
                  element?.username === isActive ||
                  otherUser(element?.userDetails).username === isActive
                );
              })?.lastMessage
                ? sender
                  ? myProfile?.profilePictureUrl || myProfile?.profilePicture
                  : otherUser(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    )?.profilePictureUrl ||
                    otherUser(
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails).username === isActive
                        );
                      })?.userDetails,
                    )?.profilePicture
                : sender
                  ? myProfile?.profilePictureUrl || myProfile?.profilePicture
                  : contacts.find((element) => {
                      return (
                        element?.username === isActive ||
                        otherUser(element?.userDetails).username === isActive
                      );
                    })?.profilePictureUrl ||
                    contacts.find((element) => {
                      return (
                        element?.username === isActive ||
                        otherUser(element?.userDetails).username === isActive
                      );
                    })?.profilePicture
            }
          />
          <div className="ml-2 mr-2">
            <div
              className={`max-w-[340px] p-4 ${
                !sender
                  ? 'bg-whitemedium rounded-tl-2xl rounded-tr-2xl rounded-br-2xl'
                  : 'bg-blueprimary rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl'
              }`}
            >
              <div className={'text-[14px] text-blueprimary'}>
                <div
                  className="flex items-center cursor-pointer bg-lightbluebg p-4 rounded-lg"
                  onClick={() => handleDownload(element)}
                >
                  <div className="flex items-center">
                    <DocumentIcon fill="#0071BC" className="h-[20px] w-[20px]" />
                    <div className="text-[14px] ml-1">Document File</div>
                  </div>
                  <div className="ml-6">
                    <div className="text-[10px]">20 KB · PDF</div>
                  </div>
                </div>
                {element?.message ? (
                  <div className={`mt-2 text-[14px] ${!sender ? 'text-greydark' : 'text-white'}`}>
                    {element?.message}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex items-center ${
            !sender === true ? 'justify-start ml-[40px] mt-1' : 'justify-end mr-[40px]'
          }`}
        >
          <div>{sender ? <TestSeen seen={element?.read} /> : ''}</div>
          <div className="text-[12px] text-greydark ml-1">{formattedTimestamp}</div>
        </div>
      </div>
    </div>
  );
};
const SharedLink = ({ element }) => {
  return (
    <div
      className={`flex items-center flex-row ml-2 mt-4 m-6 ${
        !element?.incoming ? 'flex-row-reverse' : 'justify-start'
      }`}
    >
      <div>
        <div
          className={`flex items-end ${!element?.incoming ? 'flex-row-reverse' : 'justify-start'}`}
        >
          <Avatar image={ludgi} />
          <div className="ml-2 mr-2">
            <div
              className={`max-w-[340px] p-4 ${
                element?.incoming === true
                  ? 'bg-whitemedium rounded-tl-2xl rounded-tr-2xl rounded-br-2xl'
                  : 'bg-blueprimary rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl text-white'
              }`}
            >
              <div>
                <Link className="underline" target="_blank" to={element?.link}>
                  {element?.link}
                </Link>
                <div>{element?.message ? element?.message : ''}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex items-center ${
            element?.incoming === true ? 'justify-start ml-[40px] mt-1' : 'justify-end mr-[40px]'
          }`}
        >
          <div>{!element?.incoming ? <TestSeen seen /> : ''}</div>
          <div className="text-[12px] text-greydark ml-1">{element?.time}</div>
        </div>
      </div>
    </div>
  );
};

export { Timestamp, TextMsg, MediaMsg, SharedLink, Divider, Document, VideoMsg };
