import React, { useEffect, useState } from 'react';
import AssesmentsLayout from '../../../components/MyselfPage/AssesmentLayout';
import SelectBox from '../../../components/common/SelectBox';
import { Button } from '../../../components/common/Button';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { addAssesmentBigFive, getAssesmentBigFive } from '../../../services/myself';
import { getErrorMessage, successStatus } from '../../../common';
import { ToastNotifyError, ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import { useSelector } from 'react-redux';

const initialData = {
  openness: null,
  intellect: null,
  volatility: null,
  aesthetics: null,
  enthusiasm: null,
  politeness: null,
  neuroticism: null,
  compassion: null,
  orderliness: null,
  extraversion: null,
  withdrawal: null,
  assertiveness: null,
  agreeableness: null,
  industriousness: null,
  conscientiousness: null,
};

const BigFive = () => {
  const { isOriginSignup = false } = useSelector((state) => state.auth || {});
  const [optionsList, setOptionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const navigate = useNavigate();

  function generateNumbersArray() {
    const numbersArray = [];
    for (let i = 0; i <= 100; i++) {
      numbersArray.push(`${i}%`);
    }
    setOptionsList(numbersArray);
  }

  const getBigFive = async () => {
    const response = await getAssesmentBigFive();
    const { data: { data = '' } = {}, status = '' } = response;
    if (status && data) {
      const { id, UserId, createdAt, updatedAt, ...rest } = data;
      formik.setValues(rest);
    }
  };

  const submitHandler = async (values, notifyOthers = false) => {
    if (!isLoading) {
      setIsLoading(true);
      const response = await addAssesmentBigFive({ ...values, notifyOthers });
      const { status = '', data = '' } = response;
      const errormsg = getErrorMessage(data);
      if (successStatus(status)) {
        ToastNotifySuccess('Big Five saved successfully', 'location-success');
      } else {
        if (errormsg) {
          ToastNotifyError(errormsg, 'location-failed');
        }
      }
      setIsCreateModalOpen(false);
      setIsLoading(false);
      setEnableButton(false);
      navigate(-1);
    }
  };

  const formik = useFormik({
    initialValues: initialData,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    generateNumbersArray();
    getBigFive();
  }, []);

  const handleChange = (name, value) => {
    setEnableButton(true);
    if (value === '0%') {
      formik?.setFieldValue(name, null);
    } else {
      formik?.setFieldValue(name, value);
    }
  };

  const handleSaveType = () => {
    if (isOriginSignup) {
      submitHandler(formik.values, false);
    } else if (Object.values(formik.values).some((value) => value !== null)) {
      setIsCreateModalOpen(true);
    } else {
      submitHandler(formik.values, false);
    }
  };

  return (
    <>
      <AssesmentsLayout title="The Big Five">
        <div className="rounded-md bg-white px-[24px] py-[36px]">
          <div className="text-[#0071BC] md:text-[24px] text-[18px] font-medium mb-[24px]">
            Personality Assessment
          </div>
          <div className="text-[#0071BC] md:text-[14px] text-[12px] font-medium leading-6 rounded-md bg-[#0071BC0D] p-1 w-fit md:px-[28px] px-[12px] md:py-[13px] py-[6px] mb-[40px]">
            Enter the results of your assessment by selecting the percentage number from 0 to 100.
          </div>
          <form autoComplete="off">
            <div>
              <div className="flex justify-between lg:flex-row flex-col pb-[24px] gap-y-[20px]">
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Openness
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      options={optionsList}
                      defaultValue={
                        formik.values.openness === null ? '0%' : formik?.values?.openness
                      }
                      onChange={(value) => {
                        handleChange('openness', value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Intellect
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      onChange={(value) => {
                        handleChange('intellect', value);
                      }}
                      options={optionsList}
                      defaultValue={
                        formik?.values?.intellect === null ? '0%' : formik?.values?.intellect
                      }
                    />
                  </div>
                </div>
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Volatility
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      onChange={(value) => {
                        handleChange('volatility', value);
                      }}
                      options={optionsList}
                      defaultValue={
                        formik?.values?.volatility === null ? '0%' : formik?.values?.volatility
                      }
                    />
                  </div>
                </div>
              </div>
              <hr className="pb-8 hidden lg:block" style={{ color: 'rgba(161, 160, 160, 0.50)' }} />
              <div className="flex justify-between lg:flex-row flex-col pb-[24px] gap-y-[20px]">
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Aesthetics
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      onChange={(value) => {
                        handleChange('aesthetics', value);
                      }}
                      options={optionsList}
                      defaultValue={
                        formik?.values?.aesthetics === null ? '0%' : formik?.values?.aesthetics
                      }
                    />
                  </div>
                </div>
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Enthusiasm
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      onChange={(value) => {
                        handleChange('enthusiasm', value);
                      }}
                      options={optionsList}
                      defaultValue={
                        formik?.values?.enthusiasm === null ? '0%' : formik?.values?.enthusiasm
                      }
                    />
                  </div>
                </div>
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Politeness
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      onChange={(value) => {
                        handleChange('politeness', value);
                      }}
                      options={optionsList}
                      defaultValue={
                        formik?.values?.politeness === null ? '0%' : formik?.values?.politeness
                      }
                    />
                  </div>
                </div>
              </div>
              <hr className="pb-8 hidden lg:block" style={{ color: 'rgba(161, 160, 160, 0.50)' }} />
              <div className="flex justify-between lg:flex-row flex-col pb-[24px] gap-y-[20px]">
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Neuroticism
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      onChange={(value) => {
                        handleChange('neuroticism', value);
                      }}
                      options={optionsList}
                      defaultValue={
                        formik?.values?.neuroticism === null ? '0%' : formik?.values?.neuroticism
                      }
                    />
                  </div>
                </div>
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Compassion
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      onChange={(value) => {
                        handleChange('compassion', value);
                      }}
                      options={optionsList}
                      defaultValue={
                        formik?.values?.compassion === null ? '0%' : formik?.values?.compassion
                      }
                    />
                  </div>
                </div>
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Orderliness
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      onChange={(value) => {
                        handleChange('orderliness', value);
                      }}
                      options={optionsList}
                      defaultValue={
                        formik?.values?.orderliness === null ? '0%' : formik?.values?.orderliness
                      }
                    />
                  </div>
                </div>
              </div>
              <hr className="pb-8 hidden lg:block" style={{ color: 'rgba(161, 160, 160, 0.50)' }} />
              <div className="flex justify-between lg:flex-row flex-col pb-[24px] gap-y-[20px]">
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Extraversion
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      onChange={(value) => {
                        handleChange('extraversion', value);
                      }}
                      options={optionsList}
                      defaultValue={
                        formik?.values?.extraversion === null ? '0%' : formik?.values?.extraversion
                      }
                    />
                  </div>
                </div>
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Withdrawal
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      onChange={(value) => {
                        handleChange('withdrawal', value);
                      }}
                      options={optionsList}
                      defaultValue={
                        formik?.values?.withdrawal === null ? '0%' : formik?.values?.withdrawal
                      }
                    />
                  </div>
                </div>
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Assertiveness
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      onChange={(value) => {
                        handleChange('assertiveness', value);
                      }}
                      options={optionsList}
                      defaultValue={
                        formik?.values?.assertiveness === null
                          ? '0%'
                          : formik?.values?.assertiveness
                      }
                    />
                  </div>
                </div>
              </div>
              <hr className="pb-8 hidden lg:block" style={{ color: 'rgba(161, 160, 160, 0.50)' }} />
              <div className="flex justify-between lg:flex-row flex-col pb-[24px] gap-y-[20px]">
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Agreeableness
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      onChange={(value) => {
                        handleChange('agreeableness', value);
                      }}
                      options={optionsList}
                      defaultValue={
                        formik?.values?.agreeableness === null
                          ? '0%'
                          : formik?.values?.agreeableness
                      }
                    />
                  </div>
                </div>
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Industriousness
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      onChange={(value) => {
                        handleChange('industriousness', value);
                      }}
                      options={optionsList}
                      defaultValue={
                        formik?.values?.industriousness === null
                          ? '0%'
                          : formik?.values?.industriousness
                      }
                    />
                  </div>
                </div>
                <div className="flex lg:w-[25vw] w-full justify-between items-center">
                  <div className="text-[#333] lg:text-[20px] text-[16px] font-semibold">
                    Conscientiousness
                  </div>
                  <div className="lg:w-[8vw] w-[30vw]">
                    <SelectBox
                      onChange={(value) => {
                        handleChange('conscientiousness', value);
                      }}
                      options={optionsList}
                      defaultValue={
                        formik?.values?.conscientiousness === null
                          ? '0%'
                          : formik?.values?.conscientiousness
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="flex sm:flex-row flex-col-reverse justify-end items-center sm:gap-[2vw] gap-y-[20px] sm:mt-[36px] mt-[0]">
          <div
            className="text-[#333] text-base font-medium cursor-pointer sm:w-[auto] w-full text-center"
            onClick={() => navigate(-1)}
          >
            Cancel
          </div>
          <Button
            label="Save"
            onClick={() => {
              handleSaveType();
            }}
            showArrowIcon={false}
            customClass="sm:w-auto w-full"
            isDisabled={!enableButton}
          />
        </div>
      </AssesmentsLayout>
      <ConfirmationModal
        title="Notify others"
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        secondaryButtonTitle="No"
        secondaryButtonAction={() => submitHandler(formik.values, false)}
        primaryButtonTitle="Yes"
        primaryButtonAction={() => submitHandler(formik.values, true)}
        isPrimaryButtonDisabled={isLoading}
      >
        <div className="text-[18px] tx-greydark font-medium">
          Do you want to notify your connection about your profile update?
        </div>
      </ConfirmationModal>
    </>
  );
};

export default BigFive;
