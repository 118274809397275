import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import secureLocalStorage from 'react-secure-storage';
import AuthPanelLayout from '../../components/AuthPanelLayout';
import { Button } from '../../components/common/Button';
import { PATHS } from '../../constants/urlPaths';
import Input from '../../components/common/Input';
import Modal from '../../components/Modal';
import InputProfilePicture from '../../components/InputProfilePicture';
import { LANG } from '../../constants/lang';
import { getErrorMessage, successStatus } from '../../common';
import { validationSchemaLocation } from '../../validations';
import {
  fetchFileUPloadAWS,
  fetchGenratePreSignedUrl,
  fetchProfileEdit,
} from '../../services/signup';
import { ToastNotifyError, ToastNotifySuccess } from '../../components/Toast/ToastNotify';
import { getFileExtension } from '../../utils/helper';
import { profileDispatcher } from '../../redux/dispatchers/authDispatcher';
import { notificationListDispatcher } from '../../redux/dispatchers/notificationDispatcher';
import axios from 'axios';
import { useCallback } from 'react';
import debounce from '../../utils/debounce';
import AutoComplete from '../../components/common/AutoComplete';
import { addLocation } from '../../services/myself';
import { LIMITS } from '../../constants/constants';

const initialLocationData = {
  searchVal: '',
  country: '',
  state: '',
  city: '',
};

const { PATH_SIGNUP, PATH_WORK } = PATHS;
const { LANG_GEN_INFO, LANG_PROVIDE_INFO, LANG_NEXT, LANG_SKIP } = LANG.PAGES.GEN_INFO;

function GeneralInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cropImageFile, setCropImageFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [locationOptionLists, setLocationOptionsLists] = useState([]);
  const [locationSearchedValue, setLocationSearchedValue] = useState([]);
  const [locationData, setLocationData] = useState(initialLocationData);
  const data = secureLocalStorage.getItem('object');
  const userData = data?.data;

  const fetchLocation = async (searchVal = '') => {
    if (searchVal !== null && searchVal !== undefined && searchVal?.trim()?.length) {
      const url = `https://api.locationiq.com/v1/autocomplete?key=${process.env.REACT_APP_LOCATION_KEY}&q=${searchVal}`;
      const response = await axios
        .get(url)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error('Error:', error);
          return {};
        });
      const { data = [] } = response;
      let updatedData = data;
      if (data?.length > 5) {
        updatedData = data?.slice(0, 5);
      }
      setLocationSearchedValue(updatedData);
      const refractedResponse = updatedData.map((item) => {
        return item?.display_name;
      });
      setLocationOptionsLists([...refractedResponse]);
    }
  };

  const updateSearchVal = useCallback(
    debounce((val) => {
      fetchLocation(val);
    }, 400),
    [],
  );

  useEffect(() => {
    setLocationOptionsLists([]);
    updateSearchVal(locationData?.searchVal);
  }, [locationData?.searchVal]);

  if (!userData) {
    window.location.replace(PATH_SIGNUP);
  }

  const getPreSignedUrl = async () => {
    if (cropImageFile) {
      const response = await fetchGenratePreSignedUrl(
        getFileExtension(cropImageFile?.name),
        'profilePicture',
      );
      const { status = 0, data = {} } = response;
      if (successStatus(status)) {
        const {
          data: { url, key },
        } = data;
        formik.setFieldValue('profile_picture', key);
        await fetchFileUPloadAWS({ url: url, selectedFile: cropImageFile });
      }
    }
  };

  const handleSkip = async () => {
    secureLocalStorage.setItem('object', { data });
    await dispatch(profileDispatcher());
    await dispatch(
      notificationListDispatcher({
        page: 1,
        limit: LIMITS.FETCH_UNREAD_NOTIFS_LIMIT,
      }),
    );
    navigate(PATH_WORK, { replace: true });
  };

  useEffect(() => {
    getPreSignedUrl();
  }, [cropImageFile]);

  const initialValues = {
    profile_picture: '',
  };

  const onSubmit = async (values) => {
    const { profile_picture = '' } = values;
    let dataToSend = {};

    let response;

    if (locationData?.country) {
      const { searchVal, ...restLocationData } = locationData;
      dataToSend = [{ ...restLocationData, isCurrent: true, type: 'current' }];
      response = await addLocation(dataToSend);
    }
    if (profile_picture) {
      dataToSend = {
        profilePicture: profile_picture,
      };
      response = await fetchProfileEdit(dataToSend);
    }
    const { status = '', data = {} } = response;
    const errormsg = getErrorMessage(data);

    if (successStatus(status)) {
      ToastNotifySuccess('General Info added Successfully', 'location-success');
      await dispatch(profileDispatcher());
      await dispatch(
        notificationListDispatcher({
          page: 1,
          limit: LIMITS.FETCH_UNREAD_NOTIFS_LIMIT,
        }),
      );
      navigate(PATH_WORK);
    } else {
      if (errormsg) {
        ToastNotifyError(errormsg, 'location-failed');
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaLocation,
    onSubmit,
  });

  const { values: { profile_picture } = {}, handleSubmit } = formik;

  const changeRaisedLocation = async (e) => {
    setLocationData((prevData) => {
      return {
        ...prevData,
        searchVal: e,
      };
    });

    locationSearchedValue?.forEach((element) => {
      if (element?.display_name === e) {
        setLocationData((prevData) => {
          return {
            ...prevData,
            country: element?.address?.country,
            state: element?.address?.state,
            city: element?.address?.name,
            latitude: element?.lat,
            longitude: element?.lon,
          };
        });
      }
    });
  };

  const renderFields = () => {
    return (
      <>
        <div className="w-full">
          <AutoComplete
            label="Location"
            placeholder="Search Location"
            options={locationOptionLists}
            defaultValue={locationData?.searchVal}
            onChange={(e) => changeRaisedLocation(e)}
          />
        </div>
        <div className="w-full">
          <Input
            label="Country"
            className="rounded-md bg-[#D1D1D1] p-4 w-full"
            disabled
            initialValue={locationData?.country}
          />
        </div>
        <div className="w-full">
          <Input
            disabled
            className="rounded-md bg-[#D1D1D1] p-4 w-full"
            label="State"
            initialValue={locationData?.state}
          />
        </div>
        <div className="w-full">
          <Input
            disabled
            className="rounded-md bg-[#D1D1D1] p-4 w-full"
            label="City"
            initialValue={locationData?.city}
          />
        </div>
      </>
    );
  };

  return (
    <AuthPanelLayout>
      <div className="flex items-center gap-2">
        <h1 className="text-white pr-2">{LANG_GEN_INFO}</h1>
      </div>
      <div className="max-w-[392px]">
        <h4 className="text-white mb-2 pr-2">{LANG_PROVIDE_INFO}</h4>
        <div className="border-b border-[#F2F2F233] w-3/4" />
      </div>
      <form onSubmit={handleSubmit} className="flex flex-col gap-[24px] max-w-[400px] mt-[24px]">
        <InputProfilePicture setCropImageFile={setCropImageFile} cropImageFile={cropImageFile} />
        <div className="flex flex-col gap-y-[10px]">{renderFields()}</div>
        <Button
          type="submit"
          label={LANG_NEXT}
          isDisabled={!location && !profile_picture}
          additionalClassNames="capitalize"
        />
        <div className={'text-center para-normal cursor-pointer'} onClick={handleSkip}>
          {LANG_SKIP}
        </div>
      </form>
      <Modal isOpen={open} onClose={() => setOpen(false)} isTitle={false}></Modal>
    </AuthPanelLayout>
  );
}

export default GeneralInfo;
