import React from 'react';
import SearchLens from '../Icons/SearchLens';
import ExpandIcon from '../Icons/expandIcon';
import { useState } from 'react';
import FilterSearch from '../FilterSearch';
import { Colors } from '../../constants/colors';
import SeeAllPopup from '../FilterPopup';

const FilterSidebar = ({
  title,
  subtitles = [],
  items = [],
  assesment = false,
  handleElementClicked,
  checkedItems,
  handleCheckboxChange,
}) => {
  const [itemsState, setItemsState] = useState(() => {
    const initialState = {};
    subtitles.forEach((_, index) => {
      initialState[index] = true;
    });
    return initialState;
  });

  const [itemState, setItemState] = useState(true);
  const [searchIndex, setSearchIndex] = useState([]);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [showSeeAllPopup, setShowSeeAllPopup] = useState(() => {
    const initialSeeAllState = {};
    subtitles.forEach((_, index) => {
      initialSeeAllState[index] = false;
    });
    return initialSeeAllState;
  });

  const handleElementClick = (element, title) => {
    handleElementClicked(element, title);
  };

  const handleSeeAllToggle = (index) => {
    setShowSeeAllPopup((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleSubtitleToggle = (index) => {
    setItemsState((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleSearchToggle = (index) => {
    setSearchIndex(index);
    setSearch(true);
  };

  const handleClearSearch = () => {
    setSearch(false);
    setSearchValue('');
  };

  const searchInputChangeHandler = (value) => {
    setSearchValue(value);
  };
  const convertToLowerCaseWithExceptions = (name) => {
    const lowerCaseSubtitleName = name.trim().toLowerCase();
    const withoutHyphens = lowerCaseSubtitleName.replace(/-/g, '');
    const withoutThe = withoutHyphens.replace(/^the/, '');
    const withoutSpaces = withoutThe.replace(/\s+/g, '');
    if (withoutSpaces === 'attributes') {
      return 'attribute';
    }
    return withoutSpaces;
  };

  return (
    <div className="pr-4 pl-4 pb-4">
      <div>
        {!search ? (
          <div className="flex justify-between mr-[6px] items-baseline">
            <div className="filter-heading mt-2 mb-0">{title}</div>
            {subtitles.length === 0 ? (
              <div className="flex justify-center items-center space-x-4">
                <div onClick={() => handleSearchToggle(0)} className="cursor-pointer mb-[2px]">
                  <SearchLens width={28} height={28} />
                </div>
                <div
                  onClick={() => setItemState(!itemState)}
                  className={`cursor-pointer mb-[2px] transform transition-transform ease-in-out duration-300 ${
                    itemsState ? 'rotate-180' : ''
                  }`}
                >
                  <ExpandIcon width={28} height={28} />
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            {search &&
            searchIndex !== null &&
            title !== 'Work' &&
            title !== 'Personality Assessment' ? (
              <FilterSearch
                iconColor={Colors.filterIcon}
                onChange={searchInputChangeHandler}
                value={searchValue}
                textColor="text-black"
                isAutoFocus={true}
                onClear={handleClearSearch}
                onElementClick={handleElementClick}
                placeholder={`Search by ${title}`}
                selectedSearch={title}
              />
            ) : (
              <div className="flex justify-between items-baseline">
                <div className="filter-heading mb-3 mt-4">{title}</div>
                {subtitles.length === 0 ? (
                  <div className="flex justify-center items-center space-x-4">
                    <div onClick={() => handleSearchToggle(0)} className="cursor-pointer mb-[2px]">
                      <SearchLens width={28} height={28} />
                    </div>
                    <div
                      onClick={() => setItemState(!itemState)}
                      className={`cursor-pointer mb-[2px] transform transition-transform ease-in-out duration-300 ${
                        itemsState ? 'rotate-180' : ''
                      }`}
                    >
                      <ExpandIcon width={28} height={28} />
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
        <ul className="mt-2">
          {subtitles.length ? (
            subtitles.map((subtitleData, subIndex) => (
              <React.Fragment key={subIndex}>
                <li>
                  {!search ? (
                    <div className="flex justify-between mr-[6px] items-end">
                      <div className="detail-heading mt-3">{subtitleData.subtitle}</div>
                      <div className="flex justify-center items-center space-x-4">
                        <div
                          onClick={() => handleSearchToggle(subIndex)}
                          className="cursor-pointer mb-[2px]"
                        >
                          {assesment ? '' : <SearchLens width={28} height={28} />}
                        </div>
                        <div
                          onClick={() => handleSubtitleToggle(subIndex)}
                          className={`cursor-pointer mb-[2px] transform transition-transform ease-in-out duration-300 ${
                            itemsState[subIndex] ? 'rotate-180' : ''
                          }`}
                        >
                          <ExpandIcon width={28} height={28} />
                        </div>
                      </div>
                    </div>
                  ) : searchIndex === subIndex ? (
                    <FilterSearch
                      iconColor={Colors.filterIcon}
                      onChange={searchInputChangeHandler}
                      value={searchValue}
                      textColor="text-black"
                      isAutoFocus={true}
                      onClear={handleClearSearch}
                      onElementClick={handleElementClick}
                      selectedSearch={subtitles[subIndex].subtitle}
                      placeholder={`Search by ${subtitles[subIndex].subtitle}`}
                    />
                  ) : (
                    <div className="flex justify-between items-baseline">
                      <div className="detail-heading mt-3">{subtitleData.subtitle}</div>
                      <div className="flex justify-center items-center space-x-4">
                        <div
                          onClick={() => handleSearchToggle(subIndex)}
                          className="cursor-pointer mb-[2px]"
                        >
                          <SearchLens width={28} height={28} />
                        </div>
                        <div
                          onClick={() => handleSubtitleToggle(subIndex)}
                          className={`cursor-pointer mb-[2px] transform transition-transform ease-in-out duration-300 ${
                            itemsState[subIndex] ? 'rotate-180' : ''
                          }`}
                        >
                          <ExpandIcon width={28} height={28} />
                        </div>
                      </div>
                    </div>
                  )}

                  {itemsState[subIndex] && (
                    <ul className="relative">
                      {subtitleData.items.map((item, itemIndex) => {
                        return (
                          <li
                            key={itemIndex}
                            className="mb-2 content-style flex justify-between mt-[10px]"
                          >
                            {item.name}
                            <input
                              type="checkbox"
                              className="mr-2 cursor-pointer"
                              checked={checkedItems[
                                convertToLowerCaseWithExceptions(subtitleData.subtitle)
                              ]?.includes(item.value)}
                              onChange={() =>
                                handleCheckboxChange(subtitleData.subtitle, item.value)
                              }
                            />
                          </li>
                        );
                      })}
                      {assesment ? (
                        <li
                          className="text-[#0071BC] font-normal text-[14px] cursor-pointer"
                          onClick={() => handleSeeAllToggle(subIndex)}
                        >
                          + See All
                        </li>
                      ) : (
                        ''
                      )}
                      <div className="absolute top-[0px] z-50">
                        {showSeeAllPopup[subIndex] && (
                          <SeeAllPopup
                            onClose={() => {
                              handleSeeAllToggle(subIndex);
                            }}
                            all={subtitleData}
                            placeholder={`Search for ${subtitles[subIndex].subtitle}`}
                            onItemChecked={handleCheckboxChange}
                            checkedItems={checkedItems}
                          />
                        )}
                      </div>
                    </ul>
                  )}
                </li>
                {subIndex === subtitles.length - 1 ? null : (
                  <div className="border-b border-[#A1A0A0] h-0.5 mt-2"></div>
                )}
              </React.Fragment>
            ))
          ) : itemState ? (
            <ul>
              {items.map((item, itemIndex) => (
                <li key={itemIndex} className="mb-2 content-style flex justify-between">
                  {item.name}
                  <input
                    type="checkbox"
                    className="mr-2 cursor-pointer"
                    checked={checkedItems[convertToLowerCaseWithExceptions(title)]?.includes(
                      item.value,
                    )}
                    onChange={() => handleCheckboxChange(title, item.value)}
                  />
                </li>
              ))}
            </ul>
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default FilterSidebar;
