import React from 'react';

const PhotoIcon = ({ fill = '#0071BC' }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Image">
        <g id="Iconly/Light outline/Image">
          <g id="Image_2">
            <path
              id="Vector"
              d="M17.0087 2C20.5537 2 22.9163 4.42887 22.9163 7.91408V16.0849C22.9163 19.5703 20.5536 22 17.0087 22H7.98022C4.43693 22 2.08301 19.5722 2.08301 16.0849V7.91408C2.08301 4.43059 4.4438 2 7.98022 2H17.0087ZM17.0087 3.50383H7.98022C5.33369 3.50383 3.64864 5.2387 3.64864 7.91408V16.0849C3.64864 18.7647 5.32748 20.4962 7.98022 20.4962H17.0087C19.6642 20.4962 21.3507 18.7618 21.3507 16.0849V7.91408C21.3507 5.23744 19.6644 3.50383 17.0087 3.50383ZM17.7506 12.1835L17.8863 12.3086L20.0532 14.4561C20.3541 14.7543 20.3463 15.2303 20.0359 15.5193C19.7536 15.7821 19.3184 15.7998 19.0155 15.5768L18.9289 15.5027L16.7622 13.3553C16.3847 12.9814 15.771 12.9779 15.3879 13.3269L15.3038 13.4137L12.9777 16.1312C12.1549 17.0939 10.6744 17.1971 9.7166 16.3903L9.58933 16.2747L8.66099 15.3648C8.41714 15.1217 8.02825 15.0987 7.75744 15.2949L7.67191 15.3684L6.07601 16.9855C5.77858 17.2869 5.2831 17.2996 4.96934 17.0139C4.68409 16.7542 4.64721 16.3372 4.86633 16.0372L4.93975 15.9509L6.53531 14.3341C7.3753 13.4819 8.75855 13.4365 9.65334 14.1967L9.78377 14.3167L10.7074 15.222C10.9774 15.4865 11.4102 15.4933 11.6892 15.2553L11.768 15.1766L14.0943 12.4589C15.0186 11.3782 16.6829 11.2689 17.7506 12.1835ZM8.92951 6.64124C10.3722 6.64124 11.5431 7.76589 11.5431 9.15164C11.5431 10.5374 10.3722 11.662 8.92951 11.662C7.48688 11.662 6.317 10.5374 6.317 9.15164C6.317 7.76583 7.48688 6.64124 8.92951 6.64124ZM8.92951 8.14507C8.35183 8.14507 7.88263 8.59611 7.88263 9.15164C7.88263 9.70716 8.35183 10.1582 8.92951 10.1582C9.50753 10.1582 9.97744 9.70684 9.97744 9.15164C9.97744 8.59643 9.50753 8.14507 8.92951 8.14507Z"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PhotoIcon;
