import React from 'react';

const CommentIcon = () => {
  return (
    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon/light/Comment">
        <path
          id="Vector"
          d="M2.75 8.33268C2.75 6.42306 4.06916 4.875 5.69643 4.875H16.3036C17.9308 4.875 19.25 6.42306 19.25 8.33268V15.248C19.25 17.1577 17.9308 18.7057 16.3036 18.7057H11.8107L8.20128 22.412C7.45448 23.1789 6.28571 22.5565 6.28571 21.3919V18.7057H5.69643C4.06916 18.7057 2.75 17.1577 2.75 15.248V8.33268ZM5.69643 6.25807C4.72006 6.25807 3.92857 7.1869 3.92857 8.33268V15.248C3.92857 16.3938 4.72006 17.3227 5.69643 17.3227H7.46429V21.3309L11.3679 17.3227H16.3036C17.2799 17.3227 18.0714 16.3938 18.0714 15.248V8.33268C18.0714 7.1869 17.2799 6.25807 16.3036 6.25807H5.69643Z"
          fill="#A1A0A0"
        />
      </g>
    </svg>
  );
};

export default CommentIcon;
