import React from 'react';
import alert from '../../assets/images/alert.svg';
import assesmentCheck from '../../assets/images/assesmentCheck.svg';
import rightArrow from '../../assets/images/rightArrow.svg';
import { useNavigate } from 'react-router-dom';
import { ASSESMENTS_LIST } from '../../constants/constants';

const Assesments = ({
  discAssessment = '',
  enneagram = '',
  hollandCode = '',
  myersBriggsAssessment = '',
  attributeAssessment = '',
  bigFiveAssessment = '',
  hideHeading = false,
}) => {
  const navigate = useNavigate();

  const checkIfValueExists = (title) => {
    switch (title) {
      case ASSESMENTS_LIST[0]?.title:
        if (bigFiveAssessment) {
          const {
            id: bigFiveId,
            createdAt: bigFiveCreatedAt,
            updatedAt: bigFiveUpdatedAt,
            UserId: bigFiveUserId,
            ...bigFiveRest
          } = bigFiveAssessment;
          return Object.values(bigFiveRest).some((value) => value !== null);
        } else {
          return false;
        }
      case ASSESMENTS_LIST[1]?.title:
        if (attributeAssessment) {
          const { id, createdAt, updatedAt, UserId, ...rest } = attributeAssessment;
          return Object.values(rest).some((value) => value !== null);
        } else {
          return false;
        }
      case ASSESMENTS_LIST[2]?.title:
        if (myersBriggsAssessment) {
          return true;
        } else {
          return false;
        }
      case ASSESMENTS_LIST[3]?.title:
        if (discAssessment) {
          return true;
        } else {
          return false;
        }
      case ASSESMENTS_LIST[4]?.title:
        if (enneagram) {
          return true;
        } else {
          return false;
        }

      case ASSESMENTS_LIST[5]?.title:
        if (hollandCode) {
          return true;
        } else {
          return false;
        }
    }
  };

  const handleAssessmentsNavigate = (route) => {
    navigate(route);
  };
  return (
    <div className="rounded-[4px] bg-white shadow-md rounded-b-[0]">
      {!hideHeading ? (
        <div className="p-[18px] bg-[#E9F6FF] rounded-[4px]">
          <div className="text-[#0071BC] text-[14px] font-semibold">Assessments</div>
        </div>
      ) : (
        ''
      )}
      <div className="px-[32px] py-[36px]">
        <div className="text-[#0071BC] text-2xl font-semibold capitalize mb-[10px]">
          Personality and Behavioral Assessments
        </div>
        <div className="text-[#A1A0A0] text-base font-medium mb-[20px]">
          Research shows that most organizations use personality or behavioral assessment in hiring.
          Have you taken any of them? If so, you can share the results here. It will help them in
          their search for someone with the personal attributes you have.
        </div>
        <div>
          {ASSESMENTS_LIST.map((item, index) => {
            return (
              <React.Fragment key={index + item?.title}>
                <div
                  className="flex justify-between mb-[20px] cursor-pointer"
                  onClick={() => {
                    handleAssessmentsNavigate(item.navigate);
                  }}
                >
                  <div className="flex gap-[24px] items-center">
                    <img
                      src={checkIfValueExists(item?.title) ? assesmentCheck : alert}
                      className="w-[24px]"
                      alt=""
                    />
                    <div className="text-[#0071BC] text-[20px] font-medium">{item?.title}</div>
                  </div>
                  <img src={rightArrow} alt="" className="cursor-pointer" />
                </div>
                {ASSESMENTS_LIST.length - 1 !== index ? (
                  <hr className="pb-8" style={{ color: 'rgba(161, 160, 160, 0.50)' }} />
                ) : (
                  ''
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Assesments;
