import React from 'react';
import { useNavigate } from 'react-router-dom';
import backIcon from '../../assets/images/backIcon.svg';
import WorkNavbar from '../Navbar.js/WorkNavbar';
import Background from '../../assets/images/private-background.svg';

const AssesmentsLayout = ({ title = '', showBackgroundImage = false, children }) => {
  const navigate = useNavigate();
  return (
    <>
      <WorkNavbar />
      <div
        className="sm:py-[36px] py-[10px] lg:px-[70px] md:px-[40px] sm:px-[20px] px-[0px] bg-bluebg min-h-[calc(100vh-80px)]"
        style={
          showBackgroundImage
            ? { backgroundImage: `url(${Background})`, backgroundSize: 'cover' }
            : {}
        }
      >
        <div className="flex justify-between flex-col">
          <div
            className="flex text-[18px] md:text-[18px] lg:text-[24px] sm:px-[0] px-[20px] py-4 sticky h-fit cursor-pointer font-semibold"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={backIcon} alt="backIcon" className="w-[20px] lg:w-[30px]" />
            {title}
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default AssesmentsLayout;
