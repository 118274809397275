import React, { useState } from 'react';
import AssesmentsLayout from '../../../components/MyselfPage/AssesmentLayout';
import { Button } from '../../../components/common/Button';
import { getErrorMessage, successStatus } from '../../../common';
import { ToastNotifyError, ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import { useEffect } from 'react';
import RadioButtonsGroup from '../../../components/common/RadioButtonsGroup';
import { DISC } from '../../../constants/constants';
import { userProfile } from '../../../services/auth';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import { fetchProfileEdit } from '../../../services/signup';
import { useSelector } from 'react-redux';

const Disc = () => {
  const { isOriginSignup = false } = useSelector((state) => state.auth || {});

  const [isLoading, setIsLoading] = useState(false);
  const [pageRadioData, setPageRadioData] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const navigate = useNavigate();

  const getAttributesData = async () => {
    const response = await userProfile();
    const { data: { data = '' } = {}, status = '' } = response;
    if (status) {
      const { discAssessment = '' } = data;
      setSelectedValue(discAssessment);
    }
  };

  const getPageData = () => {
    const listArray = [];
    DISC.forEach((element) => {
      listArray.push({
        label: (
          <>
            <div>
              <div className="text-[#333] sm:text-[20px] text-[16px] font-semibold leading-7 mb-[8px]">
                {element.title}
              </div>
              <div className="text-[#A1A0A0] sm:text-[16px] text-[14px] font-medium leading-6 mb-[18px]">
                {element.description}
              </div>
            </div>
          </>
        ),
        value: element.value,
        name: element.name,
      });
    });
    setPageRadioData([...listArray]);
  };

  useEffect(() => {
    getPageData();
    getAttributesData();
  }, []);

  const submitHandler = async (notifyOthers = false) => {
    if (!isLoading) {
      setIsLoading(true);
      const requestData = {
        discAssessment: selectedValue,
        notifyOthers,
      };
      const response = await fetchProfileEdit(requestData);
      const { status = '', data = '' } = response;
      const errormsg = getErrorMessage(data);
      if (successStatus(status)) {
        ToastNotifySuccess('Disc saved successfully', 'location-success');
      } else {
        if (errormsg) {
          ToastNotifyError(errormsg, 'location-failed');
        }
      }
      setIsCreateModalOpen(false);
      setIsLoading(false);
      navigate(-1);
    }
  };

  const handleSaveType = () => {
    if (isOriginSignup) {
      submitHandler(false);
    } else if (selectedValue) {
      setIsCreateModalOpen(true);
    } else {
      submitHandler(false);
    }
  };

  return (
    <>
      <AssesmentsLayout title="Disc">
        <div className="rounded-md bg-white px-[24px] py-[36px]">
          <div className="text-[#0071BC] md:text-[24px] text-[18px] font-medium mb-[24px]">
            Behavioral Assessment
          </div>
          <div className="text-[#0071BC] md:text-[14px] text-[12px] font-medium leading-6 rounded-md bg-[#0071BC0D] p-1 w-fit md:px-[28px] px-[12px] md:py-[13px] py-[6px] mb-[40px]">
            Enter the result of your assessment by selecting one of the 8 behavioral types.
          </div>
          <div>
            <RadioButtonsGroup
              options={pageRadioData}
              defaultValue={selectedValue}
              showPartitionBelowOptions={true}
              onSelect={(fieldName, value) => {
                if (value === selectedValue) {
                  setSelectedValue(null);
                } else {
                  setSelectedValue(value);
                }
              }}
            />
          </div>
        </div>
        <div className="flex sm:flex-row flex-col-reverse justify-end items-center sm:gap-[2vw] gap-y-[20px] sm:mt-[36px] mt-[0px]">
          <div
            className="text-[#333] text-base font-medium cursor-pointer sm:w-[auto] w-full text-center"
            onClick={() => navigate(-1)}
          >
            Cancel
          </div>
          <Button
            label="Save"
            onClick={() => {
              handleSaveType();
            }}
            showArrowIcon={false}
            customClass="sm:w-auto w-full"
          />
        </div>
      </AssesmentsLayout>
      <ConfirmationModal
        title="Notify others"
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        secondaryButtonTitle="No"
        secondaryButtonAction={() => submitHandler(false)}
        primaryButtonTitle="Yes"
        primaryButtonAction={() => submitHandler(true)}
        isPrimaryButtonDisabled={isLoading}
      >
        <div className="text-[18px] tx-greydark font-medium">
          Do you want to notify your connection about your profile update?
        </div>
      </ConfirmationModal>
    </>
  );
};

export default Disc;
