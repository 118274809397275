import React from 'react';

const TestSeen = ({ seen, width, height, white }) => {
  return (
    <svg
      width={width ? '20' : '24'}
      height={height ? '20' : '24'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4375 10.9375L7.8125 15.3125L16.5625 5.9375"
        stroke={seen ? '#0071BC' : white ? '#FFFFFF' : '#A1A0A0'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TestSeen;
