export const PATHS = {
  LANDING: '/',
  LOGIN: '/login',
  HOME: '/home',
  PATH_SIGNUP: '/signup',
  PATH_VERIFY_EMAIL: '/verify-email',
  PATH_GENERAL_INFO: '/general-info',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  PATH_WORK: '/work',
  PATH_ADD_CAREER: '/career',
  PATH_INTERESTS: '/interests',
  PATH_MYSELF: '/myself',
  PROFILE: '/my-profile',
  PROFILE_ADD_EDIT_CAREER: '/my-profile/career',
  MYNETWORK: '/my-network',
  OTHER_USER_PROFILE: '/profile/',
  MESSAGES: '/messages',
  MY_NOTIFICATION: '/notification',
  SETTINGS: '/settings',
  SETTINGS_CHANGE_PASSWORD: '/settings/change-password',
  SETTINGS_HELP_CENTER: '/settings/help-center',
  SETTINGS_PRIVACY_SETTING: '/settings/privacy-setting',
  SETTINGS_DELETE_ACCOUNT: '/settings/delete-account',
  SETTINGS_BLOCKED_LIST: '/settings/blocked-users',
  SEARCH_PAGE: '/search-page',
  ASSESSMENTS_BIG_FIVE: '/assessments/big-five',
  ASSESSMENTS_ATTRIBUTES: '/assessments/attributes',
  ASSESSMENTS_MYERS_BRIGGS: '/assessments/myers-briggs',
  ASSESSMENTS_DISC: '/assessments/disc',
  ASSESSMENTS_ENNEAGRAM: '/assessments/enneagram',
  ASSESSMENTS_HOLLAND_CODE: '/assessments/holland-code',
  PROFILE_ADD_ASSESSMENT: '/my-profile/assessments',
};
