const ShareIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9929 10.9438L15.8679 2.81884C15.7827 2.73372 15.6742 2.67577 15.556 2.6523C15.4379 2.62884 15.3154 2.6409 15.2042 2.68698C15.0929 2.73306 14.9977 2.81108 14.9308 2.91119C14.8638 3.01131 14.828 3.12902 14.8279 3.24946V7.5354C9.29479 7.90001 2.6008 13.1213 1.83502 19.7899C1.81015 20.0023 1.85293 20.2171 1.95725 20.4037C2.06158 20.5903 2.22213 20.7392 2.41604 20.8293C2.60996 20.9193 2.82734 20.9458 3.03722 20.9051C3.2471 20.8644 3.43878 20.7585 3.58494 20.6024C4.72854 19.3827 8.83572 15.4979 14.8279 15.247V19.4995C14.828 19.6199 14.8638 19.7376 14.9308 19.8377C14.9977 19.9378 15.0929 20.0159 15.2042 20.0619C15.3154 20.108 15.4379 20.1201 15.556 20.0966C15.6742 20.0732 15.7827 20.0152 15.8679 19.9301L23.9929 11.8051C24.107 11.6908 24.1711 11.5359 24.1711 11.3745C24.1711 11.213 24.107 11.0581 23.9929 10.9438ZM16.0467 18.0288V14.6245C16.0467 14.4628 15.9825 14.3079 15.8682 14.1936C15.7539 14.0793 15.5989 14.0151 15.4373 14.0151C12.621 14.0151 9.87572 14.7514 7.27979 16.2038C5.76674 17.0537 4.37301 18.1005 3.13502 19.3167C3.61236 16.6192 5.18252 14.0354 7.60377 11.9767C10.0006 9.9465 12.9277 8.73384 15.4373 8.73384C15.5989 8.73384 15.7539 8.66964 15.8682 8.55536C15.9825 8.44108 16.0467 8.28608 16.0467 8.12446V4.72111L22.7 11.3745L16.0467 18.0288Z"
        fill="#A1A0A0"
      />
    </svg>
  );
};

export default ShareIcon;
