export const FooterLogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="179"
      height="49"
      viewBox="0 0 179 49"
      fill="none"
    >
      <path
        d="M66.4595 28.9606C70.8543 28.9606 73.703 30.5031 73.703 34.5077C73.703 38.7318 70.3132 40.1913 66.2454 40.1913H63.6109V46.4444C63.6109 48.07 62.469 48.3666 61.9041 48.3666C61.3332 48.3666 60.1973 48.07 60.1973 46.4444V30.9955C60.1973 29.6132 60.8514 28.9665 62.2311 28.9665H66.4595V28.9606ZM63.6109 37.3198H65.9956C68.2198 37.3198 70.2537 36.7799 70.2537 34.5077C70.2537 32.2355 68.2198 31.8261 66.1027 31.8261H63.6049V37.3198H63.6109Z"
        fill="#0071BC"
      />
      <path
        d="M87.0009 46.6581C87.0009 48.1176 85.9424 48.3608 85.4547 48.3608C84.967 48.3608 83.9085 48.1176 83.9085 46.6581V46.0352H83.8549C83.284 47.3345 81.7378 48.4973 79.811 48.4973C76.4212 48.4973 75.0117 45.8987 75.0117 43.2468V36.697C75.0117 35.1545 76.1 34.8816 76.6412 34.8816C77.1824 34.8816 78.2707 35.1545 78.2707 36.697V42.3272C78.2707 43.8697 78.6216 45.7919 80.7149 45.7919C82.7785 45.7919 83.7538 44.0062 83.7538 42.2738V36.697C83.7538 35.1545 84.8362 34.8816 85.3833 34.8816C85.9245 34.8816 87.0128 35.1545 87.0128 36.697V46.6581H87.0009Z"
        fill="#0071BC"
      />
      <path
        d="M92.3717 48.3608C91.8305 48.3608 90.7422 48.0879 90.7422 46.5454V36.6139C90.7422 35.1545 91.8008 34.8816 92.2884 34.8816C92.8058 34.8816 93.8644 35.1545 93.8644 36.6139V37.2903H93.9179C94.4353 35.8842 95.7377 34.7451 97.3077 34.7451C98.283 34.7451 98.9907 35.2316 98.9907 36.1808C98.9907 37.0174 98.4733 37.7471 97.2245 37.7471C95.1608 37.7471 93.9952 39.2896 93.9952 41.4551V46.5454C94.0012 48.0938 92.9188 48.3608 92.3717 48.3608Z"
        fill="#0071BC"
      />
      <path
        d="M102.653 48.1233C101.267 48.1233 100.619 47.4707 100.619 46.0943V30.9954C100.619 29.6131 101.273 28.9664 102.653 28.9664H107.428C112.448 28.9664 118.038 31.3455 118.038 38.5478C118.038 45.2578 112.448 48.1292 107.428 48.1292H102.653V48.1233ZM104.038 45.1451H106.887C110.414 45.1451 114.374 43.7093 114.374 38.5419C114.374 33.0482 110.414 31.8853 106.887 31.8853H104.038V45.1451Z"
        fill="#0071BC"
      />
      <path
        d="M122.653 48.3608C122.112 48.3608 121.023 48.0879 121.023 46.5454V36.6139C121.023 35.1545 122.082 34.8816 122.57 34.8816C123.087 34.8816 124.146 35.1545 124.146 36.6139V37.2903H124.199C124.717 35.8842 126.019 34.7451 127.589 34.7451C128.564 34.7451 129.272 35.2316 129.272 36.1808C129.272 37.0174 128.755 37.7471 127.506 37.7471C125.442 37.7471 124.276 39.2896 124.276 41.4551V46.5454C124.276 48.0938 123.194 48.3608 122.653 48.3608Z"
        fill="#0071BC"
      />
      <path
        d="M132.39 32.6686C131.194 32.6686 130.326 31.749 130.326 30.7226C130.326 29.6963 131.194 28.7708 132.39 28.7708C133.585 28.7708 134.507 29.6132 134.507 30.7226C134.507 31.8261 133.585 32.6686 132.39 32.6686ZM132.42 48.3607C131.878 48.3607 130.79 48.0878 130.79 46.5453V36.691C130.79 35.1485 131.878 34.8756 132.42 34.8756C132.961 34.8756 134.049 35.1485 134.049 36.691V46.5453C134.049 48.0938 132.961 48.3607 132.42 48.3607Z"
        fill="#0071BC"
      />
      <path
        d="M142.891 48.3608C142.106 48.3608 141.155 48.0345 140.53 46.5751L136.57 37.3437C136.409 36.964 136.355 36.6674 136.355 36.4241C136.355 35.5342 137.057 34.8816 138.009 34.8816C138.52 34.8816 139.174 35.0714 139.608 36.2105L142.862 44.5995H142.915L146.251 36.1868C146.685 35.0774 147.334 34.8875 147.827 34.8875C148.779 34.8875 149.427 35.5401 149.427 36.3766C149.427 36.5428 149.397 36.8631 149.207 37.2962L145.246 46.581C144.628 48.0405 143.652 48.3608 142.891 48.3608Z"
        fill="#0071BC"
      />
      <path
        d="M154.097 42.6534C154.234 44.4925 155.917 45.8452 157.814 45.8452C159.17 45.8452 159.984 45.4121 160.799 44.7358C161.263 44.3561 161.56 44.273 161.911 44.273C162.643 44.273 163.214 44.7832 163.214 45.5723C163.214 45.8452 163.107 46.3257 162.672 46.7351C161.4 47.9513 159.931 48.4912 157.843 48.4912C153.829 48.4912 150.844 45.9223 150.844 41.6982C150.844 37.5037 153.799 34.745 157.736 34.745C160.882 34.745 163.434 36.5308 163.951 40.3455C163.975 40.6184 164.005 40.8083 164.005 41.0219C164.005 42.1609 163.38 42.6474 162.298 42.6474H154.097V42.6534ZM160.775 40.3218C160.775 38.6428 159.824 37.1834 157.707 37.1834C155.78 37.1834 154.234 38.5064 154.097 40.3218H160.775Z"
        fill="#0071BC"
      />
      <path
        d="M167.012 36.5901C167.012 35.1307 168.07 34.8874 168.558 34.8874C169.046 34.8874 170.104 35.1307 170.104 36.5901V37.2131H170.158C170.729 35.9138 172.275 34.751 174.202 34.751C177.592 34.751 179.001 37.3495 179.001 40.0015V46.5512C179.001 48.0937 177.919 48.3667 177.372 48.3667C176.83 48.3667 175.742 48.0937 175.742 46.5512V40.921C175.742 39.3785 175.391 37.4563 173.298 37.4563C171.24 37.4563 170.259 39.2421 170.259 40.9744V46.5512C170.259 48.0937 169.177 48.3667 168.629 48.3667C168.088 48.3667 167 48.0937 167 46.5512V36.5901H167.012Z"
        fill="#0071BC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.7538 39.177C6.40563 35.1486 3.67594 30.3253 1.96319 25.1935C0.173139 19.8243 -0.50482 14.1111 0.399128 8.62327C0.987883 5.05175 2.74225 0.756435 7.14305 3.81181C9.21262 5.24753 11.1632 8.07746 12.7333 9.83949C14.5352 11.8626 16.7535 13.5415 19.388 14.8764C23.6936 17.0597 24.0802 19.9964 22.6291 24.3214C21.1007 28.8599 19.501 33.3866 17.9845 37.943C16.242 43.1697 12.9949 43.0807 9.7538 39.177Z"
        fill="url(#paint0_linear_488_1791)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.92707 37.0054C8.23216 31.4345 8.07754 25.6026 9.34426 20.0911C10.6586 14.3778 13.4953 9.00276 17.7355 4.61252C22.963 -0.804091 31.0152 -2.85682 33.2989 6.37455C33.8163 8.48661 34.5359 10.5453 35.4398 12.509C36.4746 14.7753 37.7591 16.9289 39.2697 18.9283C42.8855 23.7219 40.4769 26.1128 36.1951 29.0496C30.0399 33.2737 22.8321 37.8063 16.2547 41.2651C13.5012 42.7186 11.6279 42.6059 9.92707 37.0054Z"
        fill="url(#paint1_linear_488_1791)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2089 36.1927C10.1691 37.6759 10.4248 39.6752 12.0424 41.2948C16.9784 46.225 28.7476 48.4853 34.9146 48.5091C39.8566 48.5269 44.1444 46.9784 45.8393 41.9356C46.9455 38.631 47.4093 34.84 47.219 30.5803C46.9931 25.5612 45.3576 23.5203 40.1421 25.1696C33.9393 27.1274 14.7185 34.3654 12.2089 36.1927Z"
        fill="url(#paint2_linear_488_1791)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3637 48.9899C18.8037 49.0492 19.0892 48.8356 19.1546 48.6102C19.2379 48.3373 19.0357 47.9694 18.5242 47.7677C16.0562 46.8185 13.7369 45.4183 11.5424 43.8462C9.60964 42.4698 7.86716 40.9806 6.02953 39.5034C5.54188 39.1118 4.33463 38.1804 3.93618 39.0822C3.6983 39.628 3.71614 40.1857 3.91239 40.7433C4.22163 41.6154 4.97691 42.4816 5.92249 43.2766C8.72353 45.6557 14.7181 48.5212 18.3637 48.9899Z"
        fill="#0071BC"
      />
      <defs>
        <linearGradient
          id="paint0_linear_488_1791"
          x1="1.81755"
          y1="26.6892"
          x2="21.3532"
          y2="15.3832"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3743" stopColor="#75B0CA" />
          <stop offset="0.5402" stopColor="#60A1C8" />
          <stop offset="0.7514" stopColor="#408AC6" />
          <stop offset="0.8771" stopColor="#1282C3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_488_1791"
          x1="5.02012"
          y1="29.4819"
          x2="36.6687"
          y2="11.1656"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2116" stopColor="#9E1F63" />
          <stop offset="0.5274" stopColor="#D91C5C" />
          <stop offset="0.9892" stopColor="#ED4663" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_488_1791"
          x1="15.7491"
          y1="48.071"
          x2="48.0698"
          y2="29.3658"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDC60B" />
          <stop offset="0.4481" stopColor="#F28F3F" />
          <stop offset="0.7156" stopColor="#ED693C" />
          <stop offset="1" stopColor="#E83E39" />
        </linearGradient>
      </defs>
    </svg>
  );
};
