const LANG = {
  LANG_FEATURE: 'Features',
  LANG_PROMOTIONS: 'PROMOTIONS',
  LANG_ABOUT_US: 'About Us',
  LANG_CONTACT: 'CONTACT',
  LANG_SIGNUP: 'Signup',
  LANG_Login: 'Login',
  LANG_UPLOAD: 'Upload',
  LANG_CROP: 'Crop',
  LANG_BACK: 'Back',

  PAGES: {
    HOMEPAGE: {
      LANG_HOMEPAGE_NEXT_GEN: 'next-generation',
      LANG_HOMEPAGE_HEADING: 'professional platform',
      LANG_HOMEPAGE_OUR_PERPOSE:
        'Helping people find the best opportunities based on their truest and most authentic version of themselves.',
      LANG_HOMEPAGE_WE_ARE:
        'We believe the desire for meaning, connection, and collaboration is inherent in all of us. It is more than merely producing output. It is about discovering purpose, providing to society, and attaining fulfillment while remaining genuine to oneself.',
      LANG_HOMEPAGE_OUR_VIEW: 'Our View',
      LANG_HOMEPAGE_OUR_VIEW_LONG1:
        'We are targeting one of the most significant and complex problems in any organization: Human resources and human relations. Our view of the workforce is that it comprises people with different abilities, ambitions, and motivations. We trust that everyone reaps the benefits when we encourage people to express themselves fully, locate chances aligned with their values, interests, and skills, and connect with entities and individuals who share their purpose.',
      LANG_HOMEPAGE_OUR_VIEW_LONG2:
        'We are not only a platform; we are a revolution. Our movement fosters genuineness, inclusivity, and development, empowering people and companies to locate their compass and build meaningful relationships.',
      LANG_HOMEPAGE_JOIN_US:
        'Join us, and let us shift work from merely being a source of pay to becoming a fulfilling experience.',
      LANG_HOMEPAGE_HOW_WORK: 'How does PurDriven work?',
      LANG_HOMEPAGE_WONDERING:
        'Wondering how our platform works? We depend on fundamentals not limited to expansion, depth, and potential. We provide a comprehensive way to find the unique person you are looking for.',
      LANG_HOMEPAGE_EXPANSION_LEFT: 'Work ',
      LANG_HOMEPAGE_EXPANSION_RIGHT: 'Expansion',
      LANG_HOMEPAGE_EXPANSION_PARA:
        'Purdriven allows you to showcase all your talents in one place, offering you an increased chance of discovering a job or exploring exciting opportunities by combining your skills. Research indicates that employees who utilize strengths at work are more engaged, satisfied, and motivated to invest in their roles. We carefully designed our platform to cater to a significant segment of the workforce that often requires more than one platform to exhibit their work and many abilities.',
      LANG_HOMEPAGE_DEPTH_LEFT: 'Myself ',
      LANG_HOMEPAGE_DEPTH_RIGHT: 'Depth',
      LANG_HOMEPAGE_DEPTH_PARA:
        'Understanding oneself is crucial when it comes to finding a fulfilling career. Our search filters go beyond skills by encompassing elements empowering individuals to find the perfect match for their growth and fulfillment. You alone know what brings you a sense of purpose and meaning. We encourage you to find how to leverage your unique personality and attributes to make meaningful contributions.',
      LANG_HOMEPAGE_POTENTIAL_LEFT: 'Interests ',
      LANG_HOMEPAGE_POTENTIAL_RIGHT: 'Potential',
      LANG_HOMEPAGE_POTENTIAL_PARA:
        'Creativity has become a valued attribute of job candidates today. Many entrepreneurs and founders created successful businesses from their interests and passions. At PurDriven, we actively encourage people to share their interests igniting the potential for business ventures or opportunities.',
      LANG_HOMEPAGE_WE_AIM: 'We Aim to',
      LANG_HOMEPAGE_WE_HIGHLIGHT:
        'Highlight your unique skills, personality, interests, and experiences to find a job that best fits you.',
      LANG_HOMEPAGE_WE_STREAMLINE:
        'Streamline the hiring process, providing deeper transparency and multidimensional awareness to employers.',
      LANG_HOMEPAGE_WE_FOSTER:
        'Foster meaningful connections with other professionals and find people with the same goals and objectives in your working environment.',
      LANG_HOMEPAGE_WE_REALIGN:
        'Realign your career path by helping you define your strengths and weaknesses to make the most out of your professional life.',
      LANG_HOMEPAGE_WE_UNLEASH:
        'Unleash creativity which is a highly valued asset among job candidates. Connect with your interests, passions and hobbies to create a business or a unique opportunity.',
      LANG_HOMEPAGE_AUTHENTIC:
        'Helping raise awareness of who we are and how we can best contribute and collaborate while remaining genuine to ourselves.',
      LANG_HOMEPAGE_QUICK_LINKS: 'Quick Links',
      LANG_HOMEPAGE_HOME: 'Home',
      LANG_HOMEPAGE_CONTACT: 'Contact Us',
      LANG_HOMEPAGE_TERMS: 'Terms & Conditions',
      LANG_HOMEPAGE_PRIVACY: 'Privacy Policy',
      LANG_HOMEPAGE_CONNECT: 'Connect with us',
      LANG_HOMEPAGE_RIGHTS: ' 2024, PurDriven. All Rights Reserved.',
    },
    AUTH_COMMON: {
      LANG_AUTH_PANEL_LAYOUT_HEADING:
        'A professional platform helping raise awareness of who we are and how we can best contribute and collaborate while remaining genuine to ourselves.',
    },
    LOGIN: {
      LANG_LOGIN_WELCOME_HEADING: 'Welcome Back',
      LANG_LOGIN_WELCOME_SUBHEADING: 'Let’s build something great together!',
      LANG_LOGIN_EMAIL_LABEL: 'Email address',
      LANG_LOGIN_EMAIL_PLACEHOLDER: 'Enter Email',
      LANG_LOGIN_PASSWORD_LABEL: 'Password',
      LANG_LOGIN_PASSWORD_PLACEHOLDER: 'Enter Password',
      LANG_LOGIN_FORGOT_PWD: 'Forgot Password?',
      LANG_LOGIN_DONT_HAVE_ACC: 'Don’t have an account?',
      LANG_LOGIN_SIGN_UP: 'Sign Up',
    },
    SIGNUP: {
      LANG_SIGNUP_WELCOME_HEADING: 'Create An Account',
      LANG_SIGNUP_WELCOME_SUBHEADING:
        'Share your talents, interests and personality and find the right opportunities for you.',
      LANG_SIGNUP_FIRST_NAME_LABEL: 'First Name',
      LANG_SIGNUP_FIRST_NAME_PLACEHOLDER: 'Enter First name',
      LANG_SIGNUP_LAST_NAME_LABEL: 'Last Name',
      LANG_SIGNUP_LAST_NAME_PLACEHOLDER: 'Enter Last name',
      LANG_SIGNUP_EMAIL_LABEL: 'Email address',
      LANG_SIGNUP_EMAIL_PLACEHOLDER: 'Enter Email',
      LANG_SIGNUP_PASSWORD_LABEL: 'Enter Password',
      LANG_SIGNUP_PASSWORD_PLACEHOLDER: 'Enter Password',
      LANG_SIGNUP_FORGOT_PWD: 'Forgot Password?',
      LANG_SIGNUP_REGISTER: 'By registering I agree to all',
      LANG_SIGNUP_PRIVACY: 'Privacy Policy',
      LANG_SIGNUP_TERMS: 'Terms & Conditions',
      LANG_SIGNUP_SAVE_NEXT: 'Save & Next',
      LANG_SIGNUP_HAVE_ACC: 'Already have an account? ',
      LANG_SIGNUP_SIGN_IN: 'LogIn',
      LANG_SIGNUP_CODE_SENT: 'Code sent successfully',
      LANG_SIGNUP_CODE_SENT_MAIL: '4 digit verification code have been sent to your email ',
    },
    VERIFY_EMAIL: {
      LANG_VERIFY_EMAIL: 'Verify Email',
      LANG_CODE_EMAIL: 'Enter the code sent to',
      LANG_OTP_EMAIL: 'Enter OTP sent to',
      LANG_VER_CODE: 'Verification Code ',
      LANG_OTP: 'OTP',
      LANG_RESEND: 'Resend',
    },
    GEN_INFO: {
      LANG_GEN_INFO: 'General Information',
      LANG_PROVIDE_INFO:
        'Please provide your location. It helps us match you with the right opportunities.',
      LANG_LOCATION: 'Location',
      LANG_LOCATION_PLACEHOLDER: 'Enter Location',
      LANG_SKIP: 'Skip for Now',
      LANG_PROCEED: 'Proceed',
      LANG_NEXT: 'Next',
    },
    FORGOT_PASSWORD: {
      LANG_GEN_WELCOME_HEADING: 'Forgot Password',
      LANG_GEN_WELCOME_SUBHEADING: 'Enter your registered email below to receive code',
      LANG_EMAIL_LABEL: 'Email address',
      LANG_EMAIL_PLACEHOLDER: 'Enter Email',
      LANG_OTP_SENT_SUCCESS: 'OTP sent successfully',
      LANG_OTP_SENT_TO_MAIL: 'OTP have been sent to your email',
    },
    RESET_PASSWORD: {
      LANG_GEN_WELCOME_HEADING: 'Reset Password',
      LANG_GEN_WELCOME_SUBHEADING: 'Enter your new password',
      LANG_PWD_LABEL: 'New Password',
      LANG_C_PWD_LABEL: 'Confirm Password',
      LANG_PWD_PLACEHOLDER: 'Enter Password',
      LANG_C_PWD_PLACEHOLDER: 'Re-Enter Password',
    },
    FEED: {
      LANG_WRITE_SOMETHING: 'Write something ...',
      LANG_CREATE_POST: 'Create Post',
      LANG_EDIT_POST: 'Edit Post',
    },
    CREATE_POST: {
      LANG_TEXT_AREA_PLACEHOLDER: 'Share a content, media or link',
      LANG_TEXT_AREA_PLACEHOLDER_EDIT: 'Share a content',
      LANG_URL: 'URL',
      LANG_HTTPS: 'https://',
      LANG_ADD_NEW: '+ Add New',
    },
  },
};
const BUTTON_LABELS = {
  BTNLBL_SIGNUP: 'Sign Up',
  BTNLBL_JOIN_PUR: 'Join PurDriven',
  BTNLBL_LOGIN: 'Login',
  BTNLBL_SUBMIT: 'Submit',
  BTNLBL_VERIFY: 'Verify',
  BTNLBL_CONTINUE: 'Continue',
  BTNLBL_SAVE: 'Save',
  BTNLBL_PHOTO: 'Photo',
  BTNLBL_VIDEO: 'Video',
  BTNLBL_LINK: 'Link',
  BTNLBL_FOLLOW: 'Follow',
  BTNLBL_UNFOLLOW: 'Unfollow',
  BTNLBL_INVITE_PEOPLE: 'Invite People',
  BTNLBL_SEND_INVITE: 'Send Invite',
  BTNLBL_FRIEND_REQUESTS: 'Friend Requests',
  BTNLBL_REJECT: 'Reject',
  BTNLBL_ACCEPT: 'Accept',
};

const TABS_NAME = {
  FOLLOWERS: 'Followers',
  FOLLOWING: 'Following',
  CONNECTIONS: 'Connections',
  BTNLBL_UPDATE: 'Update',
};

const DROPDOWN_OPTION_LABELS = {
  DDLBL_LOGOUT: 'Log out',
  DDLBL_MY_PROFILE: 'My Profile',
};

const PRIVATE_NAVIGATION_LABELS = {
  NAVLBL_FEED: 'Feed',
  NAVLBL_MY_NETWORK: 'My Network',
  NAVLBL_MESSAGES: 'Messages',
  NAVLBL_NOTIFICATIONS: 'Notifications',
  NAVLBL_SETTINGS: 'Settings',
};

export { LANG, BUTTON_LABELS, DROPDOWN_OPTION_LABELS, PRIVATE_NAVIGATION_LABELS, TABS_NAME };
