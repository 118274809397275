const SearchIcon = ({ width = 16, height = 17, color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.82567 1.86719C11.4057 1.86719 14.3177 4.77919 14.3177 8.35919C14.3177 10.0482 13.6695 11.5887 12.6088 12.7448L14.6959 14.8277C14.8913 15.023 14.8919 15.339 14.6966 15.5343C14.5993 15.633 14.4706 15.6817 14.3426 15.6817C14.2153 15.6817 14.0873 15.633 13.9893 15.5357L11.8769 13.4292C10.7657 14.3191 9.35679 14.8519 7.82567 14.8519C4.24567 14.8519 1.33301 11.9392 1.33301 8.35919C1.33301 4.77919 4.24567 1.86719 7.82567 1.86719ZM7.82567 2.86719C4.79701 2.86719 2.33301 5.33052 2.33301 8.35919C2.33301 11.3879 4.79701 13.8519 7.82567 13.8519C10.8537 13.8519 13.3177 11.3879 13.3177 8.35919C13.3177 5.33052 10.8537 2.86719 7.82567 2.86719Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;
