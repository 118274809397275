import React, { useEffect, useState } from 'react';
import RadioButton from './RadioButton';

const RadioButtonsGroup = ({
  options,
  name,
  defaultValue,
  onSelect,
  className = 'flex-col',
  showPartitionBelowOptions = false,
  optionsClass = '',
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onSelect(
      name,
      event.target.value,
      event?.type === 'click' ? true : event.target.value !== defaultValue,
    );
  };

  return (
    <div className={`flex gap-2 ${className}`}>
      {options.map((option) => (
        <React.Fragment key={option}>
          <RadioButton
            key={option.value}
            label={option.label}
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={handleChange}
            onClick={handleChange}
            labelClass={optionsClass}
          />
          {showPartitionBelowOptions ? (
            <hr className="pb-8" style={{ color: 'rgba(161, 160, 160, 0.50)' }} />
          ) : (
            ''
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default RadioButtonsGroup;
