import React from 'react';

const RightChevron = ({ fill = 'black', width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="fluent:ios-arrow-24-filled">
        <path
          id="Vector"
          d="M9.39356 3.07206C9.31838 2.9925 9.25961 2.89891 9.22059 2.79664C9.18158 2.69436 9.16309 2.5854 9.16619 2.47599C9.16928 2.36657 9.1939 2.25883 9.23863 2.15893C9.28337 2.05902 9.34734 1.9689 9.4269 1.89372C9.50646 1.81854 9.60004 1.75977 9.70232 1.72075C9.80459 1.68174 9.91355 1.66325 10.023 1.66635C10.1324 1.66944 10.2401 1.69406 10.34 1.73879C10.4399 1.78353 10.53 1.8475 10.6052 1.92706L17.6886 9.42706C17.8348 9.58178 17.9164 9.78663 17.9164 9.99956C17.9164 10.2125 17.8348 10.4173 17.6886 10.5721L10.6052 18.0729C10.5305 18.1542 10.4404 18.2198 10.3402 18.266C10.2399 18.3122 10.1315 18.338 10.0211 18.3419C9.9108 18.3459 9.80079 18.3278 9.69749 18.2889C9.59419 18.2499 9.49967 18.1908 9.4194 18.115C9.33913 18.0392 9.27473 17.9482 9.22992 17.8473C9.18511 17.7464 9.1608 17.6376 9.1584 17.5273C9.156 17.4169 9.17555 17.3072 9.21592 17.2044C9.25629 17.1016 9.31667 17.0079 9.39356 16.9287L15.9369 9.99956L9.39356 3.07206Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default RightChevron;
