import React from 'react';

const DocumentIcon = ({ fill = 'white' }) => {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.944 0.25H11.056C12.894 0.25 14.35 0.25 15.489 0.403C16.661 0.561 17.61 0.893 18.359 1.641C19.107 2.39 19.439 3.339 19.597 4.511C19.75 5.651 19.75 7.106 19.75 8.944V13.056C19.75 14.894 19.75 16.35 19.597 17.489C19.439 18.661 19.107 19.61 18.359 20.359C17.61 21.107 16.661 21.439 15.489 21.597C14.349 21.75 12.894 21.75 11.056 21.75H8.944C7.106 21.75 5.65 21.75 4.511 21.597C3.339 21.439 2.39 21.107 1.641 20.359C0.893 19.61 0.561 18.661 0.403 17.489C0.25 16.349 0.25 14.894 0.25 13.056V8.944C0.25 7.106 0.25 5.65 0.403 4.511C0.561 3.339 0.893 2.39 1.641 1.641C2.39 0.893 3.339 0.561 4.511 0.403C5.651 0.25 7.106 0.25 8.944 0.25ZM4.71 1.89C3.704 2.025 3.124 2.279 2.7 2.702C2.278 3.125 2.024 3.705 1.889 4.711C1.751 5.739 1.749 7.093 1.749 9V13C1.749 14.907 1.751 16.262 1.889 17.29C2.024 18.295 2.278 18.875 2.701 19.298C3.124 19.721 3.704 19.975 4.71 20.11C5.738 20.248 7.092 20.25 8.999 20.25H10.999C12.906 20.25 14.261 20.248 15.289 20.11C16.294 19.975 16.874 19.721 17.297 19.298C17.72 18.875 17.974 18.295 18.109 17.289C18.247 16.262 18.249 14.907 18.249 13V9C18.249 7.093 18.247 5.739 18.109 4.71C17.974 3.705 17.72 3.125 17.297 2.702C16.874 2.279 16.294 2.025 15.288 1.89C14.261 1.752 12.906 1.75 10.999 1.75H8.999C7.092 1.75 5.739 1.752 4.71 1.89ZM5.25 9C5.25 8.80109 5.32902 8.61032 5.46967 8.46967C5.61032 8.32902 5.80109 8.25 6 8.25H14C14.1989 8.25 14.3897 8.32902 14.5303 8.46967C14.671 8.61032 14.75 8.80109 14.75 9C14.75 9.19891 14.671 9.38968 14.5303 9.53033C14.3897 9.67098 14.1989 9.75 14 9.75H6C5.80109 9.75 5.61032 9.67098 5.46967 9.53033C5.32902 9.38968 5.25 9.19891 5.25 9ZM5.25 13C5.25 12.8011 5.32902 12.6103 5.46967 12.4697C5.61032 12.329 5.80109 12.25 6 12.25H11C11.1989 12.25 11.3897 12.329 11.5303 12.4697C11.671 12.6103 11.75 12.8011 11.75 13C11.75 13.1989 11.671 13.3897 11.5303 13.5303C11.3897 13.671 11.1989 13.75 11 13.75H6C5.80109 13.75 5.61032 13.671 5.46967 13.5303C5.32902 13.3897 5.25 13.1989 5.25 13Z"
        fill={fill}
      />
    </svg>
  );
};

export default DocumentIcon;
