import React, { useEffect, useState } from 'react';
import { Divider, SharedLink, MediaMsg, TextMsg, Timestamp, Document, VideoMsg } from './chatTypes';
import cross from '../../assets/images/cross.svg';
import documentAttachment from '../../assets/images/document-attachment.svg';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import db from '../../firebase';
import { useSelector } from 'react-redux';

const chats = (props) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [dataType, setDataType] = useState('');
  const [data, setData] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [videoSrc, setVideoSrc] = useState('');
  const [messages, setMessages] = useState([]);
  const [textSeen, setTextSeen] = useState();
  const [finalMessages, setFinalMessages] = useState([]);
  const [unreadMessages, setUnreadMeassages] = useState(false);
  const [medialength, setMedialength] = useState(0);

  const dbName = process.env.REACT_APP_ENV === 'dev' ? 'test_messages' : 'uat_messages';
  const myProfile = useSelector((state) => state.auth.user);
  const fetchData = async () => {
    try {
      const q = query(
        collection(db, dbName, props.retrievedDocumentId, props.retrievedDocumentId),
        orderBy('timestamp'),
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let messagesData = [];
        querySnapshot.forEach((doc) => {
          messagesData.push({ ...doc.data(), id: doc.id });
        });
        const groupedMessages = groupMessagesByDate(messagesData);
        setMessages(groupedMessages);
        setMessages(messagesData);
        const lastMessageGroup = groupedMessages[groupedMessages?.length - 1];
        const lastMessage = lastMessageGroup?.messages[lastMessageGroup?.messages.length - 1];
        const hasUnread = lastMessage && !lastMessage?.read;
        setUnreadMeassages(hasUnread);
      });
      return unsubscribe;
    } catch (error) {
      console.error('Error getting messages: ', error);
      setTextSeen(true);
    }
  };
  const groupMessagesByDate = (messages) => {
    const groupedMessages = [];
    let currentGroup = [];
    let currentDate = null;

    messages.forEach((message) => {
      if (message?.timestampByDate) {
        const timestamp = new Date(message?.timestampByDate);
        const messageDate = timestamp.toDateString();
        if (currentDate === null || currentDate !== messageDate) {
          if (currentGroup.length > 0) {
            groupedMessages.push({
              date: currentDate,
              messages: currentGroup,
            });
            currentGroup = [];
          }
          currentDate = messageDate;
        }
        currentGroup.push(message);
      }
    });

    if (currentGroup.length > 0) {
      groupedMessages.push({
        date: currentDate,
        messages: currentGroup,
      });
    }
    setFinalMessages(groupedMessages);
    return groupedMessages;
  };
  const handleFunctionInInputTextarea = () => {
    props.onCrossIconClick();
  };

  useEffect(() => {
    let unsubscribeFunction;
    const fetchDataAndSubscribe = async () => {
      unsubscribeFunction = await fetchData();
    };
    fetchDataAndSubscribe();
    return () => {
      if (unsubscribeFunction) {
        unsubscribeFunction();
      }
    };
  }, [props.retrievedDocumentId]);
  useEffect(() => {
    setMedialength(props.mediaLength);
  }, [props.mediaLength]);
  useEffect(() => {
    if (medialength === 0) {
      props.scrollChatContainer();
    } else {
      const timeoutId = setTimeout(() => {
        props.scrollChatContainer();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [messages]);

  useEffect(() => {
    let fileData = props.fileData;
    if (fileData && fileData?.length > 0) {
      const selectedType = props.fileData[0]?.type.split('/')[0];
      if (selectedType === 'image') {
        setDataType(1);
        const reader = new FileReader();
        reader.onload = (event) => {
          setImageSrc(event.target.result);
        };

        reader.readAsDataURL(props.fileData[0]);
      } else if (selectedType === 'video') {
        setDataType(2);
        const reader = new FileReader();
        reader.onload = (event) => {
          setVideoSrc(event.target.result);
        };

        reader.readAsDataURL(props.fileData[0]);
      } else {
        setData(props.fileData);
        setDataType(3);
      }
      setOpenPopup(true);
    }
  }, [props.fileData]);
  useEffect(() => {
    if (props.attachmentProgress === 100) {
      setOpenPopup(false);
    }
  }, [props.attachmentProgress]);
  const handleImgClick = () => {
    if (props.attachmentProgress === 0) {
      setOpenPopup(false);
      handleFunctionInInputTextarea();
    }
  };

  return (
    <div className="h-[97%]">
      {openPopup ? (
        <div className="h-full overflow-hidden">
          <div className="pt-2 pr-4 pl-4 pb-2 h-full">
            <div className="flex justify-between">
              <div></div>
              {dataType === 3 ? <div>Document</div> : ''}
              <img
                src={cross}
                alt=""
                className={`cursor-pointer ${props.attachmentProgress > 0 ? 'disabled' : ''}`}
                onClick={() => {
                  handleImgClick();
                }}
              />
            </div>
            <div className="flex flex-col items-center justify-center w-full h-[90%]">
              {dataType === 3 ? (
                <>
                  <img src={documentAttachment} alt="" />
                  <div>{data[0]?.name}</div>
                  <div>{Math.floor(data[0].size / 1024)} KB · PDF</div>
                </>
              ) : dataType === 1 ? (
                <img src={imageSrc} alt="" className="max-w-[80%] max-h-[90%]" />
              ) : (
                <video src={videoSrc} controls className="max-w-[80%] max-h-[90%]" />
              )}
            </div>
            <div className="w-[80%] bg-gray rounded-full h-2 mx-auto">
              <div
                className="bg-blueprimary h-2 rounded-full"
                style={{
                  width: `${props.attachmentProgress}%`,
                  transition: 'width 0.5s ease-in-out',
                }}
              ></div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ margin: '10px 0px' }}>
          {finalMessages?.map((dates, index) => {
            let isFirstUnreadMessage = false;
            return (
              <React.Fragment key={dates?.id}>
                <Timestamp element={dates?.date} key={index} />

                {dates?.messages?.map((element) => {
                  if (element?.read === false && !isFirstUnreadMessage) {
                    isFirstUnreadMessage = true;
                  } else if (isFirstUnreadMessage) {
                    isFirstUnreadMessage = false;
                  }
                  switch (element?.type) {
                    case 'divider':
                      return <Timestamp key={index} element={element} />;
                    case 'msg':
                      switch (element?.subType) {
                        case 'img':
                          return (
                            <MediaMsg
                              key={element?.id}
                              element={element}
                              selected={props.selected}
                              contacts={props.contacts}
                              isActive={props.isActive}
                            />
                          );
                        case 'doc':
                          return (
                            <Document
                              key={element?.id}
                              element={element}
                              selected={props.selected}
                              contacts={props.contacts}
                              isActive={props.isActive}
                            />
                          );
                        case 'link':
                          return <SharedLink key={element?.id} element={element} />;
                        case 'video':
                          return (
                            <VideoMsg
                              key={element?.id}
                              element={element}
                              selected={props.selected}
                              contacts={props.contacts}
                              isActive={props.isActive}
                            />
                          );
                        case 'reply':
                          // reply
                          break;
                        default:
                          return (
                            <>
                              {isFirstUnreadMessage
                                ? myProfile?.id !==
                                    finalMessages?.[finalMessages?.length - 1]?.messages?.[
                                      finalMessages?.[finalMessages?.length - 1]?.messages?.length -
                                        1
                                    ]?.idFrom &&
                                  unreadMessages && <Divider key={index} />
                                : ''}
                              <TextMsg
                                key={element?.id}
                                element={element}
                                selected={props.selected}
                                textSeen={textSeen}
                                contacts={props.contacts}
                                isActive={props.isActive}
                              />
                            </>
                          );
                      }
                      break;

                    default:
                      return <React.Fragment key={element?.type}></React.Fragment>;
                  }
                })}
              </React.Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default chats;
