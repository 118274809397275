export const TabLogo = () => {
  return (
    <svg
      width="113"
      height="30"
      viewBox="0 0 113 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.9552 17.7314C44.7296 17.7314 46.5279 18.6758 46.5279 21.1276C46.5279 23.7139 44.388 24.6074 41.82 24.6074H40.1569V28.4359C40.1569 29.4312 39.4361 29.6128 39.0794 29.6128C38.719 29.6128 38.002 29.4312 38.002 28.4359V18.9773C38.002 18.1309 38.4149 17.735 39.2859 17.735H41.9552V17.7314ZM40.1569 22.8494H41.6624C43.0665 22.8494 44.3504 22.5188 44.3504 21.1276C44.3504 19.7364 43.0665 19.4858 41.73 19.4858H40.1532V22.8494H40.1569Z"
        fill="#0071BC"
      />
      <path
        d="M54.9221 28.5666C54.9221 29.4602 54.2539 29.6091 53.946 29.6091C53.6382 29.6091 52.9699 29.4602 52.9699 28.5666V28.1852H52.9361C52.5757 28.9807 51.5996 29.6926 50.3832 29.6926C48.2433 29.6926 47.3535 28.1017 47.3535 26.478V22.4679C47.3535 21.5235 48.0405 21.3564 48.3822 21.3564C48.7238 21.3564 49.4109 21.5235 49.4109 22.4679V25.915C49.4109 26.8594 49.6324 28.0363 50.9539 28.0363C52.2566 28.0363 52.8723 26.943 52.8723 25.8823V22.4679C52.8723 21.5235 53.5556 21.3564 53.901 21.3564C54.2426 21.3564 54.9296 21.5235 54.9296 22.4679V28.5666H54.9221Z"
        fill="#0071BC"
      />
      <path
        d="M58.3138 29.6087C57.9722 29.6087 57.2852 29.4416 57.2852 28.4972V22.4166C57.2852 21.523 57.9534 21.3559 58.2613 21.3559C58.5879 21.3559 59.2562 21.523 59.2562 22.4166V22.8307H59.2899C59.6166 21.9698 60.4387 21.2724 61.4299 21.2724C62.0456 21.2724 62.4923 21.5703 62.4923 22.1514C62.4923 22.6636 62.1657 23.1104 61.3773 23.1104C60.0746 23.1104 59.3387 24.0548 59.3387 25.3806V28.4972C59.3425 29.4452 58.6592 29.6087 58.3138 29.6087Z"
        fill="#0071BC"
      />
      <path
        d="M64.8045 29.4632C63.9297 29.4632 63.5205 29.0636 63.5205 28.2209V18.9766C63.5205 18.1302 63.9335 17.7343 64.8045 17.7343H67.8191C70.9878 17.7343 74.5168 19.1909 74.5168 23.6006C74.5168 27.7088 70.9878 29.4668 67.8191 29.4668H64.8045V29.4632ZM65.6792 27.6398H67.4775C69.7038 27.6398 72.2041 26.7607 72.2041 23.5969C72.2041 20.2334 69.7038 19.5214 67.4775 19.5214H65.6792V27.6398Z"
        fill="#0071BC"
      />
      <path
        d="M77.43 29.6087C77.0884 29.6087 76.4014 29.4416 76.4014 28.4972V22.4166C76.4014 21.523 77.0696 21.3559 77.3775 21.3559C77.7041 21.3559 78.3724 21.523 78.3724 22.4166V22.8307H78.4061C78.7328 21.9698 79.5549 21.2724 80.5461 21.2724C81.1618 21.2724 81.6085 21.5703 81.6085 22.1514C81.6085 22.6636 81.2819 23.1104 80.4935 23.1104C79.1908 23.1104 78.455 24.0548 78.455 25.3806V28.4972C78.455 29.4452 77.7717 29.6087 77.43 29.6087Z"
        fill="#0071BC"
      />
      <path
        d="M83.5752 20.0016C82.8206 20.0016 82.2725 19.4386 82.2725 18.8102C82.2725 18.1818 82.8206 17.6152 83.5752 17.6152C84.3298 17.6152 84.9117 18.131 84.9117 18.8102C84.9117 19.4858 84.3298 20.0016 83.5752 20.0016ZM83.594 29.6092C83.2523 29.6092 82.5653 29.4421 82.5653 28.4977V22.4644C82.5653 21.52 83.2523 21.3529 83.594 21.3529C83.9356 21.3529 84.6226 21.52 84.6226 22.4644V28.4977C84.6226 29.4458 83.9356 29.6092 83.594 29.6092Z"
        fill="#0071BC"
      />
      <path
        d="M90.205 29.6091C89.7095 29.6091 89.1088 29.4093 88.7146 28.5158L86.2142 22.8638C86.1129 22.6313 86.0791 22.4497 86.0791 22.3008C86.0791 21.7559 86.5221 21.3564 87.1228 21.3564C87.4457 21.3564 87.8586 21.4726 88.1327 22.17L90.1863 27.3062H90.2201L92.3262 22.1555C92.6003 21.4763 93.0095 21.36 93.3211 21.36C93.9218 21.36 94.331 21.7596 94.331 22.2717C94.331 22.3734 94.3122 22.5696 94.1921 22.8348L91.6917 28.5194C91.3013 29.413 90.6856 29.6091 90.205 29.6091Z"
        fill="#0071BC"
      />
      <path
        d="M97.2784 26.1144C97.3648 27.2404 98.4272 28.0686 99.6249 28.0686C100.481 28.0686 100.995 27.8034 101.51 27.3894C101.802 27.1569 101.99 27.106 102.212 27.106C102.673 27.106 103.034 27.4184 103.034 27.9015C103.034 28.0686 102.966 28.3628 102.692 28.6135C101.889 29.3581 100.961 29.6886 99.6436 29.6886C97.1095 29.6886 95.2249 28.1158 95.2249 25.5296C95.2249 22.9615 97.0907 21.2725 99.5761 21.2725C101.562 21.2725 103.173 22.3658 103.499 24.7014C103.514 24.8685 103.533 24.9847 103.533 25.1155C103.533 25.8129 103.139 26.1108 102.456 26.1108H97.2784V26.1144ZM101.494 24.6869C101.494 23.6589 100.894 22.7654 99.5573 22.7654C98.3409 22.7654 97.3648 23.5754 97.2784 24.6869H101.494Z"
        fill="#0071BC"
      />
      <path
        d="M105.431 22.4023C105.431 21.5088 106.1 21.3598 106.407 21.3598C106.715 21.3598 107.384 21.5088 107.384 22.4023V22.7837H107.417C107.778 21.9882 108.754 21.2763 109.97 21.2763C112.11 21.2763 113 22.8673 113 24.4909V28.5011C113 29.4455 112.317 29.6126 111.971 29.6126C111.63 29.6126 110.943 29.4455 110.943 28.5011V25.054C110.943 24.1095 110.721 22.9327 109.4 22.9327C108.101 22.9327 107.481 24.026 107.481 25.0866V28.5011C107.481 29.4455 106.798 29.6126 106.452 29.6126C106.111 29.6126 105.424 29.4455 105.424 28.5011V22.4023H105.431Z"
        fill="#0071BC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.15744 23.9856C4.04378 21.5192 2.32057 18.5661 1.23934 15.4241C0.1093 12.1368 -0.318686 8.63887 0.251964 5.27894C0.623637 3.09226 1.73115 0.462432 4.50931 2.3331C5.8158 3.21213 7.0472 4.94476 8.03833 6.02357C9.17587 7.26221 10.5762 8.29016 12.2394 9.10745C14.9575 10.4442 15.2015 12.2422 14.2854 14.8902C13.3206 17.6689 12.3107 20.4404 11.3534 23.2301C10.2533 26.4302 8.20352 26.3757 6.15744 23.9856Z"
        fill="url(#paint0_linear_1353_3251)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26607 22.6567C5.1961 19.2459 5.09849 15.6753 5.89815 12.3009C6.72785 8.80289 8.51864 5.51198 11.1954 2.82403C14.4954 -0.492308 19.5787 -1.7491 21.0204 3.90285C21.347 5.19597 21.8013 6.45639 22.3719 7.6587C23.0252 9.04626 23.8361 10.3648 24.7897 11.5889C27.0723 14.5239 25.5518 15.9877 22.8487 17.7857C18.963 20.372 14.4128 23.1471 10.2606 25.2647C8.52239 26.1547 7.33979 26.0857 6.26607 22.6567Z"
        fill="url(#paint1_linear_1353_3251)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70856 22.1598C6.42084 23.0679 6.58228 24.292 7.60344 25.2837C10.7195 28.3021 18.1492 29.6861 22.0424 29.7006C25.1622 29.7115 27.869 28.7635 28.939 25.6759C29.6373 23.6527 29.9301 21.3316 29.81 18.7236C29.6673 15.6506 28.6349 14.4011 25.3424 15.4109C21.4267 16.6096 9.29286 21.0411 7.70856 22.1598Z"
        fill="url(#paint2_linear_1353_3251)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5922 29.9936C11.87 30.0299 12.0502 29.8991 12.0915 29.7611C12.1441 29.594 12.0164 29.3688 11.6935 29.2453C10.1355 28.6641 8.67135 27.8069 7.28603 26.8443C6.06589 26.0016 4.96589 25.0899 3.80582 24.1854C3.49797 23.9457 2.73585 23.3754 2.48431 23.9275C2.33414 24.2617 2.3454 24.6031 2.46929 24.9446C2.66451 25.4785 3.14131 26.0089 3.73824 26.4956C5.5065 27.9522 9.29081 29.7066 11.5922 29.9936Z"
        fill="#0071BC"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1353_3251"
          x1="1.1474"
          y1="16.3399"
          x2="13.2877"
          y2="9.09545"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3743" stopColor="#75B0CA" />
          <stop offset="0.5402" stopColor="#60A1C8" />
          <stop offset="0.7514" stopColor="#408AC6" />
          <stop offset="0.8771" stopColor="#1282C3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1353_3251"
          x1="3.16838"
          y1="18.0504"
          x2="22.8361"
          y2="6.31411"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2116" stopColor="#9E1F63" />
          <stop offset="0.5274" stopColor="#D91C5C" />
          <stop offset="0.9892" stopColor="#ED4663" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1353_3251"
          x1="9.94344"
          y1="29.4324"
          x2="30.0288"
          y2="17.4468"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDC60B" />
          <stop offset="0.4481" stopColor="#F28F3F" />
          <stop offset="0.7156" stopColor="#ED693C" />
          <stop offset="1" stopColor="#E83E39" />
        </linearGradient>
      </defs>
    </svg>
  );
};
