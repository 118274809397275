import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import MultiColorLoader from './components/common/MultiColorLoader';
import { logoutDispatcher, profileDispatcher } from './redux/dispatchers/authDispatcher';
import { privateRoutes, publicRoutes, signupRoutes } from './routes';
import { notificationListDispatcher } from './redux/dispatchers/notificationDispatcher';
import { LIMITS } from './constants/constants';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import db from './firebase';
import { updateUnreadCount } from './redux/slices/authSlice';

const App = () => {
  const dispatch = useDispatch();
  const myProfile = useSelector((state) => state.auth.user);
  const dbName = process.env.REACT_APP_ENV === 'dev' ? 'test_messages' : 'uat_messages';
  const { isAuthenticated = false, isOriginSignup = false } = useSelector(
    (state) => state.auth || {},
  );
  const { globalLoading = false } = useSelector((state) => state.auth || {});

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(profileDispatcher());
      dispatch(
        notificationListDispatcher({
          page: 1,
          limit: LIMITS.FETCH_UNREAD_NOTIFS_LIMIT,
        }),
      );
    } else {
      dispatch(logoutDispatcher());
    }
  }, [localStorage.getItem('token')]);

  useEffect(() => {
    // if there is token, then on every route change we need to find whether there is a notif or not
    if (localStorage.getItem('token')) {
      dispatch(
        notificationListDispatcher({
          page: 1,
          limit: LIMITS.FETCH_UNREAD_NOTIFS_LIMIT,
        }),
      );
    }
  }, [window.location?.pathname]);
  useEffect(() => {
    if (myProfile?.id !== undefined) {
      const fetchData = async () => {
        try {
          const q = query(collection(db, dbName), orderBy('timestamp'));
          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let messagesData = [];
            querySnapshot.forEach((doc) => {
              messagesData.push({ ...doc.data() });
            });
            const myConnection = messagesData.filter((item) =>
              item?.userIds?.includes(myProfile?.id),
            );
            const numberOfUnreadMessages = myConnection.filter(
              (item) =>
                item?.lastMessage?.content !== '' &&
                !item.read &&
                item?.lastMessage?.idFrom !== myProfile?.id,
            ).length;

            dispatch(updateUnreadCount(numberOfUnreadMessages));
          });

          return () => unsubscribe();
        } catch (error) {
          console.warn(error);
        }
      };
      fetchData();
    }
  }, [myProfile?.id, window.location?.pathname]);

  if (globalLoading) {
    return <MultiColorLoader />;
  }

  if (isOriginSignup) {
    // If the origin is signup, then we need to redirect the user to a signup page
    return <RouterProvider router={signupRoutes} fallbackElement={() => <MultiColorLoader />} />;
  } else if (isAuthenticated) {
    return <RouterProvider router={privateRoutes} fallbackElement={() => <MultiColorLoader />} />;
  } else if (isAuthenticated === false) {
    return <RouterProvider router={publicRoutes} fallbackElement={() => <MultiColorLoader />} />;
  } else {
    return <MultiColorLoader />;
  }
};

export default App;
