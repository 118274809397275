import React, { useState } from 'react';
import EditBlueIcon from '../Icons/EditBlueIcon';
import InterestVideoIcon from '../Icons/InterestVideoIcon';
import { LIMITS } from '../../constants/constants';
import PreviewMediaFile from '../PreviewMediaFile';
import { fetchUserInterestById } from '../../services/signup';
import { getErrorMessage, successStatus } from '../../common';
import { ToastNotifyError } from '../Toast/ToastNotify';
import Modal from '../Modal';
import InterestForm from '../../pages/Interests/InterestForm';
import SpinningLoader from '../common/SpinningLoader';

const InterestView = ({ interest = {}, isEditable = false, reloadData = () => {} }) => {
  const images = interest?.UserInterestMedia?.filter((media) => media?.type === 'image');
  const videos = interest?.UserInterestMedia?.filter((media) => media?.type === 'video');
  const [previewMedia, setPreviewMedia] = useState({ type: '', isOpen: false, fileSrc: '' });
  const [isLoading, setIsLoading] = useState({ edit: false });

  const [isInterestModalOpen, setIsInterestModalOpen] = useState(false);
  const [selectedInterestData, setSelectedInterestData] = useState({});

  const editModalOpenHandler = async () => {
    if (isLoading?.edit) {
      return;
    }

    setIsLoading({ ...isLoading, edit: true });
    const { status, data } = await fetchUserInterestById({
      id: interest?.id,
    });

    if (successStatus(status)) {
      setIsInterestModalOpen(true);
      setSelectedInterestData(data?.data);
    } else {
      const errormsg = getErrorMessage(data);
      if (errormsg) {
        ToastNotifyError(errormsg);
      }
    }
    setIsLoading({ ...isLoading, edit: false });
  };

  // This function creates image URLs from file objects
  const renderImages = () => {
    if (images?.length) {
      return (
        <div className="flex flex-col gap-3 pt-2 flex-wrap">
          <div className="font-medium text-greydark text-[10px] md:text-[14px] ">
            Photos ({images?.length}/{LIMITS.INTEREST_MAX_NUMBER_OF_IMAGES})
          </div>
          <div className="flex gap-3 pt-2 flex-wrap">
            {images?.map((file, index) => {
              return (
                <div
                  key={index}
                  className="relative max-h-[55px] h-[55px] w-[55px] max-w-[55px] cursor-pointer"
                  onClick={() =>
                    setPreviewMedia({ isOpen: true, fileSrc: file?.media, type: 'image' })
                  }
                >
                  <img
                    src={file?.media}
                    alt={file.media}
                    className="mb-3 max-h-[55px] h-[55px] w-[55px] max-w-[55px] rounded-md object-cover bg-[#b6ddff]"
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return '';
  };

  const renderVideos = () => {
    if (videos?.length) {
      return (
        <div className="flex flex-col gap-3 pt-2 flex-wrap">
          <div className="font-medium text-greydark text-[10px] md:text-[14px]">
            Videos ({videos?.length}/{LIMITS.INTEREST_MAX_NUMBER_OF_VIDEOS})
          </div>
          <div className="flex gap-3 pt-2 flex-wrap">
            {videos?.map((file, index) => {
              return (
                <div
                  key={index}
                  className="relative max-h-[55px] h-[55px] w-[55px] max-w-[55px] cursor-pointer"
                  onClick={() =>
                    setPreviewMedia({ isOpen: true, fileSrc: file?.media, type: 'video' })
                  }
                >
                  <video
                    src={file?.media}
                    className="mb-3 max-h-[55px] h-[55px] w-[55px] max-w-[55px] rounded-md object-cover"
                    controls={false}
                  />
                  <div className="rounded-md w-full h-full absolute top-0 left-0 bg-[#00000085] flex items-center justify-center">
                    <InterestVideoIcon />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return '';
  };

  return (
    <div className="pt-[32px]">
      <div className="flex items-center justify-between">
        <div className="py-[10px] px-[20px] rounded-r-lg flex flex-col gap-2 blue-white-gradient p-3 text-white w-fit text-[14px] md:text-[18px] font-semibold break-words max-w-[95%] lg:max-w-[90%]">
          {interest?.title}
        </div>
        {isEditable && (
          <div
            className="bg-iconBackground p-1 rounded w-fit mx-4 cursor-pointer"
            onClick={editModalOpenHandler}
          >
            {isLoading?.edit ? <SpinningLoader marginLeft="" marginRight="" /> : <EditBlueIcon />}
          </div>
        )}
      </div>
      <div className="p-4">{renderImages()}</div>
      <div className="p-4 pt-0">{renderVideos()}</div>

      <PreviewMediaFile {...previewMedia} onCloseHandler={() => setPreviewMedia({})} />

      <Modal
        isTitle={true}
        title={`${selectedInterestData?.id ? 'Edit' : 'Add'}  Interest`}
        isOpen={isInterestModalOpen}
        onClose={() => setIsInterestModalOpen(false)}
        width="max-w-[472px]"
        padding={0}
        titleClassNames="pl-0"
      >
        <div className="px-[18px]">
          <InterestForm
            reloadData={reloadData}
            type="small"
            closePopupHandler={() => setIsInterestModalOpen(false)}
            data={selectedInterestData}
          />
        </div>
      </Modal>
    </div>
  );
};

export default InterestView;
