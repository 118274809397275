export const MobileLogo = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.19129 23.9858C4.06601 21.5195 2.33332 18.5664 1.24615 15.4245C0.109901 12.1372 -0.320438 8.63933 0.253349 5.27944C0.627065 3.09279 1.74066 0.462998 4.5341 2.33364C5.84777 3.21266 7.08594 4.94527 8.08252 6.02406C9.22631 7.26268 10.6344 8.29063 12.3066 9.10789C15.0397 10.4446 15.285 12.2426 14.364 14.8905C13.3938 17.6692 12.3784 20.4407 11.4158 23.2303C10.3097 26.4304 8.24861 26.3759 6.19129 23.9858Z"
        fill="url(#paint0_linear_1353_8201)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.30029 22.6564C5.22444 19.2457 5.1263 15.6751 5.93035 12.3007C6.76461 8.80278 8.56524 5.51191 11.2568 2.824C14.5749 -0.492302 19.6861 -1.74908 21.1357 3.90279C21.4641 5.1959 21.9209 6.45631 22.4947 7.6586C23.1515 9.04614 23.9669 10.3647 24.9257 11.5888C27.2209 14.5237 25.692 15.9875 22.9741 17.7855C19.067 20.3717 14.4919 23.1468 10.3168 25.2644C8.56902 26.1543 7.37992 26.0853 6.30029 22.6564Z"
        fill="url(#paint1_linear_1353_8201)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.74974 22.1589C6.45494 23.067 6.61727 24.291 7.64404 25.2827C10.7772 28.3011 18.2478 29.685 22.1624 29.6996C25.2993 29.7105 28.021 28.7624 29.0969 25.675C29.799 23.6518 30.0934 21.3307 29.9726 18.7227C29.8292 15.6498 28.7911 14.4003 25.4805 15.41C21.5433 16.6087 9.34275 21.0401 7.74974 22.1589Z"
        fill="url(#paint2_linear_1353_8201)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6565 29.9938C11.9359 30.0302 12.1171 29.8994 12.1586 29.7614C12.2114 29.5943 12.0831 29.3691 11.7584 29.2456C10.1919 28.6644 8.71964 27.8072 7.3267 26.8446C6.09985 26.0019 4.99381 25.0902 3.82736 24.1858C3.51782 23.946 2.75151 23.3758 2.49859 23.9279C2.34759 24.2621 2.35892 24.6035 2.48349 24.9449C2.67978 25.4789 3.1592 26.0092 3.75941 26.4959C5.53739 27.9525 9.3425 29.7069 11.6565 29.9938Z"
        fill="#0071BC"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1353_8201"
          x1="1.1537"
          y1="16.3402"
          x2="13.3254"
          y2="9.03703"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3743" stopColor="#75B0CA" />
          <stop offset="0.5402" stopColor="#60A1C8" />
          <stop offset="0.7514" stopColor="#408AC6" />
          <stop offset="0.8771" stopColor="#1282C3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1353_8201"
          x1="3.18558"
          y1="18.0502"
          x2="22.9042"
          y2="6.21866"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2116" stopColor="#9E1F63" />
          <stop offset="0.5274" stopColor="#D91C5C" />
          <stop offset="0.9892" stopColor="#ED4663" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1353_8201"
          x1="9.99691"
          y1="29.4313"
          x2="30.1343"
          y2="17.3486"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDC60B" />
          <stop offset="0.4481" stopColor="#F28F3F" />
          <stop offset="0.7156" stopColor="#ED693C" />
          <stop offset="1" stopColor="#E83E39" />
        </linearGradient>
      </defs>
    </svg>
  );
};
