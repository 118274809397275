import { NavLink } from 'react-router-dom';
import { PRIVATE_NAVIGATION_LABELS } from '../../constants/lang';
import navigationItems from '../../constants/navigationItems';
import { useSelector } from 'react-redux';

const { NAVLBL_MESSAGES, NAVLBL_NOTIFICATIONS } = PRIVATE_NAVIGATION_LABELS;
const navbarItems = navigationItems;

const LeftNavigation = ({ activeTab = 0 }) => {
  const { hasNotifications = false, unreadCount = 0 } = useSelector((state) => state.auth) || {};

  return (
    <div className="flex flex-col py-4 gap-4 lg:gap-0 md:items-center">
      {navbarItems.map(({ label, navPath, icon }, _i) => {
        return (
          <NavBarItem
            label={label}
            key={label}
            isActive={_i === activeTab}
            Icon={icon}
            navPath={navPath}
            hasNotifications={hasNotifications}
            unreadCount={unreadCount}
          />
        );
      })}
    </div>
  );
};

export default LeftNavigation;

const NavBarItem = ({
  onClickHandler = () => {},
  label = '',
  isActive = false,
  navPath,
  Icon = () => <></>,
  hasNotifications = false,
  unreadCount = 0,
}) => (
  <NavLink to={navPath} replace={true} className="w-[-webkit-fill-available] w-full">
    <div
      className={`
      w-full p-1 lg:p-4 pr-[5px] md:pr-[8px] border-l-[3px] md:border-l-4 lg:border-l-[6px] box-border border-white cursor-pointer flex items-center justify-center lg:justify-normal gap-4 ${
        isActive ? 'active-nav-left-side-bar bg-whitelight' : ''
      }`}
      onClick={onClickHandler}
    >
      <div className="relative">
        <Icon isActive={isActive} />
        {label === NAVLBL_NOTIFICATIONS && hasNotifications ? (
          <div className=" !top-[-18px] !right-[-25px] dot-icon" />
        ) : (
          ''
        )}
        {label === NAVLBL_MESSAGES && unreadCount > 0 ? (
          <div className=" !top-[-30px] !right-[-35px] dot-icon" />
        ) : (
          ''
        )}
      </div>

      <p className="text-sm font-semibold hidden lg:block relative">{label} </p>
    </div>
  </NavLink>
);
