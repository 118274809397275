import React, { useState } from 'react';
import ProfileLayout from '../../components/ProfileLayout';
import SidebarSearch from '../../components/FilterComponent';
import InnerSectionLayout from '../../components/PrivateLayout/InnerSectionLayout';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../constants/urlPaths';
import UserCard from '../../components/MyNetworkLayout/UserCard';
import CrossIcon from '../../components/Icons/Cross';
import ExpandIcon from '../../components/Icons/expandIcon';
import { AppliedFiltersUsers } from '../../services/filterService';

const SearchPage = () => {
  const nameData = [
    { name: 'Marketing', value: 'Marketing' },
    { name: 'IT', value: 'IT' },
    { name: 'Finance ', value: 'Finance' },
    { name: 'Manufacturing', value: 'Manufacturing' },
    { name: 'Software Engineer', value: 'Software Engineer' },
    { name: 'UI/UX Designer', value: 'UI/UX Designer' },
    { name: 'Quality Analyst', value: 'Quality Analyst' },
    { name: 'Product Owner', value: 'Product Owner' },
    { name: 'Garduation', value: 'Garduation' },
    { name: 'Post Graduation', value: 'Post Graduation' },
    { name: 'Diploma', value: 'Diploma' },
    { name: 'Higher Education', value: 'Higher Education' },
    { name: 'Communication', value: 'Communication' },
    { name: 'Conferencing', value: 'Conferencing' },
    { name: 'Photography', value: 'Photography' },
    { name: 'Mentoring', value: 'Mentoring' },
    { name: 'Bowling', value: 'Bowling' },
    { name: 'Ice Hockey', value: 'Ice Hockey' },
    { name: 'Gardening', value: 'Gardening' },
    { name: 'Tennis', value: 'Tennis' },
    { name: 'Openness', value: 'openness' },
    { name: 'Aesthetics', value: 'aesthetics' },
    { name: 'Neuroticism', value: 'neuroticism' },
    { name: 'Extraversion', value: 'extraversion' },
    { name: 'Agreeableness', value: 'agreeableness' },
    { name: 'Intellect', value: 'intellect' },
    { name: 'Enthusiasm', value: 'enthusiasm' },
    { name: 'Compassion', value: 'compassion' },
    { name: 'Withdrawal', value: 'withdrawal' },
    { name: 'Industriousness', value: 'industriousness' },
    { name: 'Volatility', value: 'volatility' },
    { name: 'Politeness', value: 'politeness' },
    { name: 'Orderliness', value: 'orderliness' },
    { name: 'Assertiveness', value: 'assertiveness' },
    { name: 'Conscientiousness', value: 'conscientiousness' },
    { name: 'Courage', value: 'courage' },
    { name: 'Perseverance', value: 'perseverance' },
    { name: 'Adaptability', value: 'adaptability' },
    { name: 'Resilience', value: 'resilience' },
    { name: 'Learnability', value: 'learnability' },
    { name: 'Task switching', value: 'taskswitching' },
    { name: 'Compartmentalization', value: 'compartmentalization' },
    { name: 'Situational awareness', value: 'situationalawareness' },
    { name: 'Cunning', value: 'cunning' },
    { name: 'Discipline', value: 'discipline' },
    { name: 'Narcissism', value: 'narcissism' },
    { name: 'Open-Mindedness', value: 'openmindedness' },
    { name: 'Empathy', value: 'empathy' },
    { name: 'Authenticity', value: 'authenticity' },
    { name: 'Selflessness', value: 'selflessness' },
    { name: 'Decisiveness', value: 'decisiveness' },
    { name: 'Accountability', value: 'accountability' },
    { name: 'Humor', value: 'humor' },
    { name: 'Humility', value: 'humility' },
    { name: 'Integrity', value: 'integrity' },
    { name: 'Conscientiousness', value: 'conscientiousness' },
    { name: 'ISTJ: The Inspector', value: 'inspector' },
    { name: 'SFJ: The Protector', value: 'protector' },
    { name: 'INFJ: The Counsellor', value: 'counsellor' },
    { name: 'INTJ: The Mastermind', value: 'mastermind' },
    { name: 'ISTP: The Craftsman', value: 'craftsman' },
    { name: 'ISFP: The Composer', value: 'composer' },
    { name: 'INFP: The Healer', value: 'healer' },
    { name: 'INTP: The Architect', value: 'architect' },
    { name: 'ESTP: The Dynamo', value: 'dynamo' },
    { name: 'ESFP: The Performer', value: 'performer' },
    { name: 'ENFP: The Champion', value: 'champion' },
    { name: 'ENTP: The Visionary', value: 'visionary' },
    { name: 'ESTJ: The Supervisor', value: 'supervisor' },
    { name: 'ESFJ: The Provider', value: 'provider' },
    { name: 'ENFJ: The Teacher', value: 'teacher' },
    { name: 'NTJ: The Commander', value: 'commander' },
    { name: '“D”', value: 'D' },
    { name: 'Di or iD', value: 'Di or iD' },
    { name: '“i”', value: 'i' },
    { name: 'iS or Si', value: 'iS or Si' },
    { name: '“S”', value: 'S' },
    { name: 'SC or CS', value: 'SC or CS' },
    { name: '“C”', value: 'C' },
    { name: 'CD or DC', value: 'CD or DC' },
    { name: 'Type “One”', value: 'typeOne' },
    { name: 'Type “Two”', value: 'typeTwo' },
    { name: 'Type “Three”', value: 'typeThree' },
    { name: 'Type “Four”', value: 'typeFour' },
    { name: 'Type “Five”', value: 'typeFive' },
    { name: 'Type “Six”', value: 'typeSix' },
    { name: 'Type “Seven”', value: 'typeSeven' },
    { name: 'Type “Eight”', value: 'typeEight' },
    { name: 'Type “Nine”', value: 'typeNine' },
    { name: 'Realistic (R)', value: 'realistic' },
    { name: 'Investigative (I)', value: 'investigative' },
    { name: 'Artistic (A)', value: 'artistic' },
    { name: 'Social (S)', value: 'social' },
    { name: 'Enterprising (E)', value: 'enterprising' },
    { name: 'Conventional (C)', value: 'conventional' },
  ];
  const { HOME } = PATHS;
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [selectedLocationCheckbox, setSelectedLocationCheckbox] = useState([]);
  const [removeFilter, setRemoveFilter] = useState();
  const [isExpand, setIsExpand] = useState(true);
  const [isExpandLocation, setIsExpandLocation] = useState(true);
  const [filter, setFilter] = useState(false);
  const handleDataFromParent = (data) => {
    setFilteredData(data);
  };

  const handleCheckboxChange = (checkboxIndex) => {
    if (checkboxIndex === 3) {
      setSelectedCheckbox([3]);
    } else {
      const newSelectedCheckbox = selectedCheckbox.includes(checkboxIndex)
        ? selectedCheckbox.filter((index) => index !== checkboxIndex)
        : [...selectedCheckbox, checkboxIndex];
      if (
        newSelectedCheckbox.includes(0) ||
        newSelectedCheckbox.includes(1) ||
        newSelectedCheckbox.includes(2)
      ) {
        setSelectedCheckbox(newSelectedCheckbox.filter((index) => index !== 3));
      } else {
        setSelectedCheckbox(newSelectedCheckbox);
      }
    }
  };
  const handleLocationCheckboxChange = (checkboxIndex) => {
    if (checkboxIndex === 3) {
      setSelectedLocationCheckbox([3]);
    } else {
      const newSelectedLocationCheckbox = selectedLocationCheckbox.includes(checkboxIndex)
        ? selectedLocationCheckbox.filter((index) => index !== checkboxIndex)
        : [...selectedLocationCheckbox, checkboxIndex];
      if (
        newSelectedLocationCheckbox.includes(0) ||
        newSelectedLocationCheckbox.includes(1) ||
        newSelectedLocationCheckbox.includes(2)
      ) {
        setSelectedLocationCheckbox(newSelectedLocationCheckbox.filter((index) => index !== 3));
      } else {
        setSelectedLocationCheckbox(newSelectedLocationCheckbox);
      }
    }
  };
  const handleAllCheckedItems = (data) => {
    setSelectedFilters(data);
  };

  const fetchSelected = async () => {
    try {
      const { status, data } = await AppliedFiltersUsers({
        queryParams: formatAndEncodeParameters(selectedFilters),
      });

      if (status) {
        setFilteredData(data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const receiveReloadFunction = async () => {
    await fetchSelected();
  };

  const formatAndEncodeParameters = (checkedItems) => {
    const formattedParams = Object.entries(checkedItems)
      .map(([subtitle, items]) => {
        const lowerCaseSubtitle = subtitle.toLowerCase();
        const encodedItems = items.map((item) => encodeURIComponent(item));

        return `${encodeURIComponent(lowerCaseSubtitle)}=${encodedItems.join(',')}`;
      })
      .join('&');
    return formattedParams;
  };
  return (
    <ProfileLayout>
      <div className="flex justify-between">
        <div className={`w-full md:w-[70vw] md:block ${filter ? 'block' : 'hidden'}`}>
          <SidebarSearch
            onCheckedItemsChange={handleDataFromParent}
            AllCheckedItems={handleAllCheckedItems}
            onRemoveFilter={removeFilter}
            selectedCheckbox={selectedCheckbox}
            selectedLocationCheckbox={selectedLocationCheckbox}
            setFilter={setFilter}
          />
        </div>
        <div
          className={`md:ml-[40px] md:min-w-[65vw] md:min-h-[80vh] md:block w-full h-full md:mt-0 mt-[61px] ${
            filter ? 'hidden' : 'block'
          }`}
        >
          <InnerSectionLayout
            heading={'Search Result'}
            isSubSection={true}
            onClickSubSectionHandler={() => navigate(HOME)}
            filters
            setFilter={setFilter}
          >
            <div className="flex flex-col ml-[10px] md:h-[95%] max-h-[700px]">
              {selectedFilters?.attribute?.length > 0 &&
                !selectedFilters?.locations?.length > 0 && (
                  <div className="ml-[24px]">
                    <div className="flex items-center">
                      <div className="text-[14px] font-semibold text-blueprimary">
                        Level (Attributes)
                      </div>
                      <div
                        className={`cursor-pointer transform ${
                          isExpand ? 'rotate-180' : ''
                        } ml-[20px]`}
                        onClick={() => setIsExpand(!isExpand)}
                      >
                        <ExpandIcon />
                      </div>
                    </div>
                    {isExpand && (
                      <div className="flex mt-[10px] text-[12px] font-[500]">
                        <div className="flex">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.level.includes('low')}
                            onChange={() => handleCheckboxChange(0)}
                            value="low"
                          />
                          Low
                        </div>
                        <div className="flex ml-[15px] text-[12px] font-[500]">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.level.includes('medium')}
                            onChange={() => handleCheckboxChange(1)}
                            value="medium"
                          />
                          Medium
                        </div>
                        <div className="flex ml-[15px] text-[12px] font-[500]">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.level.includes('high')}
                            onChange={() => handleCheckboxChange(2)}
                            value="high"
                          />
                          High
                        </div>
                        <div className="flex ml-[15px] text-[12px] font-[500]">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.level.includes('high,medium,low')}
                            onChange={() => handleCheckboxChange(3)}
                            value="low,medium,high"
                          />
                          All
                        </div>
                      </div>
                    )}
                  </div>
                )}
              {selectedFilters?.locations?.length > 0 &&
                !selectedFilters?.attribute?.length > 0 && (
                  <div className="ml-[24px]">
                    <div className="flex items-center">
                      <div className="text-[14px] font-semibold text-blueprimary">Location</div>
                      <div
                        className={`cursor-pointer transform ${
                          isExpandLocation ? 'rotate-180' : ''
                        } ml-[20px]`}
                        onClick={() => setIsExpandLocation(!isExpandLocation)}
                      >
                        <ExpandIcon />
                      </div>
                    </div>
                    {isExpandLocation && (
                      <div className="flex mt-[10px] text-[12px] font-[500]">
                        <div className="flex">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.locationtype.includes('current')}
                            onChange={() => handleLocationCheckboxChange(0)}
                            value="current"
                          />
                          Current
                        </div>
                        <div className="flex ml-[15px] text-[12px] font-[500]">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.locationtype.includes('raised')}
                            onChange={() => handleLocationCheckboxChange(1)}
                            value="raised"
                          />
                          Raised
                        </div>
                        <div className="flex ml-[15px] text-[12px] font-[500]">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.locationtype.includes('born')}
                            onChange={() => handleLocationCheckboxChange(2)}
                            value="born"
                          />
                          Born
                        </div>
                        <div className="flex ml-[15px] text-[12px] font-[500]">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.locationtype.includes('current,raised,born')}
                            onChange={() => handleLocationCheckboxChange(3)}
                            value="current,raised,born"
                          />
                          All
                        </div>
                      </div>
                    )}
                  </div>
                )}
              {selectedFilters?.attribute?.length > 0 && selectedFilters?.locations?.length > 0 && (
                <>
                  <div className="ml-[24px]">
                    <div className="flex items-center">
                      <div className="text-[14px] font-semibold text-blueprimary">
                        Level (Attributes)
                      </div>
                      <div
                        className={`cursor-pointer transform ${
                          isExpand ? 'rotate-180' : ''
                        } ml-[20px]`}
                        onClick={() => setIsExpand(!isExpand)}
                      >
                        <ExpandIcon />
                      </div>
                    </div>
                    {isExpand && (
                      <div className="flex mt-[10px] text-[12px] font-[500]">
                        <div className="flex">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.level.includes('low')}
                            onChange={() => handleCheckboxChange(0)}
                            value="low"
                          />
                          Low
                        </div>
                        <div className="flex ml-[15px] text-[12px] font-[500]">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.level.includes('medium')}
                            onChange={() => handleCheckboxChange(1)}
                            value="medium"
                          />
                          Medium
                        </div>
                        <div className="flex ml-[15px] text-[12px] font-[500]">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.level.includes('high')}
                            onChange={() => handleCheckboxChange(2)}
                            value="high"
                          />
                          High
                        </div>
                        <div className="flex ml-[15px] text-[12px] font-[500]">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.level.includes('high,medium,low')}
                            onChange={() => handleCheckboxChange(3)}
                            value="low,medium,high"
                          />
                          All
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="ml-[24px] mt-[20px]">
                    <div className="flex items-center">
                      <div className="text-[14px] font-semibold text-blueprimary">Location</div>
                      <div
                        className={`cursor-pointer transform ${
                          isExpandLocation ? 'rotate-180' : ''
                        } ml-[20px]`}
                        onClick={() => setIsExpandLocation(!isExpandLocation)}
                      >
                        <ExpandIcon />
                      </div>
                    </div>
                    {isExpandLocation && (
                      <div className="flex mt-[10px] text-[12px] font-[500]">
                        <div className="flex">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.locationtype.includes('current')}
                            onChange={() => handleLocationCheckboxChange(0)}
                            value="current"
                          />
                          Current
                        </div>
                        <div className="flex ml-[15px] text-[12px] font-[500]">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.locationtype.includes('raised')}
                            onChange={() => handleLocationCheckboxChange(1)}
                            value="raised"
                          />
                          Raised
                        </div>
                        <div className="flex ml-[15px] text-[12px] font-[500]">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.locationtype.includes('born')}
                            onChange={() => handleLocationCheckboxChange(2)}
                            value="born"
                          />
                          Born
                        </div>
                        <div className="flex ml-[15px] text-[12px] font-[500]">
                          <input
                            type="checkbox"
                            className="mr-[3px]"
                            checked={selectedFilters.locationtype.includes('current,raised,born')}
                            onChange={() => handleLocationCheckboxChange(3)}
                            value="current,raised,born"
                          />
                          All
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              <div className="flex flex-wrap mt-4 ml-[24px]">
                {Object.entries(selectedFilters).map(([key, filterValues]) =>
                  filterValues
                    .filter(
                      (filterValue) =>
                        filterValue !== 'high' &&
                        filterValue !== 'medium' &&
                        filterValue !== 'low' &&
                        filterValue !== 'high,medium,low' &&
                        filterValue !== 'current' &&
                        filterValue !== 'raised' &&
                        filterValue !== 'born' &&
                        filterValue !== 'current,raised,born' &&
                        filterValue !== localStorage.getItem('searchValue'),
                    )
                    .map((filterValue, index) => {
                      const displayedText =
                        nameData.find((item) => item.value === filterValue)?.name ||
                        filterValue
                          .split(' ')
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ');

                      const truncatedText =
                        displayedText.slice(0, 30) + (displayedText.length > 30 ? '...' : '');

                      return (
                        <div
                          key={index}
                          className="border border-greylight pt-[4px] pb-[4px] pr-[10px] pl-[10px] justify-between rounded-full flex items-center mr-2 mb-2"
                        >
                          <span className="text-[12px] font-normal font-darkgrey">
                            {truncatedText}
                          </span>
                          <div
                            className="cursor-pointer ml-[10px]"
                            onClick={() => setRemoveFilter({ key, filterValue })}
                          >
                            <CrossIcon width="9px" height="9px" />
                          </div>
                        </div>
                      );
                    }),
                )}
              </div>
              {!filteredData?.data?.users?.length ? (
                <div className="p-4 mt-4 h-[calc(100vh-275px)] flex flex-col justify-center item-center m-auto text-center">
                  <h5 className="font-medium text-greydark text-[14px] mb-2">
                    No results were found
                  </h5>
                </div>
              ) : (
                <div className="my-5 mx-6 mb-4 overflow-y-auto">
                  {filteredData?.data?.users?.map((item) => {
                    return (
                      <UserCard
                        key={item?.id}
                        id={item?.id}
                        userId={item?.id}
                        userName={`${item?.firstName} ${item?.lastName}`}
                        UserActiveLocation={
                          item?.UserActiveLocation.find((item) => item.isCurrent) || {}
                        }
                        career={item?.Careers?.[0]?.title}
                        userImage={item?.profilePictureUrl}
                        isApproved={item?.isApproved}
                        filter
                        followStatus={item.followStatus}
                        userData={item}
                        reloadAfterFollowUnfollow={receiveReloadFunction}
                        user={item}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </InnerSectionLayout>
        </div>
      </div>
    </ProfileLayout>
  );
};

export default SearchPage;
