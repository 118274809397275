import React from 'react';

const UpChevronFilled = () => {
  return (
    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 5.63236e-07L0.870837 6.69047L12.1292 6.69047L6.5 5.63236e-07Z" fill="white" />
    </svg>
  );
};

export default UpChevronFilled;
