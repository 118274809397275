import React, { useEffect, useState } from 'react';
import FilterSidebar from '../sidebarSearch';
import ExpandIcon from '../Icons/expandIcon';
import CrossIcon from '../Icons/Cross';
import backIcon from '../../assets/images/backIcon.svg';
import { AppliedFiltersUsers } from '../../services/filterService';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../constants/urlPaths';
import { ToastNotifyError } from '../Toast/ToastNotify';

const SidebarSearch = ({
  onCheckedItemsChange,
  AllCheckedItems,
  selectedCheckbox,
  selectedLocationCheckbox,
  onRemoveFilter,
  setFilter,
}) => {
  const data = [
    {
      title: 'Work',
      subtitles: [
        {
          subtitle: 'Career',
          items: [
            { name: 'Marketing', value: 'Marketing' },
            { name: 'IT', value: 'IT' },
            { name: 'Finance ', value: 'Finance ' },
            { name: 'Manufacturing', value: 'Manufacturing' },
          ],
        },
        {
          subtitle: 'Experience',
          items: [
            { name: 'Software Engineer', value: 'Software Engineer' },
            { name: 'UI/UX Designer', value: 'UI/UX Designer' },
            { name: 'Quality Analyst', value: 'Quality Analyst' },
            { name: 'Product Owner', value: 'Product Owner' },
          ],
        },
        {
          subtitle: 'Education',
          items: [
            { name: 'Graduation', value: 'Graduation' },
            { name: 'Post Graduation', value: 'Post Graduation' },
            { name: 'Diploma', value: 'Diploma' },
            { name: 'Higher Education', value: 'Higher Education' },
          ],
        },
        {
          subtitle: 'Skill',
          items: [
            { name: 'Communication', value: 'Communication' },
            { name: 'Conferencing', value: 'Conferencing' },
            { name: 'Photography', value: 'Photography' },
            { name: 'Mentoring', value: 'Mentoring' },
          ],
        },
      ],
      items: [],
      assesment: false,
    },
    {
      title: 'Interest',
      items: [
        { name: 'Bowling', value: 'Bowling' },
        { name: 'Ice Hockey', value: 'Ice Hockey' },
        { name: 'Gardening', value: 'Gardening' },
        { name: 'Tennis', value: 'Tennis' },
      ],
      subtitles: [],
      assesment: false,
    },
  ];
  const dataAssesment = [
    {
      title: 'Personality Assessment',
      subtitles: [
        {
          subtitle: 'The Big Five',
          items: [
            { name: 'Openness', value: 'openness' },
            { name: 'Aesthetics', value: 'aesthetics' },
            { name: 'Neuroticism', value: 'neuroticism' },
            { name: 'Extraversion', value: 'extraversion' },
            { name: 'Agreeableness', value: 'agreeableness' },
          ],
          allItems: [
            { name: 'Openness', value: 'openness' },
            { name: 'Aesthetics', value: 'aesthetics' },
            { name: 'Neuroticism', value: 'neuroticism' },
            { name: 'Extraversion', value: 'extraversion' },
            { name: 'Agreeableness', value: 'agreeableness' },
            { name: 'Intellect', value: 'intellect' },
            { name: 'Enthusiasm', value: 'enthusiasm' },
            { name: 'Compassion', value: 'compassion' },
            { name: 'Withdrawal', value: 'withdrawal' },
            { name: 'Industriousness', value: 'industriousness' },
            { name: 'Volatility', value: 'volatility' },
            { name: 'Politeness', value: 'politeness' },
            { name: 'Orderliness', value: 'orderliness' },
            { name: 'Assertiveness', value: 'assertiveness' },
            { name: 'Conscientiousness', value: 'conscientiousness' },
          ],
        },
        {
          subtitle: 'The Attributes',
          items: [
            { name: 'Courage', value: 'courage' },
            { name: 'Perseverance', value: 'perseverance' },
            { name: 'Adaptability', value: 'adaptability' },
            { name: 'Resilience', value: 'resilience' },
            { name: 'Learnability', value: 'learnability' },
          ],
          allItems: [
            { name: 'Courage', value: 'courage' },
            { name: 'Perseverance', value: 'perseverance' },
            { name: 'Adaptability', value: 'adaptability' },
            { name: 'Resilience', value: 'resilience' },
            { name: 'Learnability', value: 'learnability' },
            { name: 'Task switching', value: 'taskswitching' },
            { name: 'Compartmentalization', value: 'compartmentalization' },
            { name: 'Situational awareness', value: 'situationalawareness' },
            { name: 'Cunning', value: 'cunning' },
            { name: 'Discipline', value: 'discipline' },
            { name: 'Narcissism', value: 'narcissism' },
            { name: 'Open-Mindedness', value: 'openmindedness' },
            { name: 'Empathy', value: 'empathy' },
            { name: 'Authenticity', value: 'authenticity' },
            { name: 'Selflessness', value: 'selflessness' },
            { name: 'Decisiveness', value: 'decisiveness' },
            { name: 'Accountability', value: 'accountability' },
            { name: 'Humor', value: 'humor' },
            { name: 'Humility', value: 'humility' },
            { name: 'Integrity', value: 'integrity' },
            { name: 'Conscientiousness', value: 'conscientiousness' },
          ],
        },
        {
          subtitle: 'Myers-Briggs',
          items: [
            { name: 'ISTJ: The Inspector', value: 'inspector' },
            { name: 'SFJ: The Protector', value: 'protector' },
            { name: 'INFJ: The Counsellor', value: 'counsellor' },
            { name: 'INTJ: The Mastermind', value: 'mastermind' },
            { name: 'ISTP: The Craftsman', value: 'craftsman' },
          ],
          allItems: [
            { name: 'ISTJ: The Inspector', value: 'inspector' },
            { name: 'SFJ: The Protector', value: 'protector' },
            { name: 'INFJ: The Counsellor', value: 'counsellor' },
            { name: 'INTJ: The Mastermind', value: 'mastermind' },
            { name: 'ISTP: The Craftsman', value: 'craftsman' },
            { name: 'ISFP: The Composer', value: 'composer' },
            { name: 'INFP: The Healer', value: 'healer' },
            { name: 'INTP: The Architect', value: 'architect' },
            { name: 'ESTP: The Dynamo', value: 'dynamo' },
            { name: 'ESFP: The Performer', value: 'performer' },
            { name: 'ENFP: The Champion', value: 'champion' },
            { name: 'ENTP: The Visionary', value: 'visionary' },
            { name: 'ESTJ: The Supervisor', value: 'supervisor' },
            { name: 'ESFJ: The Provider', value: 'provider' },
            { name: 'ENFJ: The Teacher', value: 'teacher' },
            { name: 'NTJ: The Commander', value: 'commander' },
          ],
        },
        {
          subtitle: 'DISC',
          items: [
            { name: '“D”', value: 'D' },
            { name: 'Di or iD', value: 'Di or iD' },
            { name: '“i”', value: 'i' },
            { name: 'iS or Si', value: 'iS or Si' },
            { name: '“S”', value: 'S' },
          ],
          allItems: [
            { name: '“D”', value: 'D' },
            { name: 'Di or iD', value: 'Di or iD' },
            { name: '“i”', value: 'i' },
            { name: 'iS or Si', value: 'iS or Si' },
            { name: '“S”', value: 'S' },
            { name: 'SC or CS', value: 'SC or CS' },
            { name: '“C”', value: 'C' },
            { name: 'CD or DC', value: 'CD or DC' },
          ],
        },
        {
          subtitle: 'Enneagram',
          items: [
            { name: 'Type “One”', value: 'typeOne' },
            { name: 'Type “Two”', value: 'typeTwo' },
            { name: 'Type “Three”', value: 'typeThree' },
            { name: 'Type “Four”', value: 'typeFour' },
            { name: 'Type “Five”', value: 'typeFive' },
          ],
          allItems: [
            { name: 'Type “One”', value: 'typeOne' },
            { name: 'Type “Two”', value: 'typeTwo' },
            { name: 'Type “Three”', value: 'typeThree' },
            { name: 'Type “Four”', value: 'typeFour' },
            { name: 'Type “Five”', value: 'typeFive' },
            { name: 'Type “Six”', value: 'typeSix' },
            { name: 'Type “Seven”', value: 'typeSeven' },
            { name: 'Type “Eight”', value: 'typeEight' },
            { name: 'Type “Nine”', value: 'typeNine' },
          ],
        },
        {
          subtitle: 'Holland Code',
          items: [
            { name: 'Realistic (R)', value: 'realistic' },
            { name: 'Investigative (I)', value: 'investigative' },
            { name: 'Artistic (A)', value: 'artistic' },
            { name: 'Social (S)', value: 'social' },
            { name: 'Enterprising (E)', value: 'enterprising' },
          ],
          allItems: [
            { name: 'Realistic (R)', value: 'realistic' },
            { name: 'Investigative (I)', value: 'investigative' },
            { name: 'Artistic (A)', value: 'artistic' },
            { name: 'Social (S)', value: 'social' },
            { name: 'Enterprising (E)', value: 'enterprising' },
            { name: 'Conventional (C)', value: 'conventional' },
          ],
        },
      ],
      assesment: true,
      items: [],
    },
  ];
  const [isExpanded, setExpanded] = useState(true);
  const [isExpanded2, setExpanded2] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [inputValue2, setInputValue2] = useState('');
  const [tiles2, setTiles2] = useState([]);
  const [tiles, setTiles] = useState([]);
  const [dataState, setDataState] = useState(data);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const storedSearchValue = localStorage.getItem('searchValue');
  const [checkedItems, setCheckedItems] = useState({
    search: storedSearchValue ? [storedSearchValue] : [],
    career: [],
    experience: [],
    education: [],
    skill: [],
    softskills: [],
    interest: [],
    bigfive: [],
    myersbriggs: [],
    disc: [],
    enneagram: [],
    hollandcode: [],
    attribute: [],
    level: ['high,medium,low'],
    locationtype: ['current'],
    locations: [],
  });
  const navigate = useNavigate();
  const { HOME } = PATHS;
  const handleRemoveFilter = (key, filterValue) => {
    setCheckedItems((prevItems) => {
      const updatedItems = prevItems[key].filter((item) => item !== filterValue);
      switch (key) {
        case 'softskills': {
          const updatedTiles = tiles.filter((tile) => tile.toLowerCase() !== filterValue);
          setTiles(updatedTiles);
          break;
        }
        case 'locations': {
          const updatedTiles2 = tiles2.filter((tile) => tile.toLowerCase() !== filterValue);
          setTiles2(updatedTiles2);
          break;
        }
        default:
          break;
      }

      return { ...prevItems, [key]: updatedItems };
    });
  };
  useEffect(() => {
    if (onRemoveFilter) {
      handleRemoveFilter(onRemoveFilter.key, onRemoveFilter.filterValue);
    }
  }, [onRemoveFilter]);
  const handleCheckboxChangeEffect = () => {
    if (!selectedCheckbox.length) {
      return;
    }

    const mapCheckboxValue = (checkboxIndex) => {
      switch (checkboxIndex) {
        case 0:
          return 'low';
        case 1:
          return 'medium';
        case 2:
          return 'high';
        case 3:
          return 'high,medium,low';
        default:
          return 'high,medium,low';
      }
    };

    const mappedValues = selectedCheckbox.map(mapCheckboxValue);

    setCheckedItems((prev) => ({ ...prev, level: mappedValues }));
  };
  const handleLocationCheckboxChangeEffect = () => {
    if (!selectedLocationCheckbox.length) {
      return;
    }

    const mapCheckboxValue = (checkboxIndex) => {
      switch (checkboxIndex) {
        case 0:
          return 'current';
        case 1:
          return 'raised';
        case 2:
          return 'born';
        case 3:
          return 'current,raised,born';
        default:
          return 'current';
      }
    };

    const mappedValues = selectedLocationCheckbox.map(mapCheckboxValue);

    setCheckedItems((prev) => ({ ...prev, locationtype: mappedValues }));
  };
  useEffect(() => {
    handleCheckboxChangeEffect();
  }, [selectedCheckbox]);

  useEffect(() => {
    handleLocationCheckboxChangeEffect();
  }, [selectedLocationCheckbox]);

  const handleClearFilter = () => {
    setCheckedItems({
      search: storedSearchValue ? [storedSearchValue] : [],
      career: [],
      experience: [],
      education: [],
      skill: [],
      softskills: [],
      interest: [],
      bigfive: [],
      myersbriggs: [],
      disc: [],
      enneagram: [],
      hollandcode: [],
      attribute: [],
      level: ['high,medium,low'],
      locations: [],
      locationtype: ['current'],
    });
    setTiles([]);
    setTiles2([]);
  };

  useEffect(() => {
    AllCheckedItems(checkedItems);
  }, [checkedItems]);

  const handleExpandToggle = () => {
    setExpanded(!isExpanded);
  };
  const handleExpandLocationToggle = () => {
    setExpanded2(!isExpanded2);
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleInputLocationChange = (event) => {
    setInputValue2(event.target.value);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (inputValue.trim() !== '') {
        setTiles([
          ...tiles,
          inputValue.slice(0, 18) + (inputValue && inputValue.length > 18 ? '...' : ''),
        ]);
        setInputValue('');
        const updatedValue = convertToLowerCaseWithExceptions(
          inputValue.slice(0, 18) + (inputValue && inputValue.length > 18 ? '...' : ''),
        );
        setCheckedItems((prev) => ({ ...prev, softskills: [...prev.softskills, updatedValue] }));
      } else {
        setInputValue('');
      }
    }
  };
  const handleEnterKeyPress2 = (event) => {
    if (event.key === 'Enter') {
      if (inputValue2.trim() !== '') {
        setTiles2([
          ...tiles2,
          inputValue2.slice(0, 18) + (inputValue2 && inputValue2.length > 18 ? '...' : ''),
        ]);
        setInputValue2('');
        const updatedValue = convertToLowerCaseWithExceptions(
          inputValue2.slice(0, 18) + (inputValue2 && inputValue2.length > 18 ? '...' : ''),
        );
        setCheckedItems((prev) => ({ ...prev, locations: [...prev.locations, updatedValue] }));
      } else {
        setInputValue2('');
      }
    }
  };
  const handleRemoveTile = (index) => {
    const updatedTiles = tiles.filter((_, i) => i !== index);
    setTiles(updatedTiles);

    const removedValue = tiles[index]?.toLowerCase();
    setCheckedItems((prev) => ({
      ...prev,
      softskills: prev.softskills.filter((item) => item.toLowerCase() !== removedValue),
    }));
  };
  const handleRemoveTile2 = (index) => {
    const updatedTiles = tiles2.filter((_, i) => i !== index);
    setTiles2(updatedTiles);
    const removedValue = tiles2[index]?.toLowerCase();
    setCheckedItems((prev) => ({
      ...prev,
      locations: prev.locations.filter((item) => item.toLowerCase() !== removedValue),
    }));
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleElementClick = (element, title) => {
    const workSubtitles = dataState[0]?.subtitles;
    const matchingSubtitle = workSubtitles.find((subtitle) => subtitle.subtitle === title);
    if (matchingSubtitle) {
      const existingName = element?.slice(0, 30) + (element && element.length > 30 ? '...' : '');
      const isElementExists = matchingSubtitle.items.some((item) => item.name === existingName);
      if (!isElementExists) {
        const updatedItems = [
          ...matchingSubtitle.items,
          {
            name: element?.slice(0, 30) + (element && element.length > 30 ? '...' : ''),
            value: element,
          },
        ];

        const updatedData = dataState.map((element) => {
          if (element.title === 'Work') {
            return {
              ...element,
              subtitles: element.subtitles.map((subtitle) => {
                if (subtitle.subtitle === title) {
                  return {
                    ...subtitle,
                    items: updatedItems,
                  };
                }
                return subtitle;
              }),
            };
          }
          return element;
        });
        setDataState(updatedData);
        setCheckedItems((prev) => ({
          ...prev,
          [convertToLowerCaseWithExceptions(title)]: [
            ...(prev[convertToLowerCaseWithExceptions(title)] || []),
            element,
          ],
        }));
      } else {
        setCheckedItems((prev) => ({
          ...prev,
          [convertToLowerCaseWithExceptions(title)]: [
            ...(prev[convertToLowerCaseWithExceptions(title)] || []),
            element,
          ],
        }));
      }
    } else {
      const interestItems = dataState[1].items;
      const isElementExistsInterest = interestItems.some((item) => item.name === element);
      if (!isElementExistsInterest) {
        const updatedItems = [
          ...interestItems,
          {
            name: element?.slice(0, 30) + (element && element.length > 30 ? '...' : ''),
            value: element,
          },
        ];
        const updatedData = dataState.map((section) => {
          if (section.title === 'Interest') {
            return {
              ...section,
              items: updatedItems,
            };
          }
          return section;
        });
        setDataState(updatedData);
        setCheckedItems((prev) => ({
          ...prev,
          [convertToLowerCaseWithExceptions(title)]: [
            ...(prev[convertToLowerCaseWithExceptions(title)] || []),
            element,
          ],
        }));
      } else {
        setCheckedItems((prev) => ({
          ...prev,
          [convertToLowerCaseWithExceptions(title)]: [
            ...(prev[convertToLowerCaseWithExceptions(title)] || []),
            element,
          ],
        }));
      }
    }
  };
  const handleCheckboxChange = (name, item) => {
    setCheckedItems((prevItems) => {
      const finalSubtitleName = convertToLowerCaseWithExceptions(name);
      const isChecked = !(prevItems[finalSubtitleName] || []).includes(item);

      return {
        ...prevItems,
        [finalSubtitleName]: isChecked
          ? [...(prevItems[finalSubtitleName] || []), item]
          : (prevItems[finalSubtitleName] || []).filter((prevItem) => prevItem !== item),
      };
    });
  };

  const convertToLowerCaseWithExceptions = (name) => {
    const lowerCaseSubtitleName = name.trim().toLowerCase();
    const withoutHyphens = lowerCaseSubtitleName.replace(/-/g, '');
    const withoutThe = withoutHyphens.replace(/^the/, '');
    const withoutSpaces = withoutThe.replace(/\s+/g, '');
    if (withoutSpaces === 'attributes') {
      return 'attribute';
    }
    return withoutSpaces;
  };

  const formatAndEncodeParameters = (checkedItems) => {
    const formattedParams = Object.entries(checkedItems)
      .map(([subtitle, items]) => {
        const lowerCaseSubtitle = subtitle.toLowerCase();
        const encodedItems = items.map((item) => encodeURIComponent(item));

        return `${encodeURIComponent(lowerCaseSubtitle)}=${encodedItems.join(',')}`;
      })
      .join('&');
    return formattedParams;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const fetchSelected = async () => {
      try {
        const { status, data } = await AppliedFiltersUsers({
          queryParams: formatAndEncodeParameters(checkedItems),
        });

        if (status) {
          onCheckedItemsChange(data);
        }
      } catch (error) {
        ToastNotifyError(error);
      }
    };

    fetchSelected();
  }, [checkedItems]);

  return (
    <div className="md:w-72 h-fit-content md:rounded-md bg-white md:mt-[16px] md:mb-[16px] w-full mt-[62px]">
      <div
        className="flex justify-between p-4 border-b border-opacity-30 border-gray-400 items-center"
        style={{ borderColor: 'rgba(161, 160, 160, 0.3)' }}
      >
        <div className="flex">
          {isMobile ? (
            <div onClick={() => navigate(HOME)} className="pointer">
              <img src={backIcon} alt="backIcon" className="w-[24px] mr-[10px]" />
            </div>
          ) : (
            ''
          )}
          <div className="text-black foSoftwarent-montserrat text-[18px] font-semibold">
            Apply Filter
          </div>
        </div>
        <div
          className="text-black foSoftwarent-montserrat text-[14px] font-normal cursor-pointer"
          onClick={() => {
            handleClearFilter();
          }}
        >
          Clear Filter
        </div>
      </div>
      <div>
        {dataState.map((element) => (
          <FilterSidebar
            key={element?.title}
            subtitles={element?.subtitles}
            title={element?.title}
            items={element?.items}
            assesment={element?.assesment}
            handleElementClicked={handleElementClick}
            checkedItems={checkedItems}
            handleCheckboxChange={handleCheckboxChange}
          />
        ))}
      </div>
      <div className="pr-4 pl-4">
        <div className="flex justify-between">
          <div className="filter-heading mb-3 mt-4">Soft Skills</div>
          <div className="flex justify-center items-center space-x-4 mr-[7px]">
            <div
              onClick={handleExpandToggle}
              className={`cursor-pointer transform transition-transform ease-in-out duration-300 ${
                isExpanded ? 'rotate-180' : ''
              }`}
            >
              <ExpandIcon width={28} height={28} />
            </div>
          </div>
        </div>
        {isExpanded && (
          <div>
            <label htmlFor="location" className="font-semibold text-[14px] text-greydark">
              Soft Skills
            </label>
            <input
              id="softskills"
              type="text"
              placeholder={'Type Soft Skills'}
              className="border border-solid border-greylight bg-white w-[98%]  rounded-md mt-[10px] text-[16px] h-[50px]"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleEnterKeyPress}
            />
            <div className="mt-4 flex flex-wrap">
              {tiles.map((tile, index) => (
                <div key={index} className="flex items-center mb-2 ml-1">
                  <div className="bg-whitelight rounded-md p-2 flex items-center">
                    {tile}
                    <div className="ml-2 cursor-pointer" onClick={() => handleRemoveTile(index)}>
                      <CrossIcon fill="#A1A0A0" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="pr-4 pl-4 pb-4">
        <div className="flex justify-between">
          <div className="filter-heading mb-3 mt-4">Location</div>
          <div className="flex justify-center items-center space-x-4 mr-[7px]">
            <div
              onClick={handleExpandLocationToggle}
              className={`cursor-pointer transform transition-transform ease-in-out duration-300 ${
                isExpanded2 ? 'rotate-180' : ''
              }`}
            >
              <ExpandIcon width={28} height={28} />
            </div>
          </div>
        </div>
        {isExpanded2 && (
          <div>
            <label htmlFor="location" className="font-semibold text-[14px] text-greydark">
              Location
            </label>
            <input
              id="location"
              type="text"
              placeholder={'Type location'}
              className="border border-solid border-greylight bg-white w-[98%] rounded-md mt-[10px] text-[16px] h-[50px]"
              value={inputValue2}
              onChange={handleInputLocationChange}
              onKeyDown={handleEnterKeyPress2}
            />
            <div className="mt-4 flex flex-wrap">
              {tiles2.map((tile, index) => (
                <div key={index} className="flex items-center mb-2 ml-1">
                  <div className="bg-whitelight rounded-md p-2 flex items-center">
                    {tile}
                    <div className="ml-2 cursor-pointer" onClick={() => handleRemoveTile2(index)}>
                      <CrossIcon fill="#A1A0A0" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div>
        {dataAssesment.map((element) => {
          return (
            <FilterSidebar
              key={element?.title}
              subtitles={element?.subtitles}
              title={element?.title}
              items={element?.items}
              assesment={element?.assesment}
              handleElementClicked={handleElementClick}
              checkedItems={checkedItems}
              handleCheckboxChange={handleCheckboxChange}
            />
          );
        })}
      </div>
      <div
        className="w-full bg-darkblue sticky bottom-0 h-[60px] flex items-center justify-center cursor-pointer md:hidden z-49"
        onClick={() => setFilter(false)}
      >
        <span className="text-white">Apply Filters</span>
      </div>
    </div>
  );
};

export default SidebarSearch;
