const SearchLens = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.88724 0C9.1334 0 11.7739 2.58222 11.7739 5.75679C11.7739 7.25454 11.1861 8.62057 10.2243 9.64578L12.1169 11.4927C12.294 11.6659 12.2946 11.9461 12.1175 12.1194C12.0292 12.2068 11.9125 12.25 11.7965 12.25C11.681 12.25 11.5649 12.2068 11.4761 12.1205L9.5607 10.2526C8.55313 11.0417 7.27558 11.5142 5.88724 11.5142C2.64106 11.5142 0 8.93136 0 5.75679C0 2.58222 2.64106 0 5.88724 0ZM5.88724 0.886752C3.14099 0.886752 0.906751 3.07112 0.906751 5.75679C0.906751 8.44247 3.14099 10.6274 5.88724 10.6274C8.63288 10.6274 10.8671 8.44247 10.8671 5.75679C10.8671 3.07112 8.63288 0.886752 5.88724 0.886752Z"
        fill="#A1A0A0"
      />
    </svg>
  );
};

export default SearchLens;
