import React, { useState } from 'react';
import { getFileExtension, getFileName } from '../../../utils/helper';
import { IMAGE_EXTENSIONS } from '../../../constants/constants';
import InterestVideoIcon from '../../Icons/InterestVideoIcon';
import PreviewMediaFile from '../../PreviewMediaFile';

const MediaData = ({ data }) => {
  const [previewMedia, setPreviewMedia] = useState({ type: '', isOpen: false, fileSrc: '' });

  const renderMedia = (files) => {
    return files.map((file, index) => {
      const url = file?.path;
      return (
        <div
          key={index}
          className="relative max-h-[55px] h-[55px] w-[55px] max-w-[55px] cursor-pointer"
        >
          {IMAGE_EXTENSIONS.includes(getFileExtension(getFileName(file?.path))) ? (
            <img
              src={url}
              alt={file.name}
              className="mb-3 max-h-[55px] h-[55px] w-[55px] max-w-[55px] rounded-md object-cover bg-[#c6b7b7]"
              onClick={() => setPreviewMedia({ isOpen: true, fileSrc: file?.path, type: 'image' })}
            />
          ) : (
            <>
              <video
                src={url}
                className="mb-3 max-h-[55px] h-[55px] w-[55px] max-w-[55px] rounded-md object-cover bg-[#c6b7b7]"
                controls={false}
              />
              <div
                className="rounded-md w-full h-full absolute top-0 left-0 bg-[#00000085] flex items-center justify-center"
                onClick={() =>
                  setPreviewMedia({ isOpen: true, fileSrc: file?.path, type: 'video' })
                }
              >
                <InterestVideoIcon />
              </div>
            </>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div className="flex gap-3 pt-2 flex-wrap">{renderMedia(data)}</div>
      <div className="pt-[24px] pb-4">
        <div className="bg-greymedium h-[1px] w-full" />
      </div>

      <PreviewMediaFile {...previewMedia} onCloseHandler={() => setPreviewMedia({})} />
    </>
  );
};

export default MediaData;
