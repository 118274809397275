import React, { useEffect, useState } from 'react';
import AutoComplete from '../common/AutoComplete';
import Input from '../common/Input';
import addCircle from '../../assets/images/add_circle.svg';
import { Button } from '../common/Button';
import axios from 'axios';
import debounce from '../../utils/debounce';
import { useCallback } from 'react';
import { addLocation, getMySelfDetails } from '../../services/myself';
import { getErrorMessage, successStatus } from '../../common';
import { ToastNotifyError, ToastNotifySuccess } from '../Toast/ToastNotify';

const initialRaisedLocationData = {
  searchVal: '',
  country: '',
  state: '',
  city: '',
  type: 'raised',
};

const initialBornLocationData = {
  searchVal: '',
  country: '',
  state: '',
  city: '',
  type: 'born',
};

const Location = ({
  parentWrapClass = '',
  hideLocationHeading = false,
  childClassPadding = '',
  setIsSaveClicked = () => {},
  getMyselfData = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentLocationSearchVal, setCurrentLocationSearchVal] = useState({});

  const [raisedLocationOptions, setRaisedLocationOptions] = useState([]);
  const [bornLocationOptions, setBornLocationOptions] = useState([]);

  //searchedData
  const [searchedRaisedData, setSearchedRaisedData] = useState([]);
  const [searchedBornData, setSearchedBornData] = useState([]);

  const [currentLocationData, setCurrentLocationData] = useState([]);
  const [raisedLocationData, setRaisedLocationData] = useState(initialRaisedLocationData);
  const [bornLocationData, setBornLocationData] = useState(initialBornLocationData);

  const [removeLocationId, setRemoveLocationId] = useState([]);

  const addLocationData = () => {
    const data = {
      keyId: Math.random(),
      searchVal: '',
      options: [],
      searchedData: [],
      country: '',
      state: '',
      city: '',
      duration: null,
      type: 'current',
      latitude: '',
      longitude: '',
    };
    setCurrentLocationData((prevData) => {
      return [...prevData, data];
    });
  };

  const fetchLocation = async (searchValue, setSearchedData = () => {}, setOptions = () => {}) => {
    if (searchValue !== null && searchValue !== undefined && searchValue?.trim()?.length) {
      const url = `https://api.locationiq.com/v1/autocomplete?key=${process.env.REACT_APP_LOCATION_KEY}&q=${searchValue}`;
      const response = await axios
        .get(url)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error('Error:', error);
          return {};
        });
      const { data = [] } = response;
      let updatedData = data;
      if (data?.length > 5) {
        updatedData = data?.slice(0, 5);
      }
      setSearchedData(updatedData);
      const refractedResponse = updatedData.map((item) => {
        return item?.display_name;
      });
      setOptions([...refractedResponse]);
    }
  };

  const refracterData = (data, name) => {
    const filteredData = data.filter((item) => item.type === name);
    const refractedLanguageData = filteredData.map(
      ({ UserId, createdAt, updatedAt, duration = null, ...rest }) => {
        return {
          ...rest,
          duration: parseInt(duration),
        };
      },
    );
    if (name === 'raised') {
      if (refractedLanguageData.length === 0) {
        return initialRaisedLocationData;
      } else {
        return refractedLanguageData[0];
      }
    } else if (name === 'born') {
      if (refractedLanguageData.length === 0) {
        return initialBornLocationData;
      } else {
        return refractedLanguageData[0];
      }
    } else {
      return refractedLanguageData;
    }
  };

  const getMySelfDetailsData = async () => {
    const response = await getMySelfDetails();
    const { data: { data = '' } = {}, status = '' } = response;
    if (status) {
      const { locations = [] } = data;
      setCurrentLocationData(refracterData(locations, 'current'));
      addLocationData();
      setRaisedLocationData(refracterData(locations, 'raised'));
      setBornLocationData(refracterData(locations, 'born'));
    }
  };

  useEffect(() => {
    getMySelfDetailsData();
  }, []);

  const checkDuplicateLocation = (locationData) => {
    for (let i = 0; i < locationData.length - 1; i++) {
      for (let j = i + 1; j < locationData.length; j++) {
        let cityMatch = true;
        if (locationData[i].city || locationData[j].city) {
          if (locationData[i].city === locationData[j].city) {
            cityMatch = true;
          } else {
            cityMatch = false;
          }
        }
        let stateMatch = true;
        if (locationData[i].state || locationData[j].state) {
          if (locationData[i].state === locationData[j].state) {
            stateMatch = true;
          } else {
            stateMatch = false;
          }
        }
        let countryMatch = true;
        if (locationData[i].country || locationData[j].country) {
          if (locationData[i].country === locationData[j].country) {
            countryMatch = true;
          } else {
            countryMatch = false;
          }
        }
        if (cityMatch && stateMatch && countryMatch) {
          return true; // Found duplicate
        }
      }
    }
    return false;
  };

  const handleSubmitData = async () => {
    const refractedLocationData = currentLocationData.map(
      ({ keyId, searchedData, searchVal, options, ...rest }, index) => {
        return {
          ...rest,
          isCurrent: index === 0 ? true : false,
        };
      },
    );

    const removedEmptyLocationData = refractedLocationData.filter((item) => item.country);
    if (checkDuplicateLocation(removedEmptyLocationData)) {
      ToastNotifyError('City already exists in current location', 'location-failed');
    } else {
      const updatedData = [...removedEmptyLocationData, ...removeLocationId];
      const { searchVal: raisedSearchVal, ...restraisedData } = raisedLocationData;
      const { searchVal, ...restBornData } = bornLocationData;
      let requestData = [...updatedData];
      if (restraisedData.country) {
        requestData = [...requestData, restraisedData];
      }
      if (restBornData.country) {
        requestData = [...requestData, restBornData];
      }
      if (!isLoading) {
        setIsLoading(true);
        const response = await addLocation(requestData);
        const { status = '', data = '' } = response;
        const errormsg = getErrorMessage(data);
        if (successStatus(status)) {
          await getMyselfData();
          await getMySelfDetailsData();
          ToastNotifySuccess('Loaction saved successfully', 'location-success');
        } else {
          if (errormsg) {
            ToastNotifyError(errormsg, 'location-failed');
          }
        }
        setIsSaveClicked(true);
        setIsLoading(false);
      }
    }
  };

  const updateSearchVal = useCallback(
    debounce((val, setSearchedData, setOptionsData) => {
      fetchLocation(val, setSearchedData, setOptionsData);
    }, 400),
    [],
  );

  const setValuesOnIndex = (value, name, index, prevState) => {
    const tempData = JSON.parse(JSON.stringify([...prevState]));
    const updatedLanguagesData = tempData.map((item, itemIndex) => {
      if (itemIndex === index) {
        if (name === 'duration') {
          const numericValue = parseInt(value);
          if (!isNaN(numericValue)) {
            item[name] = numericValue;
          } else {
            item[name] = null;
          }
        } else {
          item[name] = value;
        }
      }
      return item;
    });
    return updatedLanguagesData;
  };

  const setSearchedDataAtIndex = (data) => {
    setCurrentLocationData((prevData) => {
      return setValuesOnIndex(data, 'searchedData', currentLocationSearchVal?.index, prevData);
    });
  };

  const setOptionsDataAtIndex = (data) => {
    setCurrentLocationData((prevData) => {
      return setValuesOnIndex(data, 'options', currentLocationSearchVal?.index, prevData);
    });
  };

  useEffect(() => {
    setRaisedLocationOptions([]);
    updateSearchVal(raisedLocationData?.searchVal, setSearchedRaisedData, setRaisedLocationOptions);
  }, [raisedLocationData?.searchVal]);

  useEffect(() => {
    setBornLocationOptions([]);
    updateSearchVal(bornLocationData?.searchVal, setSearchedBornData, setBornLocationOptions);
  }, [bornLocationData?.searchVal]);

  useEffect(() => {
    updateSearchVal(currentLocationSearchVal?.value, setSearchedDataAtIndex, setOptionsDataAtIndex);
  }, [currentLocationSearchVal?.value]);

  const changeRaisedLocation = async (e, setData = () => {}, searchData) => {
    setData((prevData) => {
      return {
        ...prevData,
        searchVal: e,
      };
    });

    searchData?.forEach((element) => {
      if (element?.display_name === e) {
        setData((prevData) => {
          return {
            ...prevData,
            country: element?.address?.country,
            state: element?.address?.state,
            city: element?.address?.name,
            latitude: element?.lat,
            longitude: element?.lon,
          };
        });
      }
    });
  };

  const removeRaisedCurrentData = (setData = () => {}, id = '', initialData = {}) => {
    if (id) {
      setRemoveLocationId((prevData) => {
        return [
          ...prevData,
          {
            id,
          },
        ];
      });
    }
    setData(initialData);
  };

  const renderFields = (
    data = {},
    setData = () => {},
    options = [],
    searchData = [],
    initialData = {},
  ) => {
    return (
      <>
        <div className="lg:min-w-[250px] mr-[1vw] lg:w-auto w-full">
          <AutoComplete
            label="Location"
            labelFontColor="#333"
            placeholder="Search Location"
            options={options}
            defaultValue={data?.searchVal}
            onChange={(e) => changeRaisedLocation(e, setData, searchData)}
          />
        </div>
        <div className="mr-[1vw] lg:w-auto w-full">
          <Input
            label="Country"
            className="rounded-md bg-[#D1D1D1] p-4 w-full"
            disabled
            labelFontColor="#333"
            initialValue={data?.country}
          />
        </div>
        <div className="mr-[1vw] lg:w-auto w-full">
          <Input
            disabled
            className="rounded-md bg-[#D1D1D1] p-4 w-full"
            label="State"
            labelFontColor="#333"
            initialValue={data?.state}
          />
        </div>
        <div className="mr-[1vw] lg:w-auto w-full">
          <Input
            disabled
            className="rounded-md bg-[#D1D1D1] p-4 w-full"
            label="City"
            labelFontColor="#333"
            initialValue={data?.city}
          />
        </div>
        <img
          src={addCircle}
          alt=""
          className="cursor-pointer mt-[19px] rotate-45"
          onClick={() => removeRaisedCurrentData(setData, data?.id, initialData)}
        />
      </>
    );
  };

  const setFieldsValueWithData = (value, index, prevState) => {
    const tempData = JSON.parse(JSON.stringify([...prevState]));
    tempData?.forEach((item, itemIndex) => {
      if (itemIndex === index) {
        item?.searchedData?.forEach((element) => {
          if (element?.display_name === value) {
            item.country = element?.address?.country;
            item.state = element?.address?.state;
            item.city = element?.address?.city;
            item.latitude = element.lat;
            item.longitude = element.lon;
            item.searchVal = value;
          }
        });
      }
      return item;
    });
    return tempData;
  };

  const changeCurrentLocation = async (e, index) => {
    setCurrentLocationSearchVal({
      value: e,
      index,
    });
    // setCurrentLocationData((prevData) => {
    //   return setValuesOnIndex(e, 'searchVal', index, prevData);
    // });
    setCurrentLocationData((prevData) => {
      return setFieldsValueWithData(e, index, prevData);
    });
  };

  const removeLocationData = (index) => {
    const tempData = JSON.parse(JSON.stringify([...currentLocationData]));
    const updatedLanguagesData = tempData?.filter((item, itemIndex) => {
      if (itemIndex === index) {
        if (item?.id) {
          let data = {
            id: item.id,
          };
          const prevData = [...removeLocationId];
          prevData.push(data);
          setRemoveLocationId([...prevData]);
        }
      } else {
        return item;
      }
    });
    setCurrentLocationData(updatedLanguagesData);
  };

  const renderCurrentField = () => {
    return currentLocationData.map((item, index) => {
      return (
        <div className="flex flex-wrap gap-y-[10px]" key={index + item?.searchVal}>
          <div className="lg:min-w-[250px] mr-[1vw] lg:w-auto w-full">
            <AutoComplete
              label="Location"
              labelFontColor="#333"
              placeholder="Search Location"
              options={item?.options}
              defaultValue={item?.searchVal}
              onChange={(e) => changeCurrentLocation(e, index)}
            />
          </div>
          <div className="mr-[1vw] lg:w-auto w-full">
            <Input
              label="Country"
              className="rounded-md bg-[#D1D1D1] p-4 w-full"
              disabled
              labelFontColor="#333"
              initialValue={item?.country}
            />
          </div>
          <div className="mr-[1vw] lg:w-auto w-full">
            <Input
              disabled
              className="rounded-md bg-[#D1D1D1] p-4 w-full"
              label="State"
              labelFontColor="#333"
              initialValue={item?.state}
            />
          </div>
          <div className="mr-[1vw] lg:w-auto w-full">
            <Input
              disabled
              className="rounded-md bg-[#D1D1D1] p-4 w-full"
              label="City"
              labelFontColor="#333"
              initialValue={item?.city}
            />
          </div>
          <div className="flex mr-[1vw] lg:w-auto w-full">
            <Input
              // disabled
              className="rounded-md p-4 border border-lightgrey p-2 bg-white w-full"
              label="How long (Years)"
              labelFontColor="#333"
              initialValue={item?.duration || ''}
              onChange={(e) => {
                if (!isNaN(parseInt(e.target.value)) && parseInt(e.target.value) > 0) {
                  setCurrentLocationData((prevData) => {
                    return setValuesOnIndex(e.target.value, 'duration', index, prevData);
                  });
                }
              }}
            />
            {index === currentLocationData.length - 1 ? (
              <img
                src={addCircle}
                alt=""
                className="cursor-pointer mt-[19px] ml-[15px] cursor-pointer"
                onClick={() => addLocationData()}
              />
            ) : (
              <img
                src={addCircle}
                alt=""
                className="cursor-pointer mt-[19px] rotate-45 ml-[15px]"
                onClick={() => removeLocationData(index)}
              />
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className={`rounded-[4px] bg-white shadow-md rounded-b-[0] ${parentWrapClass}`}>
      {!hideLocationHeading ? (
        <div className="p-[18px] bg-[#E9F6FF] rounded-[4px]">
          <div className="text-[#0071BC] text-[14px] font-semibold">Location</div>
        </div>
      ) : (
        ''
      )}
      <div className={`px-[32px] py-[36px] ${childClassPadding}`}>
        <div className="mb-[32px]">
          <div className="mb-[12px] text-gray-800 text-[20px] font-medium capitalize">Current</div>
          <div className="flex flex-col gap-y-[25px]">{renderCurrentField()}</div>
        </div>
        <div className="mb-[32px]">
          <div className="mb-[12px] text-gray-800 text-[20px] font-medium capitalize">Raised</div>
          <div className="flex flex-wrap gap-y-[10px]">
            {renderFields(
              raisedLocationData,
              setRaisedLocationData,
              raisedLocationOptions,
              searchedRaisedData,
              initialRaisedLocationData,
            )}
          </div>
        </div>
        <div>
          <div className="mb-[12px] text-gray-800 text-[20px] font-medium capitalize">Born</div>
          <div className="flex flex-wrap gap-y-[10px]">
            {renderFields(
              bornLocationData,
              setBornLocationData,
              bornLocationOptions,
              searchedBornData,
              initialBornLocationData,
            )}
          </div>
        </div>
      </div>
      <hr className="pb-8" style={{ color: 'rgba(161, 160, 160, 0.50)' }} />
      <div className="grid justify-items-end pb-8 px-[2.2vw]">
        <Button
          label="Save"
          showArrowIcon={false}
          onlyShowLoaderWhenLoading={true}
          isLoading={isLoading}
          onClick={handleSubmitData}
        />
      </div>
    </div>
  );
};

export default Location;
